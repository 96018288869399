import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Delete } from '@mui/icons-material'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { convertBytes } from '../../../utils'

import zipIcon from '../../../Assets/Icons/zip_icon.png'

import './ImageDropzone.css'

function ProjectFilesDropzone(props) {
    const { showSnackbar } = useGlobalSnackbar()

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                showSnackbar({ message: 'Tipo de ficheiro não aceite, apenas .zip', variant: 'error' })
            }
            return;
        }

        props.setFiles(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noDragEventsBubbling: true,
        accept: {
            'application/octet-stream': ['.zip', '.7z', '.tar.xz'],
        },
        ...props.dropzoneOptions.options,
    })

    const removeFiles = () => {
        props.setFiles(null)
    }

    return (
        <>
            <div className={`dropzone-main-div${props.files ? ' image' : ''}${isDragActive ? ' dragging' : ''}`} {...getRootProps()}>
                <input {...getInputProps({})} /* directory="" webkitdirectory="" mozdirectory="" */ />
                {props.files ?
                    isDragActive ?
                        <p>Largue para carregar os ficheiros</p>
                        :
                        <div className='icon-image-preview-div'>
                            <img
                                alt='preview'
                                src={zipIcon}
                                className='icon-image-preview'
                            />
                            <p>{props.files.name}</p>
                            <p>{convertBytes(props.files.size)}</p>
                        </div>
                    :
                    isDragActive ?
                        <p>Largue para carregar os ficheiros</p>
                        :
                        <p>{props.dropzoneOptions.text.title}</p>
                }
            </div>
            {props.files &&
                <CustomTooltip title='Remover ficheiros'>
                    <Delete onClick={removeFiles} className='image-remove-icon' />
                </CustomTooltip>
            }
        </>
    )
}

export default ProjectFilesDropzone