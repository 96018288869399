import React, { useEffect, useState } from 'react'
import { getEntity } from '../API/Requests'
import { useNavigate } from 'react-router-dom'
import DashboardProjectCard from '../Components/Cards/Projects/DashboardProjectCard'
import DashboardUserCard from '../Components/Cards/AppUsers/DashboardUserCard'
import DashboardCompanyCard from '../Components/Cards/Companies/DashboardCompanyCard'
import MiniChart from '../Components/Charts/Dashboard/MiniChart'

import './css/Dashboard.css'

function Dashboard() {
    const [counts, setCounts] = useState({})
    const [lastCreatedProjects, setLastCreatedProjects] = useState([])
    const [lastCreatedUsers, setLastCreatedUsers] = useState([])
    const [lastCreatedCompanies, setLastCreatedCompanies] = useState([])
    const [chartsData, setChartsData] = useState({ usersChartData: [], projectsChartData: [], companiesChartData: [] })

    useEffect(() => {
        getEntity('dashboard').then(res => {
            setCounts(res.data.counts)
            setLastCreatedProjects(res.data.lastCreated.lastProjects)
            setLastCreatedUsers(res.data.lastCreated.lastUsers)
            setLastCreatedCompanies(res.data.lastCreated.lastCompanies)
            setChartsData(res.data.chartData)
        }, err => {
            console.error(err)
        })
    }, [])


    return (
        <div className='dashboard-main-div'>
            <div className='dashboard-top-div'>
                <TopPageInfo
                    title='Utilizadores'
                    count={counts.usersCount}
                    chart={<MiniChart entity={chartsData.usersChartData} />}
                />
                <TopPageInfo
                    title='Projetos'
                    count={counts.projectsCount}
                    chart={<MiniChart
                    entity={chartsData.projectsChartData} />}
                />
                <TopPageInfo
                    title='Empresas'
                    count={counts.companiesCount}
                    chart={<MiniChart entity={chartsData.companiesChartData} />}
                />
            </div>
            <div className='dashboard-last-projects'>
                <p>Projetos mais recentes</p>
                <div className='dashboard-last-project-inner'>
                    {lastCreatedProjects?.map(project => (
                        <DashboardProjectCard key={project.id} project={project} />
                    ))}
                </div>
            </div>
            <div className='dashboard-bottom-sections'>
                <div className='dashboard-bottom-users'>
                    <p>Utilizadores mais recentes</p>
                    <div className='dashboard-users-inner'>
                        {lastCreatedUsers?.map(user => (
                            <DashboardUserCard key={user.id} user={user} />
                        ))}
                    </div>
                </div>
                <div className='dashboard-bottom-companies'>
                    <p>Empresas mais recentes</p>
                    <div className='dashboard-companies-inner'>
                        {lastCreatedCompanies?.map(company => (
                            <DashboardCompanyCard key={company.id} company={company} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard


const TopPageInfo = ({ title, count, chart }) => {
    const navigate = useNavigate()

    return (
        <div key={title} className='dashboard-top-div-el' onClick={() => navigate('/statistics')}>
            <p className='dashboard-top-panel-title'>{title}</p>
            <p className='dashboard-top-panel-value'>{count}</p>
            <div className='dashboard-top-div-el-chart'>
                {chart}
            </div>
        </div>
    )

}