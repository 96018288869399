import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import Button from '../../Buttons/Button'
import { resendCredentials } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'

function ResendCredDialog(props) {
    const [loading, setLoading] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()

    const submit = () => {
        setLoading(true)
        resendCredentials(props.user.id).then(res => {
            showSnackbar({ message: res.data })
            props.close()
            setLoading(false)
            console.log(res)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar enviar novas credenciais', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`Reenviar credenciais`}
        >
            <div className='add-dialog-div' style={{ gap: '20px' }}>
                <p className='add-dialog-title'>{`Enviar novas credenciais para o utilizador ${props.user.name}, com o email ${props.user.email}?`}</p>
                <p style={{ fontSize: '14px', textAlign: 'center' }}>Se continuar, irá ser enviado um email para este utilizador com as suas novas credenciais com que poderá fazer o login na aplicação.</p>
                <Button loading={loading} onClick={submit}>Enviar novas credenciais</Button>
            </div>
        </DialogPrefab>
    )
}

export default ResendCredDialog