import './RowsComponents.css'
import { useState } from 'react'
import { Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material'
import { openInNewTab } from '../../../utils'
import CustomSwitch from '../../Inputs/CustomSwitch'
import { useUserInfo } from '../../../Hooks/useUser'
import { TableView, MoreVert } from '@mui/icons-material'
import CustomTooltip from '../../Misc/CustomTooltip'
import sortArray from 'sort-array'
import useTablesDialog from '../../../Hooks/Dialogs/useTablesDialog'


export const NameAndPicture = ({ cell, row }) => {
    const name = cell.getValue()
    const image = row.original.portrait

    return (
        <div className='name-and-picture-div'>
            <Avatar alt={name} src={image ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${image}` : null} sx={{ width: '35px', height: '35px' }} />
            <p>{name}</p>
        </div>
    )
}

export const CompanyAndPicture = ({ name, image }) => {
    return (
        <div className='name-and-picture-div'>
            <Avatar variant='rounded' alt={name} src={image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${image}` : null} sx={{ minWidth: '35px', width: 'auto', height: '35px', maxWidth: '100px' }}>
                {name.split('')[0]}
            </Avatar>
            <p>{name}</p>
        </div>
    )
}

export const LinkComponent = ({ name, url }) => {
    return (
        <p className='url-component' onClick={() => openInNewTab(url)}>{name ? name : url}</p>
    )
}

export const SwitchComponent = ({ value, onChange, entity }) => {
    const { userInfo } = useUserInfo()
    const valueBool = Boolean(value)

    return (
        <div className='boolean-main-div'>
            {userInfo.user_role[`canEdit${entity}`] ?
                <CustomSwitch checked={valueBool} onChange={onChange} />
                :
                <CustomSwitch checked={valueBool} disabled />
            }
        </div>
    )
}

export const TablesComponent = ({ tables, parentRefresh }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const { openViewMore } = useTablesDialog()

    const sortedArray = sortArray(tables, { by: 'createdAt', order: 'desc' })
    const shownArray = sortedArray.slice(0, 4)
    const overFlownArray = sortedArray.slice(4)

    const getName = table => {
        return `${table.name ? `${table.name} - ` : ''} ${table.type === 'new_business' ? 'New Business' : 'Final Design'} - ${table.year}`
    }

    const handleClick = table => {
        openViewMore(table, parentRefresh)
    }

    return (
        <div className='tables-component'>
            {shownArray.map(table => (
                <CustomTooltip key={table.id} title={getName(table)}>
                    <TableView
                        htmlColor='var(--table-buttons)'
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClick(table)}
                    />
                </CustomTooltip>
            ))}
            {overFlownArray.length > 0 &&
                <>
                    <CustomTooltip title='Ver Mais'>
                        <MoreVert
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            sx={{ cursor: 'pointer', marginLeft: '5px' }}
                            htmlColor='var(--table-buttons)'
                        />
                    </CustomTooltip>
                    <Menu
                        id='pdf-menu'
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {overFlownArray.map(table => (
                            <MenuItem key={table.id} onClick={() => { setAnchorEl(null); handleClick(table) }}>
                                <ListItemIcon >
                                    <TableView htmlColor='var(--table-buttons)' />
                                </ListItemIcon>
                                {getName(table)}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }
        </div>
    )
}