import React, { useContext, useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { ThemeContext } from '../../../../Context/theme/Theme';
import { DarkMode, LightMode } from '@mui/icons-material'
import CustomSwitch from '../../../Inputs/CustomSwitch'
import { getUserSettings, setUserSettings } from '../../../../UserSettings/UserSettings';

import './GeneralSettings.css'

function GeneralSettings() {
    const { mode, setMode } = useContext(ThemeContext);
    const [appUsersTableSettings, setAppUserTableSettings] = useState(getUserSettings('appUsersTable'))
    const [projectsTableSettings, setProjectsTableSettings] = useState(getUserSettings('projectsTable'))
    const [companiesTableSettings, setCompaniesTableSettings] = useState(getUserSettings('companiesTable'))
    const [tablesTableSettings, setTablesTableSettings] = useState(getUserSettings('tablesTable'))

    useEffect(() => {
        setUserSettings('appUsersTable', appUsersTableSettings)
    }, [appUsersTableSettings])

    useEffect(() => {
        setUserSettings('projectsTable', projectsTableSettings)
    }, [projectsTableSettings])

    useEffect(() => {
        setUserSettings('companiesTable', companiesTableSettings)
    }, [companiesTableSettings])

    useEffect(() => {
        setUserSettings('tablesTable', tablesTableSettings)
    }, [tablesTableSettings])

    return (
        <div className='general-settings-main-div'>
            <p className='roles-settings-title'>Preferências Gerais</p>
            <div className='general-settings-block'>
                <p className='general-settings-block-title'>Tema</p>
                <p className='general-settings-block-subtitle'>Escolha o tema de cores a apresentar no backoffice</p>
                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(e) => setMode(e.target.value)}
                    aria-label="text alignment"
                >
                    <ToggleButton value="light" aria-label="left aligned">
                        <LightMode sx={{ marginRight: '10px' }} />
                        Claro
                    </ToggleButton>
                    <ToggleButton value="dark" aria-label="centered">
                        <DarkMode sx={{ marginRight: '10px' }} />
                        Escuro
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className='general-settings-block'>
                <p className='general-settings-block-title'>Tabela App users</p>

                <p className='general-settings-block-subtitle'>Preferências da tabela App users</p>
                <div className='general-settings-block-div' style={{ flexDirection: 'column' }}>
                    <p>Colunas visíveis:</p>
                    <VisibleColumns data={appUsersTableSettings.columnVisibility} setData={(columnVisibility) => setAppUserTableSettings({ ...appUsersTableSettings, columnVisibility })} />
                </div>
                <div className='general-settings-block-div'>
                    <CustomSelect
                        label='Densidade'
                        value={appUsersTableSettings.density}
                        setValue={density => setAppUserTableSettings({ ...appUsersTableSettings, density })}
                        options={[{ value: 'compact', label: 'compacto' }, { value: 'comfortable', label: 'Media' }, { value: 'spacious', label: 'Espaçoso' }]}
                    />
                    <CustomSelect
                        label='Mostrar filtros'
                        value={appUsersTableSettings.showColumnFilters}
                        setValue={showColumnFilters => setAppUserTableSettings({ ...appUsersTableSettings, showColumnFilters })}
                        options={[{ value: false, label: 'Não' }, { value: true, label: 'Sim' }]}
                    />
                </div>
            </div>
            <div className='general-settings-block'>
                <p className='general-settings-block-title'>Tabela Projetos</p>
                <p>Preferências da tabela Projetos</p>
                <div className='general-settings-block-div' style={{ flexDirection: 'column' }}>
                    <p>Colunas visíveis</p>
                    <VisibleColumns data={projectsTableSettings.columnVisibility} setData={(columnVisibility) => setProjectsTableSettings({ ...projectsTableSettings, columnVisibility })} />
                </div>
                <div className='general-settings-block-div'>
                    <CustomSelect
                        label='Densidade'
                        value={projectsTableSettings.density}
                        setValue={density => setProjectsTableSettings({ ...projectsTableSettings, density })}
                        options={[{ value: 'compact', label: 'compacto' }, { value: 'comfortable', label: 'Media' }, { value: 'spacious', label: 'Espaçoso' }]}
                    />
                    <CustomSelect
                        label='Mostrar filtros'
                        value={projectsTableSettings.showColumnFilters}
                        setValue={showColumnFilters => setProjectsTableSettings({ ...projectsTableSettings, showColumnFilters })}
                        options={[{ value: false, label: 'Não' }, { value: true, label: 'Sim' }]}
                    />
                </div>
            </div>
            <div className='general-settings-block'>
                <p className='general-settings-block-title'>Tabela Empresas</p>
                <p>Preferências da tabela Empresas</p>
                <div className='general-settings-block-div' style={{ flexDirection: 'column' }}>
                    <p>Colunas visíveis</p>
                    <VisibleColumns data={companiesTableSettings.columnVisibility} setData={(columnVisibility) => setCompaniesTableSettings({ ...companiesTableSettings, columnVisibility })} />
                </div>
                <div className='general-settings-block-div'>
                    <CustomSelect
                        label='Densidade'
                        value={companiesTableSettings.density}
                        setValue={density => setCompaniesTableSettings({ ...companiesTableSettings, density })}
                        options={[{ value: 'compact', label: 'compacto' }, { value: 'comfortable', label: 'Media' }, { value: 'spacious', label: 'Espaçoso' }]}
                    />
                    <CustomSelect
                        label='Mostrar filtros'
                        value={companiesTableSettings.showColumnFilters}
                        setValue={showColumnFilters => setCompaniesTableSettings({ ...companiesTableSettings, showColumnFilters })}
                        options={[{ value: false, label: 'Não' }, { value: true, label: 'Sim' }]}
                    />
                </div>
            </div>
            <div className='general-settings-block'>
                <p className='general-settings-block-title'>Tabela Tabelas</p>
                <p>Preferências da tabela de tabelas</p>
                <div className='general-settings-block-div' style={{ flexDirection: 'column' }}>
                    <p>Colunas visíveis</p>
                    <VisibleColumns data={tablesTableSettings.columnVisibility} setData={(columnVisibility) => setTablesTableSettings({ ...tablesTableSettings, columnVisibility })} />
                </div>
                <div className='general-settings-block-div'>
                    <CustomSelect
                        label='Densidade'
                        value={tablesTableSettings.density}
                        setValue={density => setTablesTableSettings({ ...tablesTableSettings, density })}
                        options={[{ value: 'compact', label: 'compacto' }, { value: 'comfortable', label: 'Media' }, { value: 'spacious', label: 'Espaçoso' }]}
                    />
                    <CustomSelect
                        label='Mostrar filtros'
                        value={tablesTableSettings.showColumnFilters}
                        setValue={showColumnFilters => setTablesTableSettings({ ...tablesTableSettings, showColumnFilters })}
                        options={[{ value: false, label: 'Não' }, { value: true, label: 'Sim' }]}
                    />
                </div>
            </div>
        </div>
    )
}

export default GeneralSettings

const VisibleColumns = ({ data, setData }) => {
    const array = Object.keys(data).map((key) => ({ name: key, value: data[key] }));

    const getName = label => {
        switch (label) {
            case 'id': return 'ID';
            case 'name': return 'Nome';
            case 'email': return 'Email';
            case 'company': return 'Empresa';
            case 'last_login': return 'Ultimo Login';
            case 'website': return 'Website';
            default: return label
        }
    }

    const onChange = e => {
        const name = e.target.name
        const value = e.target.checked

        data[name] = value
        console.log(data)
        setData(data)
    }

    return (
        <div className='general-settings-visible-columns'>
            {array.map(col => (
                <CustomSwitch
                    key={col.name}
                    name={col.name}
                    label={getName(col.name)}
                    checked={col.value}
                    onChange={onChange}
                    labelStyle={{ fontSize: '13px' }}
                />
            ))}
        </div>
    )
}

const CustomSelect = ({ label, value, setValue, options }) => {

    return (
        <div style={{ width: '150px' }}>
            <FormControl fullWidth variant="filled">
                <InputLabel>{label}</InputLabel>
                <Select
                    id="density-select"
                    value={value}
                    label={label}
                    onChange={(e) => setValue(e.target.value)}
                >
                    {options.map(el => (
                        <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}