import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { SocketContext, socket } from './Context/socket/socket'

import { SnackbarProvider } from 'notistack'
import { UserProvider } from './Context/user'
import { ThemeProvider } from './Context/theme/Theme'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Login from './Pages/Login'
import ResetPass from './Pages/ResetPass'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<UserProvider>
		<BrowserRouter>
			<SnackbarProvider maxSnack={4}>
				<SocketContext.Provider value={socket}>
					<ThemeProvider>
						<Routes>
							<Route path='/login' element={<Login />} />
							<Route path='/password-reset' element={<ResetPass />} />
							<Route path='/*' element={<App />} />
							<Route path='*' element={<Navigate to='/login' />} />
						</Routes>
					</ThemeProvider>
				</SocketContext.Provider>
			</SnackbarProvider>
		</BrowserRouter>
	</UserProvider>
);
