import React, { useEffect, useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { shareProject, removeShareProject, getEntity } from '../../../API/Requests'
import Button from '../../Buttons/Button'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import { Tabs, Tab } from '@mui/material'

import './ShareProjects.css'

function ShareProjectsUser(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [rawProjects, setRawProjects] = useState([])
    const [projects, setProjects] = useState([])
    const [currentShared, setCurrentShared] = useState([])
    const [selectedAddProjects, setSelectedAddProjects] = useState([])
    const [selectedRemoveProjects, setSelectedRemoveProjects] = useState([])
    const [tab, setTab] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.open) {
            setLoading(true)
            if (props.currentShared) {
                setCurrentShared(props.currentShared);
            } else {
                getEntity('app_users', props.user).then(res => {
                    setCurrentShared(res.data.sharedProjects)
                }, err => {
                    console.error(err)
                })
            }

            getEntity('projects').then(res => {
                setRawProjects(res.data)
                setLoading(false)
            }, err => {
                console.error(err)
                setLoading(false)
            })
        }
    }, [props.open])

    useEffect(() => {
        if (rawProjects.length > 0) {
            if (currentShared.length === 0) {
                setProjects(rawProjects)
                return;
            }
            const filteredProjects = rawProjects.filter((project) => currentShared.every((el) => el[Object.keys(el)[0]] !== project[Object.keys(el)[0]]));
            setProjects(filteredProjects)
        }
    }, [currentShared, rawProjects])

    const onShareNewProject = () => {
        const projects = selectedAddProjects.map(el => el.id)

        shareProject({ usersIds: props.user, projectsIds: projects }).then(res => {
            showSnackbar({ message: res.data })
            props.close()
            props.refresh && props.refresh()
        }, err => {
            console.error(err)
            showSnackbar({ message: 'Ocorreu um erro ao partilhar projetos', description: err.response, variant: 'error' })
        })
    }

    const onRemoveSharedProject = () => {
        const projects = selectedRemoveProjects.map(el => el.id)

        removeShareProject({ usersIds: props.user, projectsIds: projects }).then(res => {
            showSnackbar({ message: res.data })
            props.close()
            props.refresh && props.refresh()
        }, err => {
            console.error(err)
            showSnackbar({ message: 'Ocorreu um erro ao eliminar partilha de projetos', description: err.response, variant: 'error' })
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title='Partilhar Projetos'
        >
            <div className='share-projects-main-div'>
                <Tabs value={tab} onChange={(e, value) => setTab(value)} centered variant='fullWidth'>
                    <Tab label='Partilhar projetos' />
                    {currentShared.length > 0 &&
                        <Tab label='Remover Partilha' />
                    }
                </Tabs>
                <div style={{ flex: '1 1', marginTop: '10px', width: '100%' }}>
                    <TabPanel value={tab} index={0}>
                        <div className='share-projects-tab-content'>
                            <p>Escolha os projetos que pretende partilhar com este utilizador</p>
                            <GenericAutoComplete
                                disableCloseOnSelect={true}
                                multiple={true}
                                id='projects'
                                label='Escolha os Projetos'
                                name='projects'
                                optionLabel='name'
                                tagText='Projeto(s)'
                                data={projects}
                                value={selectedAddProjects}
                                fullWidth
                                onChange={(val) => setSelectedAddProjects(val)}
                                helperText={loading ? 'Loading projects...' : ''}
                            />
                            <Button disabled={selectedAddProjects.length === 0} onClick={onShareNewProject}>Partilhar projetos</Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <div className='share-projects-tab-content'>
                            <p>Escolha os projetos que pretende retirar a partilha</p>
                            <GenericAutoComplete
                                disableCloseOnSelect={true}
                                multiple={true}
                                id='projects'
                                label='Escolha os Projetos'
                                name='projects'
                                optionLabel='name'
                                tagText='Projeto(s)'
                                data={currentShared}
                                value={selectedRemoveProjects}
                                fullWidth
                                onChange={(val) => setSelectedRemoveProjects(val)}
                                helperText={loading ? 'Loading projects...' : ''}
                            />
                            <Button disabled={selectedRemoveProjects.length === 0} onClick={onRemoveSharedProject}>Remover partilha</Button>
                        </div>
                    </TabPanel>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ShareProjectsUser

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='tab-panel'
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}