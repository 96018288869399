import React from 'react'
import { Search, Close } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import './SearchInput.css'

function SearchInput(props) {

    return (
        <div className='search-input-main-div'>
            <div className='search-input-icon'>
                <Search htmlColor='white' />
            </div>
            <input
                className='search-input-input'
                type='text'
                list='search-data-list'
                value={props.value}
                maxLength={40}
                placeholder='Procurar'
                onChange={(e) => props.onChange(e.target.value)}
            />
            {props.value.length > 0 &&
                <Tooltip title='Apagar'>
                    <div className='search-input-close'>
                        <Close htmlColor='white' onClick={() => props.onChange('')} />
                    </div>
                </Tooltip>
            }
        </div>
    )
}

export default SearchInput