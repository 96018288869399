import React, { useState, useEffect } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { createEntity, getEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import useDialog from '../../../Hooks/useDialog'
import { GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'

import '../GenericCRUDDialog.css'

function AddBackofficeUser(props) {
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])

    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const { openInfoDialog } = useDialog()

    useEffect(() => {
        if (props.open) {
            getEntity('user_roles').then(res => {
                setRoles(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props.open])

    const onSubmit = data => {
        openInfoDialog({ title: 'Adicionar novo utilizador Backoffice', message: 'Ao adicionar este utilizador irá ser enviado um email com as credenciais para o email introduzido.\n\nPretende continuar?' }, 'y/n', () => submitToServer(data))
    }

    const submitToServer = (data) => {
        console.log(data)
        setLoading(true)

        createEntity('backoffice_users', { ...data, userRoleId: data.role.id }).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar criar o utilizador', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Adicionar novo utilizador Backoffice`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para adicionar um novo utilizador para o Backoffice</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue=''
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='email'
                        defaultValue=''
                        rules={{
                            required: 'Campo Obrigatório',
                            pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Email*'
                                name='email'
                                onChange={onChange}
                                value={value}
                                error={errors.email}
                                helperText={errors.email && errors.email?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='role'
                        defaultValue={null}
                        rules={{
                            required: 'Escolha um cargo para este utilizador',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <GenericAutoComplete
                                id='role'
                                label='Permissões'
                                name='role'
                                optionLabel='name'
                                data={roles}
                                value={value}
                                fullWidth
                                onChange={(val) => { onChange(val) }}
                                error={errors.role ? true : false}
                                helperText={errors.role && errors.role?.message}
                            />
                        )}
                    />
                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Criar utilizador</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default AddBackofficeUser