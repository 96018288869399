import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { editEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import useDialog from '../../../Hooks/useDialog'
import { CompaniesAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'

import '../GenericCRUDDialog.css'

function EditAppUserDialog(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false)
    const { openInfoDialog } = useDialog()

    const onSubmit = data => {
        if (data.email !== props.user.email) {
            openInfoDialog({ title: `Editar ${props.user.name}`, message: `Ao Editar o email de ${props.user.name}, irá ser enviado um email com as credenciais para o email introduzido.\n\nPretende continuar?` }, 'y/n', () => submitToServer(data))
        } else {
            submitToServer(data)
        }
    }

    const submitToServer = (data) => {
        const userData = {}

        if (data.name !== props.user.name) userData.name = data.name
        if (data.email !== props.user.email) userData.email = data.email
        if (data.company.id !== props.user.company.id) userData.companyId = data.company.id

        if (Object.keys(userData).length === 0) {
            showSnackbar({ message: 'Não foram detetadas alterações', variant: 'info' })
            return;
        }

        setLoading(true)

        editEntity('app_users', props.user.id, userData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar editar o utilizador', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Editar utilizador ${props.user.name}`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>{`Utilize o formulário a baixo para editar a informação de ${props.user.name}`}</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue={props.user.name}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='email'
                        defaultValue={props.user.email}
                        rules={{
                            required: 'Campo Obrigatório',
                            pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Email*'
                                name='email'
                                onChange={onChange}
                                value={value}
                                error={errors.email}
                                helperText={errors.email && errors.email?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='company'
                        defaultValue={props.user.company}
                        rules={{
                            required: 'Escolha uma empresa',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <CompaniesAutoComplete
                                id='company'
                                label='Empresa'
                                name='company'
                                data={props.companies}
                                value={value}
                                onChange={(val) => { onChange(val) }}
                                error={errors.company ? true : false}
                                helperText={errors.company && errors.company?.message}
                            />
                        )}
                    />
                    <Button loading={loading} type='submit'>Editar utilizador</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default EditAppUserDialog