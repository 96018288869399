import { useEffect, useContext, useState, useMemo } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'

import { useUserInfo } from './Hooks/useUser'
import ModalProvider from 'mui-modal-provider'
import useToken from './Hooks/useToken'
import { getEntity } from './API/Requests'
import { SocketContext } from './Context/socket/socket'
import useGlobalSnackbar from './Hooks/useGlobalSnackbar'

import SidePanel from './Panels/SidePanel/SidePanel'
import TopPanel from './Panels/TopPanel/TopPanel'
import Dashboard from './Pages/Dashboard'
import AppUsers from './Pages/AppUsers'
import BackofficeUsers from './Pages/BackofficeUsers'
import Projects from './Pages/Projects'
import Companies from './Pages/Companies'
import Statistics from './Pages/Statistics'
import Tables from './Pages/Tables'
import Help from './Pages/Help'

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { ThemeContext } from './Context/theme/Theme'

import LoadingDisplay from './Components/Misc/LoadingDisplay'

import './App.css';

function App() {
	const [ready, setReady] = useState(false)

	const { token } = useToken();
	const { userInfo, setUserInfo } = useUserInfo()
	const { showSnackbar } = useGlobalSnackbar()

	const globalTheme = useTheme();
	const { mode } = useContext(ThemeContext);

	const socket = useContext(SocketContext)
	const navigate = useNavigate()

	let timer;

	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: mode,
					primary: mode === 'light' ? globalTheme.palette.primary : globalTheme.palette.warning

				},
			}),
		[mode, globalTheme],
	);

	useEffect(() => {
		if (!token) {
			navigate('/login', { replace: true })
			return;
		}
		connectSocket()

		return () => {
			clearTimeout(timer, getUser);
		}
	}, [token])

	const connectSocket = async () => {
		if (socket.connected) {
			getUser();
			return;
		}
		socket.auth = { uuid: sessionStorage.getItem('id'), token: token }
		socket.connect();
		timer = setTimeout(() => getUser(), 2000)
	}

	const getUser = async () => {
		await getEntity('backoffice_users', sessionStorage.getItem('id')).then(res => {
			setUserInfo({ ...res.data, token })
			setReady(true)
		}, err => {
			console.log(err)
			navigate('/login', { replace: true })
		})
	}

	const PermissionElement = ({ permission, element }) => {
		if (userInfo.user_role[permission]) {
			return element
		}
		return (
			<Navigate to='/app/dashboard' />
		)
	}

	return (
		ready ?
			<ThemeProvider theme={tableTheme}>
				<ModalProvider>
					<TopPanel />
					<SidePanel />
					<div style={{ paddingLeft: 'var(--side-bar-width)', paddingTop: 'var(--top-bar-height)', height: '100vh' }}>
						<Routes>
							<Route path='/' element={<Dashboard />} />
							<Route path='/app-users' element={<PermissionElement permission='canViewAppUsers' element={<AppUsers />} />} />
							<Route path='/backoffice-users' element={<PermissionElement permission='canViewBackofficeUsers' element={<BackofficeUsers />} />} />
							<Route path='/projects' element={<PermissionElement permission='canViewProjects' element={<Projects />} />} />
							<Route path='/companies' element={<PermissionElement permission='canViewCompanies' element={<Companies />} />} />
							<Route path='/statistics' element={<PermissionElement permission='canViewStatistics' element={<Statistics />} />} />
							<Route path='/tables' element={<PermissionElement permission='canViewTables' element={<Tables />} />} />
							<Route path='/help' element={<Help />} />
							<Route path='*' element={<Navigate to='/' />} />
						</Routes>
					</div>
				</ModalProvider>
			</ThemeProvider>
			:
			<LoadingDisplay />
	);
}

export default App;
