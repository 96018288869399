import React, { useState, useEffect } from 'react'
import { getEntity } from '../API/Requests'
import PageTop from '../Panels/PageTop/PageTop'
import BackofficeUserCard from '../Components/Cards/BackofficeUsers/BackofficeUserCard'
import useBackofficeUserDialog from '../Hooks/Dialogs/useBackofficeUserDialog'

import './css/BackofficeUsers.css'

function BackofficeUsers() {
    const [users, setUsers] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { openAddDialog } = useBackofficeUserDialog()


    useEffect(() => {
        getEntity('backoffice_users').then(res => {
            setUsers(res.data)
        }, err => {
            console.log(err)
        })

    }, [refresh])

    const topPageInfo = [
        { label: 'Número de utilizadores Backoffice', value: users.length },
    ]

    return (
        <div className='backoffice-users-main-div'>
            <PageTop
                title='Utilizadores Backoffice'
                info={topPageInfo}
                view={'table'}
                addEntity={() => openAddDialog(() => setRefresh(!refresh))}
                addBtn='Utilizador Backoffice'
            />
            <div className='backoffice-users-page-content'>
                {users.map(user => (
                    <BackofficeUserCard key={user.id} user={user} refresh={() => setRefresh(!refresh)} />
                ))}
            </div>
        </div>
    )
}

export default BackofficeUsers