import React from 'react'
import UsersLoginTimeline from '../Charts/Statistics/UsersLoginTimeline'
import StatisticsTable from './Components/StatisticsTable'
import useAppUsersDialog from '../../Hooks/Dialogs/useAppUsersDialog'

import './StatisticsSections.css'

function UsersStatistics(props) {
    const { openViewMore } = useAppUsersDialog()

    return (
        <div className='section-main-div'>
            <div className='section-content small'>
                <StatisticsTable
                    data={props.data}
                    headers='Nome,Visitas'
                    values='name,visits'
                    title='Visitas Utilizadores'
                    openViewMore={openViewMore}
                />
            </div>
            <div className='section-content medium'>
                <UsersLoginTimeline />
            </div>
        </div>
    )
}

export default UsersStatistics