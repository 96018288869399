import React, { useState } from 'react'
import useProjectsDialog from '../../../Hooks/Dialogs/useProjectsDialog'

import noImage from '../../../Assets/placeholder/no_image.jpg'
import logoPlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './DashboardProjectCard.css'

function DashboardProjectCard({ project }) {
    const [projectImage, setProjectImage] = useState(`${process.env.REACT_APP_SERVER_URL}/project/${project.folder}/socialThumbnail.jpg`)
    const { openViewMore } = useProjectsDialog()


    return (
        <div className='project-card-dashboard'>
            <div
                className='project-view-more-online-div'
                style={{ backgroundColor: project.online ? 'green' : 'var(--dark-red)' }}
            >
                {project.online ? 'Online' : 'Offline'}
            </div>
            <img
                alt={project.name}
                src={projectImage}
                className='projects-view-more-image-dashboard'
                onClick={() => openViewMore(project, true)}
                onError={() => setProjectImage(noImage)}
            />
            <p className='project-card-title'>{project.name}</p>
            <p>{project.company.name}</p>
            <img className='project-card-company-div' alt={project.company.name} src={project.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${project.company.image}` : logoPlaceholder} />
        </div>
    )
}

export default DashboardProjectCard