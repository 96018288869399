import React from "react";
import DialogPrefab from "./DialogPrefab";
import { QRCode as QR } from "react-qrcode-logo";
import { openInNewTab } from "../../utils";

import Logo from "../../Assets/Logos/fauna.svg";

import "./QRCode.css";

function QRCode(props) {
	return (
		<DialogPrefab open={props.open} close={props.close} maxWidth="xs" title="QR Code">
			<div className="qrcode-main-div">
				<QR
					value={props.url}
					size={200}
					// logoImage={Logo}
					// removeQrCodeBehindLogo={true}
					// logoWidth={60}
					// logoHeight={15}
					qrStyle="dots"
					eyeRadius={5}
				/>
				<p className="qrcode-link" onClick={() => openInNewTab(props.url)}>
					{props.url}
				</p>
			</div>
		</DialogPrefab>
	);
}

export default QRCode;
