import React from 'react'
import { Avatar } from '@mui/material'
import useAppUsersDialog from '../../../Hooks/Dialogs/useAppUsersDialog'

function DashboardUserCard({ user }) {
    const { openViewMore } = useAppUsersDialog()

    return (
        <div className='dashboard-user-card' onClick={() => openViewMore(user, true)}>
            <Avatar alt={user.name} src={user.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${user.portrait}` : null} sx={{ width: '100px', height: '100px' }} />
            <p className='dashboard-user-card-name'>{user.name}</p>
            <p className='dashboard-user-card-email'>{user.email}</p>
        </div>
    )
}

export default DashboardUserCard