import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { RRule } from 'rrule'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '../../Inputs/DatePickers/DatePicker'
import { getUsersStatistics } from '../../../API/Requests';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import './UsersLoginTimeline.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const buildRRule = (timeRange) => {
    const from = moment(timeRange.from).hour(0).minutes(0).seconds(0)
    let to;

    //check if the to date day is same as current day, if not set te time range until the end of the day
    if (moment(timeRange.to).isSame(moment(), 'day')) {
        to = moment(timeRange.to)
    } else {
        to = moment(timeRange.to).hour(23).minutes(0).seconds(0)
    }

    const numberOfDays = to.diff(from, 'days')

    let rule;
    let labels;

    if (numberOfDays < 3) {
        const rrule = new RRule({
            freq: RRule.HOURLY,
            dtstart: from.toDate(),
            until: to.toDate(),
            interval: 1
        }).all();

        rule = [...rrule];
        labels = [...rrule];

        for (let i = 0; i < labels.length; i++) {
            labels[i] = moment(labels[i]).format('DD MMM HH:mm')
        }

        return { rule, labels, freq: 'hour' };
    }

    if (numberOfDays >= 3 && numberOfDays < 30) {
        const rrule = new RRule({
            freq: RRule.DAILY,
            dtstart: from.toDate(),
            until: to.toDate(),
            interval: 1
        }).all();

        rule = [...rrule];
        labels = [...rrule];

        for (let i = 0; i < labels.length; i++) {
            labels[i] = moment(labels[i]).format('DD MMM')
        }

        return { rule, labels, freq: 'day' };
    }

    if (numberOfDays >= 30 && numberOfDays < 90) {
        const rrule = new RRule({
            freq: RRule.WEEKLY,
            dtstart: from.toDate(),
            until: to.toDate(),
            interval: 1
        }).all();

        rule = [...rrule];
        labels = [...rrule];

        for (let i = 0; i < labels.length; i++) {
            labels[i] = moment(labels[i]).format('DD MMM')
        }

        return { rule, labels, freq: 'day' };
    }

    if (numberOfDays >= 90) {
        const rrule = new RRule({
            freq: RRule.MONTHLY,
            dtstart: from.toDate(),
            until: to.toDate(),
            interval: 1
        }).all();

        rule = [...rrule];
        labels = [...rrule];

        for (let i = 0; i < labels.length; i++) {
            labels[i] = moment(labels[i]).format('MMM YYYY')
        }

        return { rule, labels, freq: 'month' };
    }

}

function UsersLoginTimeline() {
    const [timeRule, setTimeRule] = useState({ rule: [], labels: [] })
    const [data, setData] = useState([])
    const [timeFrame, setTimeFrame] = useState({ from: moment().subtract(7, 'day').toDate(), to: moment().toDate() })

    useEffect(() => {
        setTimeRule(buildRRule(timeFrame))
    }, [timeFrame])

    useEffect(() => {
        if (timeRule.rule.length === 0) return;

        getUsersStatistics(timeFrame).then(res => {
            setData(prepareDataToChart(res.data))
        }, err => {
            console.log(err)
        })
    }, [timeRule])

    const prepareDataToChart = (rawData) => {
        const data = []

        timeRule.rule.forEach((day, index) => {
            data.push({ x: day, y: calculateVisitsPerDay(day, rawData, timeRule.freq) })
        })
        return data
    }

    const calculateVisitsPerDay = (day, rawData, freq) => {
        let counter = 0

        rawData.forEach((visit, index) => {
            if (moment(visit.createdAt).isSame(moment(day), freq)) {
                counter++;
            }
        })

        return counter
    }

    const handleDateChange = (date, field) => {
        let newTimeFrame = { ...timeFrame, [field]: date.toDate() }

        if (moment(newTimeFrame.from).isAfter(newTimeFrame.to)) {
            newTimeFrame = { from: date.toDate(), to: date.toDate() }
        }

        if (date && date.isValid()) {
            setTimeFrame(newTimeFrame)
        }
    }

    const getChartColors = type => {
        const mode = window.localStorage.getItem('mode');
        if (mode === 'dark') {
            switch (type) {
                case 'backgroundColor': return '#ed6c02'
                case 'borderColor': return '#ed6c02'
                default: return '#d9d9d9'
            }
        } else {
            switch (type) {
                case 'backgroundColor': return '#1976d2'
                case 'borderColor': return '#1976d2'
                default: return '#171717'
            }
        }
    }

    const options = {
        tension: 0.3,
        cubicInterpolationMode: 'monotone',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                ticks: { color: getChartColors('tick'), stepSize: 1 }
            },
            x: {
                ticks: { color: getChartColors('tick') }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: false,
            },
        },
    };

    const chartData = {
        labels: timeRule.labels,
        datasets: [
            { data, borderColor: getChartColors('borderColor'), backgroundColor: getChartColors('backgroundColor') }
        ]
    };

    return (
        <div className='users-line-chart'>
            <div className='users-chart-top-info'>
                <p className='users-chart-top-info-title'>
                    {`Número de visitas de ${moment(timeFrame.from).format('DD-MMM-YYYY')} a ${moment(timeFrame.to).format('DD-MMM-YYYY')}`}
                </p>
                <div className='users-chart-time-piker'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            inputFormat='DD-MM-YYYY'
                            mask='__-__-____'
                            disableFuture
                            value={timeFrame.from}
                            onChange={(date) => handleDateChange(date, 'from')}
                        />
                        <DatePicker
                            inputFormat='DD-MM-YYYY'
                            mask='__-__-____'
                            disableFuture
                            value={timeFrame.to}
                            onChange={(date) => handleDateChange(date, 'to')}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            <div className='users-chart-div'>
                <Line options={options} data={chartData} />
            </div>
        </div>
    )
}

export default UsersLoginTimeline