import React, { useState, createContext, useEffect } from "react";

const localStorageKey = "mode";

// exposed context for doing awesome things directly in React
export const ThemeContext = createContext({
    mode: "system",
    theme: "light",
    setMode: () => { }
});

export const ThemeProvider = ({ children }) => {


    const [mode, setMode] = useState(() => {
        const isSystemInDarkMode = matchMedia("(prefers-color-scheme: dark)").matches; //problemas com o mui não aceitar o mode de system
        const initialMode = localStorage.getItem(localStorageKey) ? localStorage.getItem(localStorageKey) : isSystemInDarkMode ? "dark" : "light";
        return initialMode;
    });

    // When the mode changes, save it to the localStorage nad update state
    useEffect(() => {
        localStorage.setItem(localStorageKey, mode);

        if (mode !== "system") {
            setTheme(mode);
            return;
        }

        const isSystemInDarkMode = matchMedia("(prefers-color-scheme: dark)");

        setTheme(isSystemInDarkMode.matches ? "dark" : "light");
    }, [mode]);

    const [theme, setTheme] = useState(() => {
        if (mode !== "system") {
            return mode;
        }
        const isSystemInDarkMode = matchMedia("(prefers-color-scheme: dark)").matches;
        return isSystemInDarkMode ? "dark" : "light";
    });

    useEffect(() => {
        document.body.classList.remove("light");
        document.body.classList.remove("dark");
        document.body.classList.add(theme);
        // change <meta name="color-scheme"> for native inputs
        (document.getElementById("colorScheme")).content = theme;
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, mode, setMode }}>
            {children}
        </ThemeContext.Provider>
    );
};
