import React, { useState, useMemo, useEffect } from 'react'
import PageTop from '../Panels/PageTop/PageTop'
import TablePrefab from '../Components/Tables/TablePrefab'
import { getEntity } from '../API/Requests'
import moment from 'moment'
import { NameAndPicture, CompanyAndPicture } from '../Components/Tables/TableComponents/RowsComponents'
import useAppUsersDialog from '../Hooks/Dialogs/useAppUsersDialog'
import useShareProjectsDialog from '../Hooks/Dialogs/useShareProjectsDialog'

import './css/AppUsers.css'

function AppUsers() {
    const [data, setData] = useState([])
    const [companies, setCompanies] = useState([])
    const [exportData, setExportData] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { openDeleteDialog, openAddDialog, openEditDialog, openResendCred, openViewMore } = useAppUsersDialog()
    const { openShareProjectsUser } = useShareProjectsDialog()

    const topPageInfo = [
        { label: 'Número de utilizadores', value: data.length },
    ]

    useEffect(() => {
        setLoading(true)

        getEntity('app_users').then(res => {
            setData(res.data)

            const dataToExport = res.data.map(el => {
                return {
                    id: el.id,
                    nome: el.name,
                    email: el.email,
                    empresa: el.company ? el.company.name : null,
                    ultimo_login: el.last_login ? moment(el.last_login).format('DD MMM YYYY HH:mm') : 'Nunca'
                }
            })

            setExportData(dataToExport)
        }, err => {
            console.error(err)
        })

        getEntity('companies').then(res => {
            setCompanies(res.data.plainData)
            setLoading(false)
        }, err => {
            console.error(err)
            setLoading(false)
        })

    }, [refresh])

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 50,
                columnFilterModeOptions: ['equals', 'between', 'betweenInclusive', 'greaterThan', 'lessThan'],
                filterFn: 'equals',
                enableGrouping: false,
            },
            {
                accessorKey: "name",
                header: "Nome",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                Cell: NameAndPicture
            },
            {
                accessorKey: "email",
                header: "Email",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                enableClickToCopy: true,
            },
            {
                accessorFn: (row) => row.company?.name,
                id: 'company',
                header: "Empresa",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                aggregationFn: 'count',
                Cell: ({ cell, row }) => cell.getValue() ? <CompanyAndPicture name={cell.getValue()} image={row.original.company.image} /> : 'Sem Empresa',
                AggregatedCell: ({ cell }) => <div>Total users: {cell.getValue()}</div>,
            },
            {
                accessorKey: "last_login",
                header: "Ultimo Login",
                Cell: ({ cell }) => <p>{cell.getValue() ? moment(cell.getValue()).format('DD MMM YYYY HH:mm') : 'Nunca'}</p>,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                enableGrouping: false
            },
        ],
        []
    );

    const onEdit = (data) => {
        openEditDialog(data.row.original, companies, () => setRefresh(!refresh))
    }

    const onView = (data) => {
        openViewMore(data.row.original, true)
    }

    const onDelete = (data) => {
        openDeleteDialog(data.row.original, () => setRefresh(!refresh))
    }

    const onAdd = () => {
        openAddDialog(() => setRefresh(!refresh), companies)
    }

    const onResendCredentials = (data) => {
        openResendCred(data.row.original)
    }

    const onShare = (data) => {
        openShareProjectsUser(data.row.original.id, null, null)
    }

    return (
        <div className='app-users-page-main-div'>
            <PageTop
                title='Utilizadores'
                info={topPageInfo}
                view={'table'}
                addEntity={onAdd}
                addBtn='Utilizadores'
            />
            <div className='app-users-table-div'>
                <TablePrefab
                    settingsId='appUsersTable'
                    roleId='AppUsers'
                    columns={columns}
                    data={data}
                    exportData={exportData}
                    title='Utilizadores'
                    enableGrouping
                    enablePinning
                    enableColumnDragging={false}
                    export
                    adicionalState={{ isLoading: loading }}
                    enableColumnFilterModes
                    onRowView={onView}
                    onRowEdit={onEdit}
                    onRowDelete={onDelete}
                    onRowResend={onResendCredentials}
                    onRowShareUser={onShare}
                />
            </div>
        </div>
    )
}

export default AppUsers