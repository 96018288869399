import React, { useState } from 'react'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar'
import DialogPrefab from '../../DialogPrefab'
import { removeEntity } from '../../../../API/Requests'
import Button from '../../../Buttons/Button'

import '../../GenericCRUDDialog.css'

function DeleteTableRow(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)
    
    const submit = () => {
        setLoading(true)
        removeEntity('table_rows', props.row.id).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar eliminar a linha da tabela', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`Eliminar linha de tabela`}
        >
            <div className='delete-dialog-div'>
                <p>{`Tem a certeza que pretende eliminar esta linha da tabela?\n\nToda a informação da mesma será eliminada.`}</p>
                <div className='dialog-confirm-pass-and-button'>
                    <Button loading={loading} disabled={loading} onClick={submit}>Eliminar linha da tabela</Button>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteTableRow