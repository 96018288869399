import React, { useState } from 'react'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar'
import DialogPrefab from '../../DialogPrefab'
import { removeEntity } from '../../../../API/Requests'
import Button from '../../../Buttons/Button'

import '../../GenericCRUDDialog.css'

function DeleteTableProject(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)

    const submit = () => {
        setLoading(true)
        removeEntity('table_projects', props.data.id).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar eliminar o projeto da tabela', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`Eliminar ${props.data.project ? props.data.project.name : 'este projeto'} da tabela`}
        >
            <div className='delete-dialog-div'>
                <p>{`Tem a certeza que pretende eliminar este projeto da tabela?\n\nEste projeto irá apenas ser eliminado da tabela, permanecendo intacto.`}</p>
                <div className='dialog-confirm-pass-and-button'>
                    <Button loading={loading} disabled={loading} onClick={submit}>Eliminar Projeto da tabela</Button>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteTableProject