import React from 'react'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import CustomTooltip from '../Misc/CustomTooltip';

function CustomSwitch({ labelStyle, label, tooltip, ...props }) {

    const BigSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'grey',
            borderRadius: 26 / 2,
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        tooltip ?
            <CustomTooltip title={tooltip}>
                {label ?
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <p style={labelStyle}>{label}</p>
                        <BigSwitch {...props} />
                    </div>
                    :
                    <BigSwitch {...props} />}
            </CustomTooltip>
            :
            label ?
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <p style={labelStyle}>{label}</p>
                    <BigSwitch {...props} />
                </div>
                :
                <BigSwitch {...props} />
    )
}

export default CustomSwitch