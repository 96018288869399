import React from "react";
import { Edit, Visibility, Delete, SyncLock, Link, Share, QueryStats, ForwardToInbox } from "@mui/icons-material";
import CustomTooltip from "../../Misc/CustomTooltip";
import { useUserInfo } from "../../../Hooks/useUser";

import "./Actions.css";
import { Divider } from "@mui/material";

function Actions(props) {
	const { userInfo } = useUserInfo();

	return (
		<div className="table-actions-div">
			{props.onSendProjectEmail && (
				<CustomTooltip title="Notificar clientes por email">
					<ForwardToInbox onClick={props.onSendProjectEmail} />
				</CustomTooltip>
			)}
			{props.onViewAnalytics && (
				<CustomTooltip title="Ver Analytics">
					<QueryStats onClick={props.onViewAnalytics} />
				</CustomTooltip>
			)}
			{props.onShareUser && userInfo.user_role[`canEdit${props.roleId}`] && (
				<CustomTooltip title="Partilhar projetos">
					<Share onClick={props.onShareUser} />
				</CustomTooltip>
			)}
			{props.onShareProject && userInfo.user_role[`canEdit${props.roleId}`] && (
				<CustomTooltip title="Partilhar com utilizadores">
					<Share onClick={props.onShareProject} />
				</CustomTooltip>
			)}
			{props.onVisit && (
				<CustomTooltip title="Visitar">
					<Link onClick={props.onVisit} />
				</CustomTooltip>
			)}
			{props.onResend && userInfo.user_role[`canEdit${props.roleId}`] && (
				<CustomTooltip title="Enviar novas credenciais">
					<SyncLock onClick={props.onResend} />
				</CustomTooltip>
			)}
			{(props.onShareUser || props.onShareProject || props.onVisit || props.onResend) && (
				<Divider orientation="vertical" sx={{ background: "var(--text)", margin: "0 5px" }} />
			)}
			{props.onView && (
				<CustomTooltip title="Ver mais informação">
					<Visibility onClick={props.onView} />
				</CustomTooltip>
			)}
			{props.onEdit && userInfo.user_role[`canEdit${props.roleId}`] && (
				<CustomTooltip title="Editar">
					<Edit onClick={props.onEdit} />
				</CustomTooltip>
			)}
			{props.onDelete && userInfo.user_role[`canRemove${props.roleId}`] && (
				<CustomTooltip title="Eliminar">
					<Delete onClick={props.onDelete} />
				</CustomTooltip>
			)}
		</div>
	);
}

export default Actions;
