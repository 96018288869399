import React, { useEffect, useState, useContext } from 'react'
import { useUserInfo } from '../../Hooks/useUser'
import { Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import useToken from '../../Hooks/useToken'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import ErrorDialog from '../../Components/Dialogs/ErrorDialog'
import MyProfile from '../../Components/Dialogs/Profile/MyProfile'
import SettingsDialog from '../../Components/Dialogs/Settings/SettingsDialog'
// import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment'
import { getEntity } from '../../API/Requests'
import { SocketContext } from '../../Context/socket/socket'
import ThemeToggle from '../../Components/Misc/ThemeToggle'

import { ExitToApp as LogoutIcon, PersonOutlined as ProfileIcon, Settings as SettingsIcon } from '@mui/icons-material'

import Logo from '../../Assets/Logos/fauna.svg'

import './TopPanel.css'
import DecompProgress from '../../Components/Misc/DecompProgress/DecompProgress'

const errorDialogInfo = {
    status: 404,
    statusText: 'Disconnected from server',
    data: 'You got disconnected from the server because the server may be offline. You will be redirected to the login page in 5 seconds',
}

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        transform: 'translate(5px,5px) !important',
    }
}))

function TopPanel() {
    const { userInfo } = useUserInfo()

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()
    const { setToken } = useToken()
    const { showSnackbar } = useGlobalSnackbar()
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const [userRolesData, setUserRolesData] = useState([])
    const [openProfile, setOpenProfile] = useState(false)
    const [openSettings, setOpenSettings] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const socket = useContext(SocketContext)

    let timer;

    useEffect(() => {
        if (userInfo.id) {
            socket.on('connect_error', (args) => onConnectionError(args))
        }

        return () => {
            socket.off('connect_error', onConnectionError)
        }

    }, [userInfo])


    useEffect(() => {
        getEntity('user_roles').then(res => {
            setUserRolesData(res.data)
        }, err => {
            console.log(err)
        })
    }, [refresh])

    const onIdle = () => {
        logoutUser()
    }

    // const { getRemainingTime } = useIdleTimer({
    //     timeout: 1000 * 60 * 10,
    //     debounce: 1500,
    //     onIdle,
    // })


    const logoutUser = () => {
        clearTimeout(timer)
        setToken(null)
        socket.disconnect()
        navigate('/login')
    }

    const onConnectionError = (args) => {
        if (timer) return; //previne a criação de múltiplos timeouts

        timer = setTimeout(() => {
            logoutUser(false)
        }, 5000)
        setShowErrorDialog(true)
    }

    return (
        <>
            <div className='top-bar-main-div'>
                <div className='top-bar-logo-div'>
                    <img alt='fauna logo' src={Logo} className='top-bar-logo' />
                </div>
                <DecompProgress />
                <div className='top-bar-theme-toggle'>
                    <ThemeToggle />
                </div>
                <div className='top-panel-user-display' onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Avatar alt={userInfo.name} src={userInfo.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/backofficeUsers/${userInfo.portrait}` : null} sx={{ width: '30px', height: '30px', margin: '5px 10px 5px 5px' }} />
                    <p>{userInfo.name}</p>
                </div>
            </div>
            <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} logout={logoutUser} openProfile={() => setOpenProfile(true)} openSettings={() => setOpenSettings(true)} />
            <ErrorDialog open={showErrorDialog} close={() => setShowErrorDialog(false)} info={errorDialogInfo} ocb={logoutUser} />
            <MyProfile open={openProfile} close={() => setOpenProfile(false)} userRolesData={userRolesData} />
            <SettingsDialog open={openSettings} close={() => setOpenSettings(false)} userRolesData={userRolesData} refresh={() => setRefresh(!refresh)} />
        </>
    )
}

export default TopPanel

const UserMenu = (props) => {
    const { userInfo } = useUserInfo()

    return (
        <StyledMenu
            id='profile-menu'
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={() => props.setAnchorEl(null)}
        >
            <MenuItem onClick={() => { props.setAnchorEl(null); props.openProfile() }}>
                <ListItemIcon >
                    <ProfileIcon htmlColor='var(--light-pink)' />
                </ListItemIcon>
                Meu Perfil
            </MenuItem>
            {userInfo.user_role.canManageSettings &&
                <MenuItem onClick={() => { props.setAnchorEl(null); props.openSettings() }}>
                    <ListItemIcon >
                        <SettingsIcon htmlColor='var(--light-pink)' />
                    </ListItemIcon>
                    Preferências
                </MenuItem>
            }
            <MenuItem onClick={props.logout}>
                <ListItemIcon>
                    <LogoutIcon htmlColor='var(--light-pink)' />
                </ListItemIcon>
                Sair
            </MenuItem>
        </StyledMenu>
    )
}