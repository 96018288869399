import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getHeaders = async () => {
	return {
		Authorization: JSON.parse(sessionStorage.getItem("token"))?.token,
		"requesting-user": `bo_${sessionStorage.getItem("id")}`,
		lang: "pt",
	};
};

const getFileHeaders = async data => {
	return {
		Authorization: JSON.parse(sessionStorage.getItem("token"))?.token,
		"requesting-user": `bo_${sessionStorage.getItem("id")}`,
		accept: "application/json",
		"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
		lang: "pt",
	};
};

export const loginUser = async (email, password) => {
	return await axios.post(
		`${SERVER_URL}/api/backoffice_users/auth`,
		{ email, password },
		{ headers: { lang: "pt" } },
	);
};

export const sendForgotPass = async email => {
	return await axios.post(`${SERVER_URL}/api/backoffice_users/forgot-pass`, { email }, { headers: { lang: "pt" } });
};

export const resetPass = async data => {
	return await axios.post(`${SERVER_URL}/api/backoffice_users/reset-pass`, data, { headers: { lang: "pt" } });
};

export const updatePassword = async (id, data) => {
	return await axios.post(`${SERVER_URL}/api/backoffice_users/change-pass/${id}`, data, {
		headers: await getHeaders(),
	});
};

export const getUserForPassReset = async (id, secret) => {
	return await axios.get(`${SERVER_URL}/api/backoffice_users/get-user-for-pass-reset/${id}/${secret}`, {
		headers: { lang: "pt" },
	});
};

export const getEntity = async (entity, id) => {
	return await axios.get(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ""}`, { headers: await getHeaders() });
};

export const editEntity = async (entity, id, data) => {
	return await axios.put(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ""}`, data, { headers: await getHeaders() });
};

export const createEntity = async (entity, data) => {
	return await axios.post(`${SERVER_URL}/api/${entity}`, data, { headers: await getHeaders() });
};

export const removeEntity = async (entity, id, authPassword) => {
	return await axios.delete(`${SERVER_URL}/api/${entity}/${id}`, {
		headers: await getHeaders(),
		data: { authPassword },
	});
};

export const updateUserProfile = async (id, data) => {
	return await axios.put(`${SERVER_URL}/api/backoffice_users/update-profile/${id}`, data, {
		headers: await getHeaders(),
	});
};

export const createEntityWithFile = async (entity, data, config) => {
	return await axios.post(`${SERVER_URL}/api/${entity}`, data, { headers: await getFileHeaders(data), ...config });
};

export const editEntityWithFile = async (entity, id, data, config) => {
	return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, data, {
		headers: await getFileHeaders(data),
		...config,
	});
};

export const resendCredentials = async id => {
	return await axios.post(
		`${SERVER_URL}/api/app_users/resend-credentials/${id}`,
		{},
		{ headers: await getHeaders() },
	);
};

export const getFilesStats = async id => {
	return await axios.get(`${SERVER_URL}/api/projects/get-project-file-stats/${id}`, { headers: await getHeaders() });
};

export const changePicture = async (id, data) => {
	return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/backoffice_users/change-picture/${id}`, data, {
		headers: await getFileHeaders(data),
	});
};

export const removePicture = async id => {
	return axios.post(
		`${process.env.REACT_APP_SERVER_URL}/api/backoffice_users/remove-picture/${id}`,
		{},
		{ headers: await getHeaders() },
	);
};

export const getByCompany = async (entity, id) => {
	return await axios.get(`${SERVER_URL}/api/${entity}/get-by-company/${id}`, { headers: await getHeaders() });
};

export const shareProject = async data => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/app_users/share-project`, data, {
		headers: await getHeaders(),
	});
};

export const removeShareProject = async data => {
	return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/app_users/remove-share-project`, data, {
		headers: await getHeaders(),
	});
};

export const getUsersStatistics = async data => {
	return await axios.post(`${SERVER_URL}/api/statistics/users`, data, { headers: await getHeaders() });
};

export const getProjectsStatistics = async data => {
	return await axios.post(`${SERVER_URL}/api/statistics/projects`, data, { headers: await getHeaders() });
};

export const sendNewProjectEmail = async id => {
	return await axios.post(`${SERVER_URL}/api/projects/send-project-email/${id}`, {}, { headers: await getHeaders() });
};

export const getProjectsFromUser = async id => {
	return await axios.get(`${SERVER_URL}/api/app_users/getProjects/${id}`, { headers: await getHeaders() });
};
