import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Delete } from '@mui/icons-material'

import './ImageDropzone.css'

function ImageDropzone(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [filePreview, setFilePreview] = useState(null)

    useEffect(() => {
        if(props.preview) {
            setFilePreview(props.preview)
        }
    }, [props.preview])

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            if (fileRejections[0].errors[0].code === 'file-too-large') {
                showSnackbar({ message: `Imagem demasiado grande, limite de ${props.dropzoneOptions.text.maxSize}`, variant: 'error' })
            }
            if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                showSnackbar({ message: 'Tipo de ficheiro não aceite', variant: 'error' })
            }
            return;
        }
        
        props.setFile(acceptedFiles[0])
        createImagePreview(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noDragEventsBubbling: true,
        ...props.dropzoneOptions.options
    })

    const createImagePreview = file => {
        const picture = URL.createObjectURL(new Blob([file], { type: 'image/png' }))
        setFilePreview(picture)
    }

    const removeImage = () => {
        props.setFile(null)
        setFilePreview(null)
    }

    return (
        <>
            <div className={`dropzone-main-div${filePreview ? ' image' : ''}${isDragActive ? ' dragging' : ''}`} {...getRootProps()}>
                <input {...getInputProps({})} />
                {filePreview ?
                    isDragActive ?
                        <p>Largue para carregar a imagem</p>
                        :
                        <img
                            alt='preview'
                            src={filePreview}
                            className='image-preview'
                        />
                    :
                    isDragActive ?
                        <p>Largue para carregar a imagem</p>
                        :
                        <p>{props.dropzoneOptions.text.title}</p>
                }
            </div>
            {filePreview &&
                <CustomTooltip title='Remover imagem'>
                    <Delete onClick={removeImage} className='image-remove-icon' />
                </CustomTooltip>
            }
        </>
    )
}

export default ImageDropzone