const defaultSettings = {
    appUsersTable: {
        columnVisibility: {
            id: true,
            name: true,
            email: true,
            company: true,
            last_login: true
        },
        sorting: [{ id: 'id', desc: false }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'comfortable',
        expanded: false,
        showColumnFilters: false
    },
    projectsTable: {
        columnVisibility: {
            id: true,
            name: true,
            company: true,
            online: true
        },
        sorting: [{ id: 'id', desc: false }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'comfortable',
        expanded: false,
        showColumnFilters: false
    },
    companiesTable: {
        columnVisibility: {
            id: true,
            name: true,
            website: true,
        },
        sorting: [{ id: 'id', desc: false }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'comfortable',
        expanded: false,
        showColumnFilters: false
    },
    tablesTable: {
        columnVisibility: {
            id: true,
            name: true,
            tables: true
        },
        sorting: [{ id: 'id', desc: false }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'comfortable',
        showColumnFilters: false
    },
}

export const getUserSettings = (entity) => {
    if (localStorage.getItem('fauna_backoffice_settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('fauna_backoffice_settings'))
        if(localStorageData[entity]) {
            return localStorageData[entity]
        }
    }

    return defaultSettings[entity]

}

export const setUserSettings = (entity, value) => {
    if (localStorage.getItem('fauna_backoffice_settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('fauna_backoffice_settings'))
        localStorageData[entity] = value

        localStorage.setItem('fauna_backoffice_settings', JSON.stringify(localStorageData))

    } else {
        const prefObject = defaultSettings;
        prefObject[entity] = value

        localStorage.setItem('fauna_backoffice_settings', JSON.stringify(prefObject))
    }
}