import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { createEntityWithFile } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import { CompaniesAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import ImageDropzone from '../../Inputs/Dropzone/ImageDropzone'
import { Help } from '@mui/icons-material'
import CustomTooltip from '../../Misc/CustomTooltip'

import '../GenericCRUDDialog.css'

function AddCompany(props) {
    const [loading, setLoading] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const [imageFile, setImageFile] = useState(null)

    const submitToServer = (data) => {
        setLoading(true)
        let companyData = { ...data };

        if (!imageFile) {
            if (data.parent?.image) {
                companyData.image = data.parent.image
            }
        } else {
            companyData.image = imageFile
        }

        if (data.parent) {
            companyData.hierarchy = data.parent.hierarchy ? `${data.parent.hierarchy}/${data.parent.id}` : data.parent.id
        }

        createEntityWithFile('companies', companyData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar criar a empresa', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    const dropzoneOptions = {
        options: {
            maxFiles: 1,
            maxSize: 500000,
            multiple: false,
            accept: {
                'image/jpeg': [],
                'image/png': [],
            }
        },
        text: {
            maxSize: '500kb',
            title: 'Arraste ou clique aqui para carregar um logo.',
        }
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Adicionar nova empresa`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para adicionar uma nova empresa</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(submitToServer)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue=''
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='website'
                        defaultValue=''
                        rules={{
                            pattern: {
                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                                message: 'Urls têm de começar por http:// ou https://. Ex: https://google.com'
                            }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Website'
                                name='website'
                                type='url'
                                placeholder='https://'
                                onChange={onChange}
                                value={value}
                                error={errors.website}
                                helperText={errors.website && errors.website?.message}
                            />
                        )}
                    />
                    {props.companies.length > 0 &&
                        <Controller
                            control={control}
                            name='parent'
                            defaultValue={null}
                            render={({ field: { value, onChange } }) => (
                                <CompaniesAutoComplete
                                    id='parent'
                                    label='Empresa Mãe'
                                    name='parent'
                                    noOptionsText='Sem empresas'
                                    data={props.companies}
                                    value={value}
                                    onChange={(val) => { onChange(val) }}
                                    error={errors.parent ? true : false}
                                    helperText={errors.parent && errors.parent?.message}
                                />
                            )}
                        />
                    }
                    <CustomTooltip title='Poderá fazer upload do logo da empresa, embora não seja obrigatório. Caso a empresa que pretende criar tenha uma empresa "mãe" e se não fizer upload de um logo, esta irá herdar o logo da empresa "mãe".' >
                        <Help />
                    </CustomTooltip>
                    <ImageDropzone setFile={(file) => setImageFile(file)} dropzoneOptions={dropzoneOptions} />
                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Criar empresa</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default AddCompany