import React, { useState, useContext, useEffect } from 'react'
import { SocketContext } from '../../../Context/socket/socket'

import './DecompProgress.css'

function DecompProgress() {
    const socket = useContext(SocketContext)
    const [decompressProgressData, setDecompressProgressData] = useState()

    useEffect(() => {
        socket.on('decompress_progress', data => showDecompressionProgress(data))

        return () => {
            socket.off('decompress_progress', showDecompressionProgress)
        }
    }, [])


    const showDecompressionProgress = data => {
        const percentage = Math.floor((data.entryNumber / data.totalEntries) * 100);

        setDecompressProgressData({...data, percentage})

        if (data.entryNumber === data.totalEntries) {
            setTimeout(() => {
                setDecompressProgressData(null)
            }, 2000)
        }
    }

    return (
        <div className={`decompress-progress-div ${decompressProgressData ? 'show' : 'hide'}`}>
            <p>A descompactar ficheiros do projeto {decompressProgressData?.projectName}</p>
            <p style={{ fontSize: '12px', wordBreak: 'break-word' }}>Ficheiro: {decompressProgressData?.fileName}</p>
            <p>{decompressProgressData?.entryNumber}/{decompressProgressData?.totalEntries}</p>
            <div className='progress-bar'>
                <div className='inner-progress-bar' style={{ width: `${decompressProgressData ? decompressProgressData.percentage : 0}%` }}>
                    <p style={{ marginRight: '5px' }}>{decompressProgressData?.percentage}%</p>
                </div>
            </div>
        </div>
    )
}

export default DecompProgress