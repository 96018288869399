import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import useDialog from "../Hooks/useDialog";

export default function useGlobalSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { openErrDialog } = useDialog()

    const Actions = ({ snackbarKey, description }) => {
        return (
            <>
                {description &&
                    <Button onClick={() => { openErrDialog(description); closeSnackbar(snackbarKey) }} color='inherit' size='small'>VER</Button>
                }
                <Button onClick={() => closeSnackbar(snackbarKey)} color='inherit' size='small'>OK</Button>
            </>
        )
    }

    const showSnackbar = (
        data = {
            message: '',
            description: '',
            customComponent: null,
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            persist: false,
            autoHideDuration: 5000,
        }
    ) => {
        enqueueSnackbar(data.message, {
            variant: data.variant ? data.variant : 'success',
            anchorOrigin: data.anchorOrigin ? data.anchorOrigin : { vertical: 'bottom', horizontal: 'center' },
            persist: data.variant === 'error' ? true : data.persist ? data.persist : false,
            autoHideDuration: data.autoHideDuration ? data.autoHideDuration : 5000,
            content: (key, message) => getRespectiveComponent(key, message, data.customComponent),
            action: (key) => <Actions snackbarKey={key} description={data.description} />
        })
    }

    const getRespectiveComponent = (key, message, customComponent) => {
        switch (customComponent) {
            // case 'newOrder': return <div><NewOrderSnackbar id={key} message={message} /></div>
            default: return null
        }
    }

    return {
        showSnackbar
    }
}