import { useModal } from 'mui-modal-provider'
import DeleteProject from '../../Components/Dialogs/Projects/DeleteProject'
import AddProject from '../../Components/Dialogs/Projects/AddProject'
import EditProject from '../../Components/Dialogs/Projects/EditProject'
import ViewMoreProject from '../../Components/Dialogs/Projects/ViewMoreProject'

export default function useProjectsDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (project, refresh) => {
        const modal = showModal(DeleteProject, {
            project,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = refresh => {
        const modal = showModal(AddProject, {
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (project, refresh) => {
        const modal = showModal(EditProject, {
            project,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openViewMore = (project, companyLink) => {
        const modal = showModal(ViewMoreProject, {
            project,
            companyLink,
            close: () => modal.hide(),
        })
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog,
        openViewMore
    }
}