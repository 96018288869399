import { useModal } from 'mui-modal-provider'
import DeleteAppUserDialog from '../../Components/Dialogs/AppUsers/DeleteAppUserDialog'
import AddAppUserDialog from '../../Components/Dialogs/AppUsers/AddAppUserDialog'
import EditAppUserDialog from '../../Components/Dialogs/AppUsers/EditAppUserDialog'
import ResendCredDialog from '../../Components/Dialogs/AppUsers/ResendCredDialog'
import ViewMoreAppUserDialog from '../../Components/Dialogs/AppUsers/ViewMoreAppUserDialog'

export default function useAppUsersDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (user, refresh) => {
        const modal = showModal(DeleteAppUserDialog, {
            user,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = (refresh, companies) => {
        const modal = showModal(AddAppUserDialog, {
            refresh,
            companies,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (user, companies, refresh) => {
        const modal = showModal(EditAppUserDialog, {
            user,
            refresh,
            companies,
            close: () => modal.hide(),
        })
    }

    const openResendCred = (user) => {
        const modal = showModal(ResendCredDialog, {
            user,
            close: () => modal.hide(),
        })
    }

    const openViewMore = (user, companyLink) => {
        const modal = showModal(ViewMoreAppUserDialog, {
            user,
            companyLink,
            close: () => modal.hide(),
        }) 
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog,
        openResendCred,
        openViewMore
    }
}