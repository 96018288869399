import React from 'react'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { RRule } from 'rrule'
import { StyledEngineProvider } from '@mui/material'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const buildRRule = () => {

    const rule = new RRule({
        dtstart: moment().subtract(1, 'months')._d,
        freq: RRule.DAILY,
        interval: 1,
        count: 32
    }).all();

    for (let i = 0; i < rule.length; i++) {
        rule[i] = moment(rule[i]).format('DD MMM')
    }

    return rule;
}

function MiniChart({ entity }) {
    let counter = 0 //place inside of calculateViewsPerDay for a non evolution chart
    
    const prepareDataToGraph = () => {
        const data = []
        const numberOfDays = buildRRule('month');
        
        numberOfDays.forEach((day, index) => {
            data.push({ x: day, y: calculateViewsPerDay(day) })
        })
        return data
    }
    
    const calculateViewsPerDay = (day) => {

        entity.forEach((el, index) => {
            if (moment(new Date(el.createdAt)).format('DD MMM') === day) {
                entity = entity.filter(item => item !== el)
                counter++;
            }
        })

        return counter
    }

    const getColor = () => {
        return localStorage.getItem('mode') === 'light' ? '#1976d2' : '#ed6c02'
    }

    const chartData = {
        labels: buildRRule('month'),
        datasets: [{ data: prepareDataToGraph()}]
    };

    const options = {
        tension: 0.3,
        cubicInterpolationMode: 'monotone',
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                borderColor: getColor()
            },
            point: {
                radius: 0,
            }
        },
        scales: {
            y: {
                display: false
            },
            x: {
                display: false
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: false,
            },
        },
    }

    return (
        <Line options={options} data={chartData} />
    )
}

export default MiniChart