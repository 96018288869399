import React, { useState, useEffect } from 'react'
import ProjectSmallCard from '../../Cards/Projects/ProjectSmallCard'
import { TextField } from '../../Inputs/TextField/TextField'
import { useDebounce } from '../../../utils'
import { Clear } from '@mui/icons-material'
import useShareProjectsDialog from '../../../Hooks/Dialogs/useShareProjectsDialog'

import './ProjectsList.css'
import Button from '../../Buttons/Button'

function ProjectsList({ projects, ...props }) {
    const [searchString, setSearchString] = useState('')
    const [filteredProjects, setFilteredProjects] = useState(projects)
    const debounceSearch = useDebounce(searchString, 500)
    const { openShareProjectsUser } = useShareProjectsDialog()

    useEffect(() => {
        setFilteredProjects(projects)
    }, [projects])

    useEffect(() => {
        if (searchString.length === 0) {
            setFilteredProjects(projects)
            return;
        }

        const filter = projects.filter(project => project.name.toLowerCase().includes(searchString.toLowerCase()))
        setFilteredProjects(filter)

    }, [debounceSearch])

    return (
        <>
            <div className='projects-search-div'>
                <TextField
                    name='search'
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    placeholder='Procurar por projeto'
                    endIcon={
                        <Clear
                            sx={searchString.length > 0 ? { display: 'flex' } : { display: 'none' }}
                            onClick={() => setSearchString('')}
                        />
                    }
                />
                {props.type === 'shared' &&
                    <Button size='small' onClick={() => openShareProjectsUser(props.userId, projects, props.refresh)}>Gerir</Button>
                }
            </div>
            <div className='projects-list-wrapper'>
                <div className='projects-list'>
                    {filteredProjects.length > 0 ?
                        filteredProjects.map(project => (
                            <ProjectSmallCard key={project.id} project={project} />
                        ))
                        :
                        <p style={{ textAlign: 'center' }}>Sem projetos para apresentar</p>
                    }
                </div>
            </div>
        </>
    )
}

export default ProjectsList