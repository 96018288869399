import React from 'react'
import { Radio } from '@mui/material'
import CustomTooltip from '../../Misc/CustomTooltip'

import './CustomRadio.css'

function CustomRadio({ itens, onChange, value }) {
    return (
        <div className='custom-radio-main-div'>
            {itens.map(item => (
                <div key={item.id} className='custom-radio-item'>
                    <Radio checked={value === item.value} value={item.value} onChange={onChange} />
                    <CustomTooltip title={item.tooltip}>
                        <p>{item.label}</p>
                    </CustomTooltip>
                </div>
            ))}

        </div>
    )
}

export default CustomRadio