import React, { useState } from 'react'
import { Visibility, Share } from '@mui/icons-material'
import { openInNewTab } from '../../../utils'
import useProjectsDialog from '../../../Hooks/Dialogs/useProjectsDialog'

import noImage from '../../../Assets/placeholder/no_image.jpg'
import imagePlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './ProjectCards.css'

function ProjectSmallCard({ project, companyLink }) {
    const [imageSrc, setImageSrc] = useState(`${process.env.REACT_APP_SERVER_URL}/project/${project.folder}/socialThumbnail.jpg`)
    const { openViewMore } = useProjectsDialog()

    const onImageClick = () => {
        openInNewTab(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${project.folder}`)
    }

    return (
        <div className='project-small-card-div'>
            <div className='project-small-card-image-div'>
                <img
                    alt={project.name}
                    src={imageSrc}
                    onClick={onImageClick}
                    onError={() => setImageSrc(noImage)}
                />
            </div>
            <div className='project-small-card-info' onClick={() => openViewMore(project, false)}>
                <p className='project-small-card-name'>{project.name}</p>
                {project.company &&
                    <div className='projects-small-card-company-div'>
                        <img alt={project.company.name} src={project.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${project.company.image}` : imagePlaceholder} />
                        <p className='project-small-card-company-name'>{project.company.name}</p>
                    </div>
                }
                <div className='projects-small-card-stats'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Visibility htmlColor='var(--table-buttons)' />
                        <p>{project.views}</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Share htmlColor='var(--table-buttons)' />
                        <p>{project.shares}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSmallCard