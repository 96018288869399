import React, { useState, useEffect } from 'react'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { createEntity, getEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import { CompaniesAutoComplete, GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import Button from '../../Buttons/Button'

import '../GenericCRUDDialog.css'

function AddTable(props) {
	const [loading, setLoading] = useState(false)
	const [companies, setCompanies] = useState([])
	const [tableTypes, setTableTypes] = useState([])

	const { showSnackbar } = useGlobalSnackbar()
	const { control, handleSubmit, formState: { errors } } = useForm();

	const tableTypeData = [
		{ id: 1, name: 'New Business', value: 'new_business' },
		{ id: 2, name: 'Final Design', value: 'final_design' }
	]

	useEffect(() => {
		if (props.open) {
			getEntity('companies').then(res => {
				setCompanies(res.data.plainData)
			}, err => {
				console.log(err)
			})
		}
	}, [props.open])

	const submitToServer = (data) => {
		const tableType = data.tableType.map(el => el.value)
		const companyId = data.companyId.id;
		const finalData = { ...data, tableType, companyId }

		setLoading(true)

		createEntity('tables', finalData).then(res => {
		    props.close()
		    props.refresh()
		    showSnackbar({ message: res.data })
		    setLoading(false)
		}, err => {
		    console.log(err)
		    showSnackbar({ message: 'Ocorreu um erro ao tentar criar a tabela', description: err.response, variant: 'error' })
		    setLoading(false)
		})
	}

	return (
		<DialogPrefab
			open={props.open}
			close={props.close}
			maxWidth='sm'
			title='Adicionar nova tabela'
		>
			<div className='add-dialog-div'>
				<p className='add-dialog-title'>Utilize o formulário a baixo para adicionar uma nova tabela</p>
				<form className='add-dialog-user-fields' onSubmit={handleSubmit(submitToServer)}>
					<div className='add-dialog-joined-fields'>
						<Controller
							control={control}
							name='name'
							defaultValue=''
							render={({ field: { value, onChange } }) => (
								<TextField
									disabled={loading}
									label='Nome'
									name='name'
									onChange={onChange}
									value={value}
									helperText='Campo não obrigatório'
								/>
							)}
						/>
						<Controller
							control={control}
							name='year'
							defaultValue={new Date().getFullYear()}
							rules={{
								required: 'Indique um ano',
							}}
							render={({ field: { value, onChange } }) => (
								<TextField
									disabled={loading}
									label='Ano*'
									name='year'
									type='number'
									onChange={onChange}
									value={value}
									error={errors.year ? true : false}
									helperText={errors.year && errors.year?.message}
								/>
							)}
						/>
					</div>
					<Controller
						control={control}
						name='companyId'
						defaultValue={null}
						rules={{
							required: 'Escolha uma empresa para esta tabela',
						}}
						render={({ field: { value, onChange } }) => (
							<CompaniesAutoComplete
								disabled={loading}
								id='companyId'
								label='Empresa'
								name='companyId'
								data={companies}
								value={value}
								onChange={(val) => { onChange(val) }}
								error={errors.companyId ? true : false}
								helperText={errors.companyId && errors.companyId?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name='tableType'
						defaultValue={[]}
						rules={{
							required: 'Escolha pelo menos um tipo de tabela',
						}}
						render={({ field: { value, onChange } }) => (
							<GenericAutoComplete
								disableCloseOnSelect={true}
								multiple={true}
								id='tableType'
								label='Tipo de tabela'
								name='tableType'
								optionLabel='name'
								data={tableTypeData}
								value={value}
								fullWidth
								onChange={(val) => { onChange(val); setTableTypes(val) }}
								error={errors.tableType ? true : false}
								helperText={errors.tableType && errors.tableType?.message}
							/>
						)}
					/>
					<div className='add-table-number-of-projects-div'>
						{tableTypes.findIndex(el => el.id === 1) !== -1 &&
							<div className='add-table-inner-projects-div'>
								<p>Tabela New Business</p>
								<Controller
									control={control}
									name='newBusinessRows'
									defaultValue=''
									rules={{
										required: 'Indique o número de projetos para esta tabela',
										min: { value: 1, message: 'Tem que selecionar 1 ou mais' }
									}}
									render={({ field: { value, onChange } }) => (
										<TextField
											disabled={loading}
											label='Nº Projetos'
											name='newBusinessRows'
											onChange={onChange}
											value={value}
											type='number'
											error={errors.newBusinessRows ? true : false}
											helperText={errors.newBusinessRows && errors.newBusinessRows?.message}
										/>
									)}
								/>
							</div>
						}
						{tableTypes.findIndex(el => el.id === 2) !== -1 &&
							<div className='add-table-inner-projects-div'>
								<p>Tabela Final Design</p>
								<Controller
									control={control}
									name='finalDesignRows'
									defaultValue=''
									rules={{
										required: 'Indique o numero de projetos para esta tabela',
										min: { value: 1, message: 'Tem que selecionar 1 ou mais' }
									}}
									render={({ field: { value, onChange } }) => (
										<TextField
											disabled={loading}
											label='Nº Projetos'
											name='finalDesignRows'
											onChange={onChange}
											value={value}
											type='number'
											error={errors.finalDesignRows ? true : false}
											helperText={errors.finalDesignRows && errors.finalDesignRows?.message}
										/>
									)}
								/>
							</div>
						}
					</div>
					<Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Criar Tabela</Button>
				</form>
			</div>
		</DialogPrefab>
	)
}

export default AddTable