import React, { useState, useEffect } from "react";
import Button from "../../Buttons/Button";
import DialogPrefab from "../DialogPrefab";
import { TextField } from "../../Inputs/TextField/TextField";
import { editEntityWithFile, getEntity } from "../../../API/Requests";
import useGlobalSnackbar from "../../../Hooks/useGlobalSnackbar";
import { Controller, useForm } from "react-hook-form";
import { CompaniesAutoComplete } from "../../Inputs/AutoComplete/CustomAutocomplete";
import ProjectFilesDropzone from "../../Inputs/Dropzone/ProjectFilesDropzone";
import CustomRadio from "../../Inputs/CustomRadio/CustomRadio";
import CustomSwitch from "../../Inputs/CustomSwitch";
import useDialog from "../../../Hooks/useDialog";

import "../GenericCRUDDialog.css";

function EditProject(props) {
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [files, setFiles] = useState(null);
	const [progress, setProgress] = useState(0);
	const [editMode, setEditMode] = useState("replace");

	const { openInfoDialog } = useDialog();
	const { showSnackbar } = useGlobalSnackbar();
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (props.open) {
			getEntity("companies").then(
				res => {
					setCompanies(res.data.plainData);
				},
				err => {
					console.log(err);
				},
			);
		}
	}, [props.open]);

	const dropzoneOptions = {
		options: {
			multiple: true,
		},
		text: {
			title: "Clique aqui para carregar os ficheiros.",
		},
	};

	const radioItens = [
		{
			id: 1,
			value: "replace",
			label: "Fazer upload e substituir apenas ficheiros diferentes",
			tooltip:
				"Esta opção faz com que os ficheiros já existentes se mantenham intactos e só serão substituídos ficheiros que sejam iguais aos novos.",
		},
		{
			id: 2,
			value: "remove",
			label: "Remover todos os ficheiros existente e depois fazer o upload",
			tooltip:
				"Esta opção faz com que antes de ser feito o upload dos novos ficheiros, todos os ficheiros existentes do projeto sejam apagados e só depois é que será feito o upload dos novos ficheiros.",
		},
	];

	const preSubmit = data => {
		if (data.company.id !== props.project.company.id) {
			openInfoDialog(
				{
					title: "Enviar email de notificação?",
					message:
						"A empresa associada a este projeto foi alterada.\nPretende enviar email a notificar os users da nova empresa?",
				},
				"y/n",
				() => submitToServer(data, true),
				() => submitToServer(data, false),
			);
		} else {
			submitToServer(data, false);
		}
	};

	const submitToServer = (data, sendEmail) => {
		const { name, company, online, analyticsCode } = data;
		const formData = new FormData();

		if (name !== props.project.name) formData.set("name", name);
		if (company.id !== props.project.company.id) formData.set("companyId", company.id);
		if (online !== props.project.online) formData.set("online", online);
		if (analyticsCode !== props.project.analyticsCode) formData.set("analyticsCode", analyticsCode);
		formData.set("sendEmail", sendEmail);

		if (files) {
			formData.append("files", files);
			formData.set("editMode", editMode);
		}

		if (Array.from(formData.keys()).length === 0) {
			showSnackbar({ message: "Não foram detetadas alterações", variant: "info" });
			return;
		}

		setLoading(true);
		const config = {
			onUploadProgress: progressEvent => {
				setProgress(Math.floor((progressEvent.loaded / progressEvent.total) * 100));
			},
		};

		editEntityWithFile("projects", props.project.id, formData, config).then(
			res => {
				props.close();
				props.refresh();
				showSnackbar({ message: res.data });
				setLoading(false);
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro ao tentar editar o projeto",
					description: err.response,
					variant: "error",
				});
				setLoading(false);
			},
		);
	};

	return (
		<DialogPrefab
			open={props.open}
			close={props.close}
			maxWidth="xs"
			title={`Editar projeto ${props.project.name}`}
		>
			<div className="add-dialog-div">
				<p className="add-dialog-title">{`Utilize o formulário a baixo para editar projeto ${props.project.name}`}</p>
				<form className="add-dialog-user-fields" onSubmit={handleSubmit(preSubmit)}>
					<Controller
						control={control}
						name="name"
						defaultValue={props.project.name}
						rules={{
							required: "Campo Obrigatório",
						}}
						render={({ field: { value, onChange } }) => (
							<TextField
								disabled={loading}
								label="Nome*"
								name="name"
								onChange={onChange}
								value={value}
								error={errors.name}
								helperText={errors.name?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="company"
						defaultValue={props.project.company}
						rules={{
							required: "Escolha uma empresa para este projeto",
						}}
						render={({ field: { value, onChange } }) => (
							<CompaniesAutoComplete
								disabled={loading}
								id="company"
								label="Empresa"
								name="company"
								data={companies}
								value={value}
								onChange={val => {
									onChange(val);
								}}
								error={errors.company ? true : false}
								helperText={errors.company?.message}
							/>
						)}
					/>
					<div style={{ display: "flex", gap: "20px" }}>
						<Controller
							control={control}
							name="analyticsCode"
							defaultValue={props.project.analyticsCode ? props.project.analyticsCode : ""}
							render={({ field: { value, onChange } }) => (
								<TextField
									disabled={loading}
									label="Código Analytics*"
									name="analyticsCode"
									onChange={onChange}
									value={value}
									error={errors.analyticsCode}
									helperText={errors.analyticsCode?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="online"
							defaultValue={props.project.online}
							render={({ field: { value, onChange } }) => (
								<CustomSwitch
									disabled={loading}
									label="Online?"
									name="online"
									onChange={onChange}
									checked={value}
								/>
							)}
						/>
					</div>
					{loading ? (
						<>
							<div className="progress-bar">
								<div className="inner-progress-bar" style={{ width: `${progress}%` }}>
									<p style={{ marginRight: "5px" }}>{progress}%</p>
								</div>
							</div>
							<p style={{ fontSize: "12px", textAlign: "center" }}>
								A fazer o upload dos ficheiros por favor aguarde...
							</p>
						</>
					) : (
						<div className="edit-project-bottom-div">
							<ProjectFilesDropzone
								setFiles={files => setFiles(files)}
								files={files}
								dropzoneOptions={dropzoneOptions}
							/>
							{files && (
								<CustomRadio
									itens={radioItens}
									onChange={e => setEditMode(e.target.value)}
									value={editMode}
								/>
							)}
							<Button style={{ marginTop: "20px" }} loading={loading} type="submit">
								Editar Projeto
							</Button>
						</div>
					)}
				</form>
			</div>
		</DialogPrefab>
	);
}

export default EditProject;
