import React, { useState, useEffect } from 'react'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import { useNavigate } from 'react-router-dom'
import { getUserForPassReset, resetPass } from '../API/Requests'
import { PasswordTextField } from '../Components/Inputs/TextField/TextField'
import Button from '../Components/Buttons/Button'
import ThemeToggle from '../Components/Misc/ThemeToggle'

import './css/ResetPass.css'

function ResetPass() {
    const { showSnackbar } = useGlobalSnackbar()
    const navigate = useNavigate()
    const [user, setUser] = useState(null)

    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
        passwordIsValid: true,
        confirmPasswordIsValid: true,
        formIsValid: false,
        formErrors: { password: '', confirmPassword: '' }

    })

    const navigateToLogin = () => navigate('/login', { replace: true })
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {

        if (!urlParams.get('secret') || !urlParams.get('uid')) {
            navigateToLogin();
            return;
        }

        getUserForPassReset(urlParams.get('uid'), urlParams.get('secret')).then(res => {
            setUser(res.data.name ? res.data.name : res.data.email)
            console.log(res)
        }, err => {
            navigateToLogin()
            return;
        })

    }, [])

    const handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value

        setState(state => ({ ...state, ...{ [name]: value } }))

        switch (name) {
            case 'password':
                const passIsValid = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/).test(value)
                if (state.confirmPassword.length > 0) setState(state => ({ ...state, ...{ confirmPassword: '', confirmPasswordIsValid: false } }))
                setState(state => ({
                    ...state,
                    ...{
                        formIsValid: false,
                        passwordIsValid: passIsValid,
                        formErrors: {
                            password: passIsValid ? '' : 'Palavra passe tem que ter pelo menos 8 caracteres, com maiúsculas, minúsculas e números',
                            confirmPassword: state.formErrors.confirmPassword
                        }
                    }
                }))
                break;
            case 'confirmPassword':
                const confirmPassISValid = value === state.password
                setState(state => ({
                    ...state,
                    ...{
                        confirmPasswordIsValid: confirmPassISValid,
                        formErrors: {
                            password: state.formErrors.password,
                            confirmPassword: confirmPassISValid ? '' : 'Palavras passe não coincidem'
                        }
                    }
                }))
                break;
            default: return ''
        }
    }

    useEffect(() => {
        if (state.confirmPassword.length > 0 && state.password.length > 0) {
            setState(state => ({ ...state, ...{ formIsValid: state.passwordIsValid && state.confirmPasswordIsValid } }))
        }
    }, [state.password, state.confirmPassword])

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            id: urlParams.get('uid'),
            password: state.password,
            secret: urlParams.get('secret'),
            secure: true
        }

        setLoading(true)

        const timer = setTimeout(() => {
            resetPass(data).then(res => {
                setLoading(false)
                showSnackbar({ message: `${res.data}. Irá ser redirecionado para a pagina de login dentro de 5 segundos`, persist: false })
                clearTimeout(timer)
                setTimeout(() => {
                    navigate('/login', { replace: true })
                }, 5000)
            }, err => {
                console.log(err)
                setLoading(false)
                clearTimeout(timer)
                showSnackbar({ message: err.response?.data ? err.response.data : 'Cannot communicate with the server, please try again later', variant: 'error' })
            })
        }, 1000)
    }

    return (
        <div className='reset-password-main-div'>
            <div className='reset-password-form'>
                <div className='reset-password-top-text'>
                    {user && <p className='reset-pass-user-name'>{`Olá ${user},`}</p>}
                    <p className='reset-pass-text'>Defina aqui a sua nova password</p>
                </div>
                <PasswordTextField
                    required
                    label='Nova palavra passe'
                    name='password'
                    helperText={state.formErrors.password}
                    onChange={handleInput}
                    autoComplete='new-password'
                    value={state.password}
                    error={!state.passwordIsValid}
                />
                <PasswordTextField
                    required
                    name='confirmPassword'
                    label='Confirme palavra passe'
                    onChange={handleInput}
                    autoComplete='new-password'
                    helperText={state.formErrors.confirmPassword}
                    value={state.confirmPassword}
                    error={!state.confirmPasswordIsValid}
                    style={{ marginBottom: '10px' }}
                />
                <div className='reset-password-buttons'>
                    {!loading &&
                        <Button onClick={() => navigateToLogin()}>Cancelar</Button>
                    }
                    <Button loading={loading} onClick={handleSubmit} disabled={!state.formIsValid}>Confirmar</Button>
                </div>
            </div>
            <div className='reset-pass-theme-toggle'>
                <ThemeToggle />
            </div>
        </div>
    )
}

export default ResetPass