import { useModal } from 'mui-modal-provider'
import AddCompany from '../../Components/Dialogs/Companies/AddCompany'
import DeleteCompany from '../../Components/Dialogs/Companies/DeleteCompany'
import EditCompany from '../../Components/Dialogs/Companies/EditCompany'
import ViewMoreCompany from '../../Components/Dialogs/Companies/ViewMoreCompany'

export default function useCompaniesDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (company, plainData, refresh) => {
        const modal = showModal(DeleteCompany, {
            company,
            plainData,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = (companies, refresh) => {
        const modal = showModal(AddCompany, {
            companies,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (company, companiesData, refresh) => {
        const modal = showModal(EditCompany, {
            company,
            companiesData,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openViewMore = (company) => {
        const modal = showModal(ViewMoreCompany, {
            company,
            close: () => modal.hide(),
        })
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog,
        openViewMore
    }
}