import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import './TextField.css'

export function TextField({ style, helperText, helperTextAction, error, onEnter, endIcon, ...props }) {

    return (
        <div className={`textfield-main-div`} style={style} >
            <p className='textfield-label'>{props.label}</p>
            <input
                name={props.name}
                className={`textfield-input-input${helperText ? ' helper-text' : ''}${error ? ' error' : ''}`}
                type={props.type ? props.type : 'text'}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                autoComplete={props.type}
                onKeyDown={e => {
                    if (e.key === 'Enter') onEnter && onEnter()
                }}
                {...props}
            />
            {endIcon &&
                <div className='textfield-endIcon'>
                    {endIcon}
                </div>
            }
            {helperTextAction ?
                <p className='helper-text-action' onClick={helperTextAction}>{helperText}</p> : <p className='helper-text-p'>{helperText}</p>
            }
        </div>
    )
}


export function PasswordTextField({ style, helperText, helperTextAction, error, onEnter, dark, ...props }) {
    const [show, setShow] = useState(false)

    return (
        <div className={`textfield-main-div ${dark ? 'dark' : 'light'}`} style={style}>
            <p className='textfield-label'>{props.label ? props.label : 'Palavra passe'}</p>
            <input
                name={props.name ? props.name : 'password'}
                className={`textfield-input-input${helperText ? ' helper-text' : ''}${error ? ' error' : ''}`}
                type={show ? 'text' : 'password'}
                value={props.value}
                maxLength={40}
                onChange={props.onChange}
                onCopy={(e) => e.preventDefault()}
                onCut={(e) => e.preventDefault()}
                autoComplete={props.autoComplete ? props.autoComplete : 'current-password'}
                onKeyDown={e => {
                    if (e.key === 'Enter') onEnter && onEnter()
                }}
                {...props}
            />
            {helperTextAction ?
                <p className='helper-text-action' onClick={helperTextAction}>{helperText}</p> : <p className='helper-text-p'>{helperText}</p>
            }
            <div className='show-pass-button' onClick={() => setShow(!show)}>
                {show ? <VisibilityOff htmlColor='var(--pass-btn)' /> : <Visibility htmlColor='var(--pass-btn)' />}
            </div>
        </div>
    )
}

