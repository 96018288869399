import { useModal } from 'mui-modal-provider'
import AddBackofficeUser from '../../Components/Dialogs/BackofficeUsers/AddBackofficeUser'
import DeleteBackofficeUser from '../../Components/Dialogs/BackofficeUsers/DeleteBackofficeUser'
import EditBackofficeUser from '../../Components/Dialogs/BackofficeUsers/EditBackofficeUser'

export default function useBackofficeUserDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (user, refresh) => {
        const modal = showModal(DeleteBackofficeUser, {
            user,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = refresh => {
        const modal = showModal(AddBackofficeUser, {
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (user, refresh) => {
        const modal = showModal(EditBackofficeUser, {
            refresh,
            user,
            close: () => modal.hide(),
        })
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog
    }
}