import React, { useState, useMemo, useEffect } from "react";
import PageTop from "../Panels/PageTop/PageTop";
import TablePrefab from "../Components/Tables/TablePrefab";
import { editEntity, getEntity, sendNewProjectEmail } from "../API/Requests";
import { CompanyAndPicture, SwitchComponent } from "../Components/Tables/TableComponents/RowsComponents";
import useProjectsDialog from "../Hooks/Dialogs/useProjectsDialog";
import moment from "moment";
import useGlobalSnackbar from "../Hooks/useGlobalSnackbar";
import { openInNewTab } from "../utils";
import useShareProjectsDialog from "../Hooks/Dialogs/useShareProjectsDialog";
import useDialog from "../Hooks/useDialog";

import "./css/Projects.css";

function Projects() {
	const [projects, setProjects] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const { showSnackbar } = useGlobalSnackbar();
	const { openDeleteDialog, openAddDialog, openEditDialog, openViewMore } = useProjectsDialog();
	const { openShareProjectsProject } = useShareProjectsDialog();
	const { openInfoDialog } = useDialog();

	const topPageInfo = [{ label: "Número de projetos", value: projects.length }];

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setLoading(true);
		getEntity("projects").then(
			res => {
				setProjects(res.data);
				setExportData(mapExportData(res.data));
				setLoading(false);
			},
			err => {
				console.log(err);
			},
		);
	}, [refresh]);

	const mapExportData = data => {
		return data.map(el => ({
			id: el.id,
			name: el.name,
			company: el.company?.name,
			createdAt: moment(el.createdAt).format("DD MMM YYYY HH:mm"),
			updatedAt: moment(el.updatedAt).format("DD MMM YYYY HH:mm"),
		}));
	};

	const changeOnline = (e, project) => {
		const updatedProjects = projects.map(el => {
			if (el.id === project.id) {
				el.online = e.target.checked;
			}
			return el;
		});

		setProjects(updatedProjects);

		editEntity("projects", project.id, { online: e.target.checked }).then(
			() => {
				showSnackbar({ message: `${project.name} está agora ${e.target.checked ? "online" : "Offline"}` });
			},
			err => {
				showSnackbar({
					message: err.response?.data ? err.response.data : "Não foi possível comunicar com o servidor",
					variant: "error",
				});
			},
		);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
				columnFilterModeOptions: ["equals", "between", "betweenInclusive", "greaterThan", "lessThan"],
				filterFn: "equals",
				enableGrouping: false,
			},
			{
				accessorKey: "name",
				header: "Nome",
				columnFilterModeOptions: ["fuzzy", "contains", "startsWith", "endsWith"],
				filterFn: "fuzzy",
				enableGrouping: false,
			},
			{
				accessorFn: row => row.company?.name,
				id: "company",
				header: "Empresa",
				columnFilterModeOptions: ["fuzzy", "contains", "startsWith", "endsWith"],
				filterFn: "fuzzy",
				aggregationFn: "count",
				Cell: ({ cell, row }) =>
					cell.getValue() ? (
						<CompanyAndPicture name={cell.getValue()} image={row.original.company.image} />
					) : (
						"Sem Empresa"
					),
				AggregatedCell: ({ cell }) => <div>Total users: {cell.getValue()}</div>,
				size: 250,
			},
			{
				header: "Online?",
				accessorFn: originalRow => (originalRow.online ? "true" : "false"),
				id: "online",
				filterVariant: "checkbox",
				Cell: ({ cell, row }) => (
					<SwitchComponent
						value={cell.getValue() === "true" ? true : false}
						onChange={e => changeOnline(e, row.original)}
						entity="Projects"
					/>
				),
				enableGrouping: false,
				size: 60,
			},
		],
		[projects],
	);

	const onEdit = data => {
		openEditDialog(data.row.original, () => setRefresh(!refresh));
	};

	const onView = data => {
		openViewMore(data.row.original, true);
	};

	const onDelete = data => {
		openDeleteDialog(data.row.original, () => setRefresh(!refresh));
	};

	const onAdd = () => {
		openAddDialog(() => setRefresh(!refresh));
	};

	const onVisit = data => {
		openInNewTab(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${data.row.original.folder}`);
	};

	const onShare = data => {
		openShareProjectsProject(data.row.original.id, null, null);
	};

	const onSendEmail = data => {
		const sendEmail = () =>
			sendNewProjectEmail(data.row.original.id).then(
				res => {
					console.log(res.data);
					showSnackbar({ message: "Email enviado com sucesso" });
				},
				err => {
					console.log(err);
					showSnackbar({
						message: err.response?.data ? err.response.data : "Não foi possível comunicar com o servidor",
						variant: "error",
					});
				},
			);

		if (data.row.original.emailSent) {
			openInfoDialog(
				{
					title: "Notificar os clientes por email?",
					message:
						"Já foi enviado uma notificação aos clientes por email sobre este projeto.\n\nPretende enviar novamente?",
				},
				"y/n",
				() => sendEmail(),
			);
		} else {
			openInfoDialog(
				{
					title: "Notificar os clientes por email?",
					message:
						"Os clientes associados à empresa deste projeto serão notificados por email.\n\nPretende continuar?",
				},
				"y/n",
				() => sendEmail(),
			);
		}
	};

	const onViewAnalytics = data => {
		if (data.row.original.analyticsCode) {
			openInNewTab(
				`https://analytics.google.com/analytics/web/#/p${data.row.original.analyticsCode}/reports/reportinghub`,
			);
		} else {
			showSnackbar({ message: "Este projeto não tem código de analytics associado", variant: "info" });
		}
	};

	return (
		<div className="projects-page-main-div">
			<PageTop title="Projetos" info={topPageInfo} view={"table"} addEntity={onAdd} addBtn="Projeto" />
			<div className="projects-table-div">
				<TablePrefab
					settingsId="projectsTable"
					columns={columns}
					data={projects}
					exportData={exportData}
					export
					roleId="Projects"
					title="Projetos"
					enableGrouping
					enablePinning
					enableColumnDragging={false}
					adicionalState={{ isLoading: loading }}
					enableColumnFilterModes
					onRowVisit={onVisit}
					onRowView={onView}
					onRowEdit={onEdit}
					onRowDelete={onDelete}
					onRowShareProject={onShare}
					onRowAnalytics={onViewAnalytics}
					onSendProjectEmail={onSendEmail}
				/>
			</div>
		</div>
	);
}

export default Projects;
