import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { PasswordTextField } from '../../Inputs/TextField/TextField'
import { removeEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'

import '../GenericCRUDDialog.css'

function DeleteAppUserDialog(props) {
    const [authPassword, setAuthPassword] = useState('')
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)

    const submit = () => {
        if (authPassword.length < 6) return;
        setLoading(true)
        removeEntity('app_users', props.user.id, authPassword).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar eliminar o utilizador', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`Eliminar utilizador ${props.user.name}`}
        >
            <div className='delete-dialog-div'>
                <p>{`Tem a certeza que pretende eliminar este utilizador?\n\nSe continuar, ${props.user.name} deixará de ter acesso à plataforma.`}</p>
                <div className='dialog-confirm-pass-and-button'>
                    <PasswordTextField
                        autoFocus={true}
                        style={{ textAlign: 'center' }}
                        helperText='Confirma a sua identidade introduzindo a sua password'
                        value={authPassword}
                        onChange={(e) => setAuthPassword(e.target.value)}
                        onEnter={() => submit()}
                    />
                    <Button loading={loading} disabled={authPassword.length < 6} onClick={submit}>Eliminar</Button>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteAppUserDialog