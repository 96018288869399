import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Checkbox, Divider, Tooltip } from "@mui/material";
import { TextField } from "../../../Inputs/TextField/TextField";
import Button from "../../../Buttons/Button";
import useDialog from "../../../../Hooks/useDialog";
import useGlobalSnackbar from "../../../../Hooks/useGlobalSnackbar";
import DialogPrefab from "../../DialogPrefab";
import { createEntity, editEntity, removeEntity } from "../../../../API/Requests";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

import "./UserRolesSettings.css";

const checkboxData = [
	{
		category: "App Users",
		permissions: [
			{ label: "Ver", property: "canViewAppUsers" },
			{ label: "Adicionar", property: "canCreateAppUsers" },
			{ label: "Editar", property: "canEditAppUsers" },
			{ label: "Remover", property: "canRemoveAppUsers" },
		],
	},
	{
		category: "Backoffice Users",
		permissions: [
			{ label: "Ver", property: "canViewBackofficeUsers" },
			{ label: "Adicionar", property: "canCreateBackofficeUsers" },
			{ label: "Editar", property: "canEditBackofficeUsers" },
			{ label: "Remover", property: "canRemoveBackofficeUsers" },
		],
	},
	{
		category: "Projetos",
		permissions: [
			{ label: "Ver", property: "canViewProjects" },
			{ label: "Adicionar", property: "canCreateProjects" },
			{ label: "Editar", property: "canEditProjects" },
			{ label: "Remover", property: "canRemoveProjects" },
		],
	},
	{
		category: "Empresas",
		permissions: [
			{ label: "Ver", property: "canViewCompanies" },
			{ label: "Adicionar", property: "canCreateCompanies" },
			{ label: "Editar", property: "canEditCompanies" },
			{ label: "Remover", property: "canRemoveCompanies" },
		],
	},
	{
		category: "Misc",
		permissions: [
			{ label: "Gerir Preferências", property: "canManageSettings" },
			{ label: "Gerir Permissões", property: "canManageRoles" },
			{ label: "Ver Estatísticas", property: "canViewStatistics" },
		],
	},
];

let stateChanges = {};

function UserRolesSettings(props) {
	const [selectedRole, setSelectedRole] = useState(null);
	const [originalData, setOriginalData] = useState();
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
	const { openInfoDialog } = useDialog();
	const { showSnackbar } = useGlobalSnackbar();

	const handleInput = e => {
		const name = e.target.name;
		const value = e.target.checked;

		setSelectedRole(state => ({
			...state,
			...{ [name]: Boolean(value) },
		}));

		if (originalData[name] !== value) {
			stateChanges[name] = Boolean(value);
		} else {
			delete stateChanges[name];
		}
	};

	useEffect(() => {
		if (selectedRole) {
			setOriginalData(props.userRoles.filter(role => role.id === selectedRole.id)[0]);
		}
	}, [selectedRole]);

	useEffect(() => {
		return () => {
			stateChanges = {};
		};
	}, []);

	const checkChanges = ycb => {
		if (Object.keys(stateChanges).length > 0) {
			openInfoDialog(
				{
					title: "Descartar alterações",
					message: "Você fez alterações e não gravou, pretende descartar estas alterações e continuar?",
				},
				"y/n",
				() => {
					setSelectedRole(null);
					stateChanges = {};
					ycb();
				},
			);
		} else {
			ycb();
		}
	};

	const handleChangeSelectedRole = role => {
		setSelectedRole(role);
		stateChanges = {};
	};

	const handleSubmit = async () => {
		editEntity("user_roles", selectedRole.id, stateChanges).then(
			res => {
				stateChanges = {};
				setSelectedRole(null);
				props.refresh();
				showSnackbar({ message: res.data });
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro com o seu pedido",
					description: err.response,
					variant: "error",
				});
			},
		);
	};

	const createNewRole = async name => {
		createEntity("user_roles", { name }).then(
			res => {
				setSelectedRole(null);
				props.refresh();
				showSnackbar({ message: res.data });
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro com o seu pedido",
					description: err.response,
					variant: "error",
				});
			},
		);
	};

	const removeRole = async () => {
		removeEntity("user_roles", selectedRole.id).then(
			res => {
				setSelectedRole(null);
				props.refresh();
				showSnackbar({ message: res.data });
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro com o seu pedido",
					description: err.response,
					variant: "error",
				});
			},
		);
	};

	const editRole = async name => {
		editEntity("user_roles", selectedRole.id, { name }).then(
			res => {
				setSelectedRole(null);
				props.refresh();
				showSnackbar({ message: res.data });
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro com o seu pedido",
					description: err.response,
					variant: "error",
				});
			},
		);
	};

	return (
		<div className="roles-settings-container">
			<p className="roles-settings-title">Cargos/Permissões Backoffice</p>
			<p className="roles-settings-subtitle">
				Aqui poderá criar, editar e eliminar cargos e as respetivas permissões
			</p>
			<div className="roles-settings-bottom">
				<div className="roles-selector">
					{props.userRoles.map(role => {
						return (
							<RolesCard
								key={role.id}
								active={role.id === selectedRole?.id ? "true" : "false"}
								name={role.name}
								onClick={() => checkChanges(() => handleChangeSelectedRole(role))}
							/>
						);
					})}
					<Tooltip title="Adicionar novo cargo">
						<div className="role-card" onClick={() => checkChanges(() => setOpenAddRoleDialog(true))}>
							+
						</div>
					</Tooltip>
				</div>
				<div className="roles-info">
					{selectedRole && (
						<div className="role-permissions-main-div">
							<div className="role-permissions-title-div">
								<p className="role-permissions-title">{selectedRole.name}</p>
								<div className="role-permissions-actions">
									<Tooltip title="Editar nome do cargo">
										<EditIcon onClick={() => checkChanges(() => setOpenEditDialog(true))} />
									</Tooltip>
									{selectedRole.id !== 1 && (
										<Tooltip title="Eliminar cargo">
											<DeleteIcon onClick={() => removeRole()} />
										</Tooltip>
									)}
								</div>
							</div>
							{checkboxData.map((category, index) => {
								return (
									<div key={index} className="roles-category">
										<p className="role-category-title">{category.category}</p>
										<Divider
											orientation="horizontal"
											sx={{ width: "90%", backgroundColor: "white" }}
										/>
										<div className="role-category-checkbox">
											<FormGroup key={index} sx={{ display: "flex", flexDirection: "row" }}>
												{category.permissions.map((permissions, index) => {
													return (
														<FormControlLabel
															key={index}
															label={
																<p style={{ color: "var(--text)", fontSize: "14px" }}>
																	{permissions.label}
																</p>
															}
															control={
																<Checkbox
																	name={permissions.property}
																	disabled={selectedRole.id === 1}
																	value={selectedRole[permissions.property]}
																	checked={selectedRole[permissions.property]}
																	onChange={e => handleInput(e)}
																/>
															}
														/>
													);
												})}
											</FormGroup>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<Button
				disabled={Object.keys(stateChanges).length < 1}
				style={{ marginTop: "15px" }}
				onClick={handleSubmit}
			>
				Gravar Alterações
			</Button>
			<EditDialog
				open={openEditDialog}
				close={() => setOpenEditDialog(false)}
				ocb={name => editRole(name)}
				default={selectedRole?.name}
			/>
			<AddDialog
				open={openAddRoleDialog}
				close={() => setOpenAddRoleDialog(false)}
				ocb={name => createNewRole(name)}
			/>
		</div>
	);
}

const RolesCard = props => {
	return (
		<div className={`role-card ${props.active === "true" ? "active" : ""}`} {...props}>
			<p>{props.name}</p>
		</div>
	);
};

const EditDialog = props => {
	const [value, setValue] = useState("");

	useEffect(() => {
		if (props.default) {
			setValue(props.default);
		}
	}, [props.default]);

	return (
		<DialogPrefab
			maxWidth="xs"
			open={props.open}
			close={props.close}
			aria-labelledby="max-width-dialog-title"
			title={`Editar cargo ${props.default}`}
		>
			<div className="info-dialog-action">
				<div className="info-dialog-text-div">
					<p>Indique o novo nome para o cargo</p>
					<TextField
						required
						autoFocus
						id="name"
						name="name"
						label="Nome"
						onEnter={() => {
							props.ocb(value);
							props.close();
						}}
						value={value}
						onChange={e => setValue(e.target.value)}
						error={value.length === 0}
					/>
				</div>
				<Button
					disabled={value.length === 0 || value === props?.default}
					onClick={() => {
						props.ocb(value);
						props.close();
					}}
				>
					Submeter
				</Button>
			</div>
		</DialogPrefab>
	);
};

const AddDialog = props => {
	const [value, setValue] = useState("");

	const buttonOnClickEvent = () => {
		props.ocb(value);
		setValue("");
		props.close();
	};

	return (
		<DialogPrefab
			maxWidth="xs"
			open={props.open}
			close={() => {
				props.close();
				setValue("");
			}}
			aria-labelledby="max-width-dialog-title"
			title="Adicionar novo cargo"
		>
			<div className="info-dialog-action">
				<div className="info-dialog-text-div">
					<p>Indique o nome do novo cargo</p>
					<TextField
						required
						autoFocus
						id="name"
						name="name"
						label="Nome"
						onEnter={buttonOnClickEvent}
						value={value}
						onChange={e => setValue(e.target.value)}
						error={value.length === 0}
					/>
				</div>
				<Button disabled={value.length === 0} onClick={buttonOnClickEvent}>
					Submeter
				</Button>
			</div>
		</DialogPrefab>
	);
};

export default UserRolesSettings;
