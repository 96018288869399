import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { sendForgotPass } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'

import './ForgotPassDialog.css'

function ForgotPassDialog(props) {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()

    const handleInput = (e) => {
        const value = e.target.value.trim();
        setEmail(value)

        if (!value) {
            setError('')
            return;
        }

        const isEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)

        if (isEmail) {
            setError('')
            return;
        }
        setError(`${value} não é um email válido`)
    }

    const submit = () => {
        setLoading(true)
        sendForgotPass(email).then(res => {
            console.log(res)
            setLoading(false)
            props.close()
            showSnackbar(`${res.data}`, '', "success", { vertical: 'bottom', horizontal: 'center' }, false)
        }, err => {
            console.log(err)
            setLoading(false)
            showSnackbar(err.response?.data ? err.response.data : 'Não foi possível comunicar com o servidor', '', 'error', { vertical: 'bottom', horizontal: 'center' }, false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title='Recuperar Palavra-passe'
            maxWidth='xs'
            margin='0'
        >
            <div className='forgot-pass-main-div'>
                <p className='forgot-pass-text'>Indique o seu email registado para poder recuperar a sua password</p>
                <TextField
                    name='email'
                    value={email}
                    onChange={handleInput}
                    required
                    autoFocus={true}
                    helperText={error}
                    error={error}
                    onEnter={submit}
                />
                <div className='forgot-pass-btn'>
                    <Button
                        loading={loading}
                        disabled={error || email.length === 0}
                        onClick={submit}
                        size='medium'
                    >
                        Submeter
                    </Button>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ForgotPassDialog