import React, { useState } from 'react'
import { PasswordTextField } from '../../Inputs/TextField/TextField'
import Button from '../../Buttons/Button'
import { Controller, useForm } from 'react-hook-form'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { updatePassword } from '../../../API/Requests'

import './MyProfile.css'

function ChangePassword(props) {
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)

    const onSubmit = data => {
        setLoading(true)

        updatePassword(props.user.id, data).then(res => {
            reset()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro no seu pedido', description: err.response ? err.response : 'Não foi possível comunicar com o servidor', variant: "error" })
            setLoading(false)
        })
    }

    return (
        <form className='edit-profile-form-fields' onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name='newPassword'
                defaultValue=''
                rules={{
                    required: 'Campo Obrigatório',
                    pattern: { value: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/, message: 'Password tem que conter pelo menos 8 caracteres com maiúsculas, minúsculas e números' }
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label='Nova Palavra passe'
                        value={value}
                        onChange={onChange}
                        error={errors.newPassword}
                        helperText={errors.newPassword && errors.newPassword?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='confirmPassword'
                defaultValue=''
                rules={{
                    required: 'Campo Obrigatório',
                    validate: pass => {
                        if (watch('newPassword') !== pass) {
                            return "Palavras passe não coincidem"
                        }
                    }
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label='Confirme a nova palavra passe'
                        value={value}
                        onChange={onChange}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword && errors.confirmPassword?.message}
                    />
                )}
            />
            <Controller
                control={control}
                name='authPassword'
                defaultValue=''
                rules={{
                    required: 'Campo Obrigatório',
                }}
                render={({ field: { value, onChange } }) => (
                    <PasswordTextField
                        label='Palavra passe atual'
                        style={{ marginTop: 'auto' }}
                        value={value}
                        onChange={onChange}
                        error={errors.authPassword}
                        helperText='Introduza a sua password para confirmar a sua identidade'
                    />
                )}
            />
            <Button style={{ marginTop: '10px' }} loading={loading} type='submit'>Submeter</Button>
        </form>
    )
}

export default ChangePassword