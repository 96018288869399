import React from 'react'
import { Autocomplete, Checkbox, Popper, TextField, Avatar } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import './CustomAutocomplete.css'

const CustomPopper = (props) => {
    return <Popper {...props} style={{ width: 'max-content', maxWidth: '300px', minWidth: '150px' }} />
}

const CustomAutoCompleteStyled = styled(TextField)({
    margin: '0',
    '& .MuiFormHelperText-root': {
        color: 'var(--textfield-error) !important',
        fontSize: '12px',
        marginLeft: '7px',
        fontFamily: '"JetBrains Mono", monospace'
    },
    '& .MuiInputBase-root': {
        width: '100%',
        height: '35px',
        borderRadius: '5px',
        letterSpacing: '1px',
        fontWeight: '300',
        border: 'none',
        outline: 'none',
        transition: 'box-shadow 0.2s ease-in-out',
        padding: '0 40px 0 10px;',
        color: 'var(--text)',
        boxShadow: 'var(--textfield-shadow)',
        backgroundColor: 'field',
        '&:hover': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '&:focus-visible': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '&.Mui-focused': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            padding: '0 !important',
            fontFamily: '"JetBrains Mono", monospace'
        },
        '&.Mui-error fieldset': {
            border: '1px solid var(--textfield-shadow-border)',
            boxShadow: 'var(--textfield-shadow-error)'
        },
    },
})


export function GenericAutoComplete({ data, label, id, onChange, value, name, optionLabel, helperText, error, customLabel, tagText, ...props }) {

    return (
        <Autocomplete
            id={id}
            renderTags={(tagValue) =>
                <p style={{ marginLeft: '10px' }}>{`${tagValue.length} ${tagText ? `${tagText} selecionado(s) ` : `${label} selecionado(s) `}`}</p>
            }
            tabIndex={-1}
            options={data}
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
            getOptionLabel={optionLabel ? (option) => option[optionLabel] : props.getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: '250px', height: 'fit-content', fontSize: '16px' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ color: 'var(--text)' }}
                        checked={selected}
                    />
                    {customLabel ? option[customLabel] : option[optionLabel]}
                </li>
            )}
            renderInput={(params) =>
                <div style={{ marginBottom: '20px' }}>
                    <p style={{ color: 'var(--text)' }}>{label}</p>
                    <CustomAutoCompleteStyled tabIndex={1} {...params} error={error} name={name} helperText={helperText} />
                </div>
            }
            {...props}
        />
    )
}

export function CompaniesAutoComplete({ data, label, id, onChange, value, name, optionLabel, helperText, error, customLabel, tagText, ...props }) {

    return (
        <Autocomplete
            id={id}
            tabIndex={-1}
            options={data}
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            fullWidth={true}
            onChange={(e, newValue) => onChange(newValue)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <li {...props} style={{ maxWidth: '300px', height: 'fit-content', fontSize: '16px' }}>
                    <div className='name-and-picture-div'>
                        <Avatar variant='rounded' alt={option.name} src={option.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${option.image}` : null} sx={{ width: '30px', height: '30px' }}>
                            {option.name.split('')[0]}
                        </Avatar>
                        <p>{option.name}</p>
                    </div>
                </li>
            )}
            renderInput={(params) =>
                <div style={{ marginBottom: '20px' }}>
                    <p style={{ color: 'var(--text)' }}>{label}</p>
                    <CustomAutoCompleteStyled tabIndex={1} {...params} error={error} name={name} helperText={helperText} />
                </div>
            }
            {...props}
        />
    )
}