import { useModal } from 'mui-modal-provider'
import ShareProjectsProject from '../../Components/Dialogs/ShareProjects/ShareProjectsProject'
import ShareProjectsUser from '../../Components/Dialogs/ShareProjects/ShareProjectsUser'

export default function useShareProjectsDialog() {
    const { showModal } = useModal()

    const openShareProjectsProject = (project, sharedWith, refresh) => {
        const modal = showModal(ShareProjectsProject, {
            project,
            sharedWith,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openShareProjectsUser = (user, currentShared, refresh) => {
        const modal = showModal(ShareProjectsUser, {
            user,
            currentShared,
            refresh,
            close: () => modal.hide(),
        })
    } 

    return {
        openShareProjectsProject,
        openShareProjectsUser
    }
}