import { useModal } from 'mui-modal-provider'
import PDFViewer from '../../Components/Dialogs/PDFViewer'
import QRCode from '../../Components/Dialogs/QRCode'

export default function useMiscDialog() {
    const { showModal } = useModal()

    const openPDFViewer = (pdf, title) => {
        const modal = showModal(PDFViewer, {
            pdf,
            title,
            close: () => modal.hide(),
        })
    }

    const openQRCode = url => {
        const modal = showModal(QRCode, {
            url,
            close: () => modal.hide(),
        }) 
    }


    return {
        openPDFViewer,
        openQRCode
    }
}