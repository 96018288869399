import React from 'react'
import { Skeleton } from '@mui/material'

function Skeletons() {
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', height:'100%' }}>
            <Skeleton variant="circular" width={150} height={150} sx={{ marginBottom: '15px' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='70%' />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width='70%' />
            <Skeleton variant="rounded" width='70%' height='75px' sx={{ marginTop: '15px' }}/>
            <Skeleton variant="rounded" width='70%' height='100px' sx={{ marginTop:'auto' }}/>
        </div>
    )
}

export default Skeletons