import React, { useState, useEffect } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField, PasswordTextField } from '../../Inputs/TextField/TextField'
import { editEntity, getEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import useDialog from '../../../Hooks/useDialog'
import { GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import { Divider } from '@mui/material'

import '../GenericCRUDDialog.css'

function EditBackofficeUser(props) {
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])

    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, formState: { errors } } = useForm();
    const { openInfoDialog } = useDialog()

    useEffect(() => {
        if (props.open) {
            getEntity('user_roles').then(res => {
                setRoles(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props.open])

    const onSubmit = data => {
        if (data.email !== props.user.email) {
            openInfoDialog({ title: `Editar ${props.user.name}`, message: `Ao Editar o email de ${props.user.name}, irá ser enviado um email com as credenciais para o email introduzido.\n\nPretende continuar?` }, 'y/n', () => submitToServer(data))
        } else {
            submitToServer(data)
        }
    }

    const submitToServer = (data) => {
        const changedData = {}
        setLoading(true)

        for (const key in data) {
            if (key === 'role') {
                if (data.role.id.toString() !== props.user.user_role.id.toString()) {
                    changedData.userRoleId = data.role.id
                }
            } else {
                if (data[key] !== props.user[key]) {
                    changedData[key] = data[key]
                }
            }
        }

        if (Object.keys(changedData).length === 1) {
            showSnackbar({ message: 'Não foram detetadas alterações', variant: 'default' })
            setLoading(false)
            return;
        }

        editEntity('backoffice_users', props.user.id, changedData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar editar o utilizador', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Editar ${props.user.name}`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>{`Utilize o formulário a baixo para editar a informação de ${props.user.name}`}</p>
                <form className='add-dialog-user-fields' style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue={props.user.name}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='email'
                        defaultValue={props.user.email}
                        rules={{
                            required: 'Campo Obrigatório',
                            pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Email*'
                                name='email'
                                onChange={onChange}
                                value={value}
                                error={errors.email}
                                helperText={errors.email && errors.email?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='role'
                        defaultValue={props.user.user_role}
                        rules={{
                            required: 'Escolha um cargo para este utilizador',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <GenericAutoComplete
                                id='role'
                                label='Permissões'
                                name='role'
                                optionLabel='name'
                                data={roles}
                                value={value}
                                fullWidth
                                onChange={(val) => { onChange(val) }}
                                error={errors.role ? true : false}
                                helperText={errors.role && errors.role?.message}
                            />
                        )}
                    />
                    <Divider sx={{ width: '100%', backgroundColor: 'var(--text)', marginBottom: '20px' }} />
                    <Controller
                        control={control}
                        name='authPassword'
                        defaultValue=''
                        rules={{
                            required: 'Introduza a sua password',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <PasswordTextField
                                style={{ textAlign: 'center' }}
                                label='A sua palavra passe'
                                value={value}
                                onChange={onChange}
                                helperText={errors.authPassword ? errors.authPassword?.message : 'Confirma a sua identidade introduzindo a sua password'}
                                error={errors.authPassword}
                            />
                        )}
                    />

                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>{`Editar utilizador`}</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default EditBackofficeUser