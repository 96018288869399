import React, { useState } from 'react'
import Button from '../../Components/Buttons/Button'
import { useUserInfo } from '../../Hooks/useUser';
import { ArrowDownward, ArrowUpward, Add } from '@mui/icons-material';
import { Divider } from '@mui/material';
import ToggleButtons from '../../Components/Buttons/ToggleButtons';
import SearchInput from '../../Components/Inputs/SearchInput/SearchInput';

import './PageTop.css'


const getPermissionEntity = (entity) => {
    switch (entity) {
        case 'Utilizadores Backoffice': return 'BackofficeUsers';
        case 'Utilizadores': return 'AppUsers';
        case 'Empresas': return 'Companies';
        case 'Projetos': return 'Projects';
        case 'Tabelas Business Development': return 'Tables'
        default: return entity
    }
}

function PageTop(props) {

    const [openFilter, setOpenFilter] = useState(false)
    const { userInfo } = useUserInfo()

    const viewToggleButtonData = props.viewOptions ? props.viewOptions :
        [
            { label: 'Tabela', value: 'table' },
            { label: 'Cartas', value: 'cards' },
        ]

    return (
        <div className='page-top-main-div'>
            <div className='page-top-top-div'>
                <p className='page-top-title'>{props.title}</p>
                <div className='page-top-entity-info'>
                    {props.info.map(elem => {
                        return (
                            <EntityInfo key={elem.label} title={elem.label} value={elem.value} />
                        )
                    })}
                </div>
            </div>
            <div className='page-top-bottom-div'>
                {(props.addEntity && userInfo.user_role[`canCreate${getPermissionEntity(props.title)}`]) &&
                    <Button onClick={props.addEntity}> {`Adicionar ${props.addBtn}`} </Button>
                }
                {props.customButton &&
                    <Button onClick={props.customButton.func}>{props.customButton.name}</Button>
                }
                {props.onChangeSearch &&
                    <SearchInput value={props.searchValue} onChange={props.onChangeSearch} />
                }
                {props.changeView ? (
                    <div className='page-top-bottom-view-changer'>
                        <p>Ver</p>
                        <ToggleButtons value={props.view} onChange={props.changeView} data={viewToggleButtonData} />
                    </div>
                ) : <div style={{ width: '200px' }}></div>
                }
                {props.customChild && props.customChild}
            </div>
            {props.filterComponent &&
                <div className={`${openFilter ? 'top-page-filter-panel' : 'top-page-filter-panel hidden'}`}>
                    <div className='top-page-filter-panel-handle' onClick={() => setOpenFilter(!openFilter)} >
                        {openFilter ? <ArrowUpward htmlColor='white' /> : <ArrowDownward htmlColor='white' />}
                    </div>
                    <div className={`${openFilter ? 'top-page-filter-content' : 'top-page-filter-content hidden'}`}>
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'white', width: '43%', position: 'absolute', top: '10px', right: '20px' }} />
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'white', width: '43%', position: 'absolute', top: '10px', left: '20px' }} />
                        <p className='top-page-filter-title'>Filter</p>
                        {props.filterComponent}
                    </div>
                </div>
            }
        </div>
    )
}

export default PageTop

const EntityInfo = ({ title, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '125px', color: 'var(--text)' }}>
            <p style={{ fontSize: '18px' }}>{title}</p>
            <p style={{ fontSize: '32px' }}>{value}</p>
        </div>
    )
}