import React, { useState } from 'react'
import { Divider, DialogContent } from '@mui/material'
import DialogPrefab from '../DialogPrefab'
import UserRolesSettings from './UserRoles/UserRolesSettings'
import GeneralSettings from './General/GeneralSettings'

import './SettingsDialog.css'


function SettingsDialog(props) {
    const [view, setView] = useState('general')

    const getView = () => {
        switch (view) {
            case 'user_roles': return <UserRolesSettings userRoles={props.userRolesData} refresh={props.refresh} />
            case 'general': return <GeneralSettings />
            // case 'upload_data': return <UploadData />
            // case 'announcements': return <Announcements />
            default: return <p>Default</p>
        }
    }

    return (    
        <DialogPrefab
            maxWidth='md'
            open={props.open}
            close={props.close}
            title='Preferências'
            margin='0px'
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent className='settings-dialog-content'>
                <div className='settings-dialog-body'>
                    <div className='settings-body-menu'>
                        <MenuButton view={view} viewValue='general' label='Geral' cb={() => setView('general')} />
                        <MenuButton view={view} viewValue='user_roles' label='Cargos' cb={() => setView('user_roles')} />
                        {/* <MenuButton view={view} viewValue='upload_data' label='Carregar Dados Gerais' cb={() => setView('upload_data')} /> */}
                        {/* <MenuButton view={view} viewValue='announcements' label='Anúncios' cb={() => setView('announcements')} /> */}
                    </div>
                    <Divider orientation='vertical' sx={{ height: '100%' }} />
                    <div className='settings-body-right-content' >
                        <div className='body-right-roles'>
                            {getView()}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </DialogPrefab >
    )
}

export default SettingsDialog

const MenuButton = ({ label, cb, view, viewValue }) => {
    return (
        <>
            <div className={`settings-menu-button ${view === viewValue ? 'active' : ''}`} onClick={cb}>
                <p>{label}</p>
            </div>
            <Divider orientation='horizontal' sx={{ width: '100%' }} />
        </>
    )
}