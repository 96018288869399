import React from "react";
import { Edit, Delete } from "@mui/icons-material";
import CustomTooltip from "../../Misc/CustomTooltip";
import moment from "moment";
import { useUserInfo } from "../../../Hooks/useUser";
import useBackofficeUserDialog from "../../../Hooks/Dialogs/useBackofficeUserDialog";

import imagePlaceholder from "../../../Assets/placeholder/portrait.jpg";
import "./BackofficeUserCard.css";

function BackofficeUserCard({ user, refresh }) {
	const { userInfo } = useUserInfo();
	const { openDeleteDialog, openEditDialog } = useBackofficeUserDialog();

	return (
		<div className="backoffice-user-card-main-div">
			<div className="backoffice-user-card-image">
				<img
					alt={user.name}
					src={
						user.portrait
							? `${process.env.REACT_APP_SERVER_URL}/images/backofficeUsers/${user.portrait}`
							: imagePlaceholder
					}
				/>
			</div>
			<div className="backoffice-user-card">
				<div className="backoffice-user-card-top-info">
					<p>{user.name}</p>
					<p>{user.user_role.name}</p>
				</div>
				<div className="backoffice-user-card-bottom-info">
					<div>
						<p style={{ color: "var(--secondary)" }}>Email:</p>
						<p style={{ wordBreak: "break-word" }}>{user.email}</p>
					</div>
					<div>
						<p style={{ color: "var(--secondary)" }}>Último Login:</p>
						<p>{user.last_login ? moment(user.last_login).format("DD MMM YYYY HH:mm") : "Nunca"}</p>
					</div>
				</div>
				{userInfo.id !== user.id && (
					<div className="backoffice-user-card-actions">
						{userInfo.user_role.canEditBackofficeUsers && (
							<CustomTooltip title={`Editar ${user.name}`}>
								<Edit onClick={() => openEditDialog(user, refresh)} />
							</CustomTooltip>
						)}
						{userInfo.user_role.canRemoveBackofficeUsers && (
							<CustomTooltip title={`Remover ${user.name}`}>
								<Delete onClick={() => openDeleteDialog(user, refresh)} />
							</CustomTooltip>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default BackofficeUserCard;
