import React from 'react'
import ProjectsCreationTimeline from '../Charts/Statistics/ProjectsCreationTimeline'
import StatisticsTable from './Components/StatisticsTable'
import useProjectsDialog from '../../Hooks/Dialogs/useProjectsDialog'
import DoughnutChart from '../Charts/Statistics/DoughnutChart'

import './StatisticsSections.css'

function ProjectsStatistics(props) {
    const { openViewMore } = useProjectsDialog()

    return (
        <div className='section-main-div'>
            <div className='section-content large'>
                <ProjectsCreationTimeline />
            </div>
            <div className='section-content small'>
                <StatisticsTable
                    data={props.data}
                    headers='Nome,Visitas,Partilhas'
                    values='name,views,shares'
                    title='Projetos'
                    openViewMore={openViewMore}
                />
            </div>
            <div className='section-content small'>
                <p style={{ position: 'absolute' }}>Visitas</p>
                <DoughnutChart data={props.data} field='views' />
            </div>
            <div className='section-content small'>
                <p style={{ position: 'absolute' }}>Partilhas</p>
                <DoughnutChart data={props.data} field='shares' />
            </div>
        </div>
    )
}

export default ProjectsStatistics