import React, { useState, useEffect } from 'react'
import DialogPrefab from '../DialogPrefab'
import { openInNewTab } from '../../../utils'
import { Divider } from '@mui/material'
import moment from 'moment'
import ProjectsList from '../../Misc/ProjectsList/ProjectsList'
import UsersList from '../../Misc/UsersList/UsersList'
import { getEntity } from '../../../API/Requests'

import logoPlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './ViewMoreCompany.css'

function ViewMoreCompany(props) {
    const [projects, setProjects] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        getEntity('companies', props.company.id).then(res => {
            setProjects(res.data.projects)
            setUsers(res.data.app_users)
        }, err => {
            console.log(err)
        })
    }, [props.project])

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={props.company.name}
            maxWidth='lg'
        >
            <div className='company-view-more-div'>
                <div className='company-view-more-left'>
                    <p>{projects.length} Projetos desta empresa</p>
                    <ProjectsList projects={projects} />
                </div>
                <Divider sx={{ height: '99%' }} orientation='vertical' />
                <div className='company-view-more-center'>
                    <img className='company-view-more-company-img' alt={props.company.name} src={props.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${props.company.image}` : logoPlaceholder} />
                    <p className='company-view-more-title'>{props.company.name}</p>
                    {props.company.website && <p className='company-view-more-url' onClick={() => openInNewTab(props.company.website)}>{props.company.website}</p>}
                    <Divider sx={{ width: '95%', marginTop: 'auto' }} />
                    <div className='view-more-user-meta'>
                        <p style={{ fontWeight: '600' }}>ID: <span style={{ fontWeight: '300' }}>{props.company.id}</span></p>
                        <p style={{ fontWeight: '600' }}>Image ID: <span style={{ fontWeight: '300' }}>{props.company.image_id}</span></p>
                        <p style={{ fontWeight: '600' }}>Created At: <span style={{ fontWeight: '300' }}>{moment(props.company.createdAt).format('DD MMM YYYY HH:mm')}</span></p>
                        <p style={{ fontWeight: '600' }}>Updated At: <span style={{ fontWeight: '300' }}>{moment(props.company.updatedAt).format('DD MMM YYYY HH:mm')}</span></p>
                    </div>
                </div>
                <Divider sx={{ height: '99%' }} orientation='vertical' />
                <div className='company-view-more-right'>
                    <p>{users.length} Utilizadores nesta empresa</p>
                    <UsersList users={users} />
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ViewMoreCompany