import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserInfo } from '../../Hooks/useUser'

import {
    Dashboard as DashboardIcon,
    Business as CompaniesIcon,
    Person4 as BackofficeUsersIcon,
    People as AppUsersIcon,
    ViewInAr as ProjectIcon,
    Help as HelpIcon,
    StackedBarChart as StatisticsIcon,
    Toc as TableIcon
} from '@mui/icons-material'

import './ListItems.css'


const SideBarButtons = ({ path, icon, label, activeTab, setActive }) => {
    const navigate = useNavigate()
    const { userInfo } = useUserInfo()

    const checkPermission = () => {
        switch (path) {
            case '/app-users': return userInfo.user_role.canViewAppUsers;
            case '/projects': return userInfo.user_role.canViewProjects;
            case '/companies': return userInfo.user_role.canViewCompanies;
            case '/backoffice-users': return userInfo.user_role.canViewBackofficeUsers;
            case '/statistics': return userInfo.user_role.canViewStatistics;
            case '/tables': return userInfo.user_role.canViewTables;
            default: return true
        }
    }

    const handleClick = () => {
        setActive(path)
        navigate(path)
    }

    return (
        checkPermission() &&
        <div className={`side-bar-button${activeTab === path ? ' active' : ''}`} onClick={handleClick} >
            {icon}
            <p>{label}</p>
        </div>

    )
}

export const MainListItems = (props) => {

    return (
        <div className='side-bar-items-div'>
            <SideBarButtons path='/' icon={<DashboardIcon />} label='Dashboard' activeTab={props.activeTab} setActive={props.setActiveTab} />
            <SideBarButtons path='/app-users' icon={<AppUsersIcon />} label='App Users' activeTab={props.activeTab} setActive={props.setActiveTab} />
            <SideBarButtons path='/projects' icon={<ProjectIcon />} label='Projetos' activeTab={props.activeTab} setActive={props.setActiveTab} />
            <SideBarButtons path='/companies' icon={<CompaniesIcon />} label='Empresas' activeTab={props.activeTab} setActive={props.setActiveTab} />
            <SideBarButtons path='/tables' icon={<TableIcon />} label='Tabelas' activeTab={props.activeTab} setActive={props.setActiveTab} />
        </div>
    )
}

export const SecondaryListItems = (props) => {

    return (
        <div className='side-bar-items-div'>
            <SideBarButtons path='/backoffice-users' icon={<BackofficeUsersIcon />} label='Backoffice Users' activeTab={props.activeTab} setActive={props.setActiveTab} />
            <SideBarButtons path='/statistics' icon={<StatisticsIcon />} label='Estatísticas' activeTab={props.activeTab} setActive={props.setActiveTab} />
        </div>
    )
}

export const BottomListItems = (props) => {

    return (
        <div className='side-bar-items-div'>
            {/* <SideBarButtons path='/help' icon={<HelpIcon />} label='Ajuda' activeTab={props.activeTab} setActive={props.setActiveTab} /> */}
        </div>
    )
}