import React, { useState } from "react";
import Button from "../../Buttons/Button";
import DialogPrefab from "../DialogPrefab";
import { PasswordTextField } from "../../Inputs/TextField/TextField";
import { removeEntity } from "../../../API/Requests";
import useGlobalSnackbar from "../../../Hooks/useGlobalSnackbar";

import "../GenericCRUDDialog.css";

function DeleteProject(props) {
	const [authPassword, setAuthPassword] = useState("");
	const { showSnackbar } = useGlobalSnackbar();
	const [loading, setLoading] = useState(false);

	const submit = () => {
		if (authPassword.length < 6) return;
		setLoading(true);
		removeEntity("projects", props.project.id, authPassword).then(
			res => {
				props.close();
				props.refresh();
				showSnackbar({ message: res.data });
				setLoading(false);
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro ao tentar eliminar o projeto",
					description: err.response,
					variant: "error",
				});
				setLoading(false);
			},
		);
	};

	return (
		<DialogPrefab
			open={props.open}
			close={props.close}
			maxWidth="sm"
			title={`Eliminar projeto ${props.project.name}`}
		>
			<div className="delete-dialog-div">
				<p>
					{
						"Tem a certeza que pretende eliminar este projeto?\n\nSe continuar, todos os ficheiros deste projeto serão eliminados."
					}
				</p>
				<div className="dialog-confirm-pass-and-button">
					<PasswordTextField
						autoFocus={true}
						style={{ textAlign: "center" }}
						helperText="Confirma a sua identidade introduzindo a sua password"
						value={authPassword}
						onChange={e => setAuthPassword(e.target.value)}
						onEnter={() => submit()}
					/>
					<Button loading={loading} disabled={authPassword.length < 6 || loading} onClick={submit}>
						Eliminar Projeto
					</Button>
				</div>
			</div>
		</DialogPrefab>
	);
}

export default DeleteProject;
