import React, { useState } from 'react'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import DialogPrefab from '../DialogPrefab'
import { removeEntity } from '../../../API/Requests'
import Button from '../../Buttons/Button'

import '../GenericCRUDDialog.css'

function DeleteTable(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [loading, setLoading] = useState(false)

    const submit = () => {
        setLoading(true)
        removeEntity('tables', props.table.id).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar eliminar a tabela', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`Eliminar tabela`}
        >
            <div className='delete-dialog-div'>
                <p>{`Tem a certeza que pretende eliminar esta tabela?\n\nTodo o seu conteúdo irá ser eliminado. Os projetos associados ficarão intactos.`}</p>
                <div className='dialog-confirm-pass-and-button'>
                    <Button loading={loading} disabled={loading} onClick={submit}>Eliminar tabela</Button>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteTable