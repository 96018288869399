import { useModal } from 'mui-modal-provider'
import AddTable from '../../Components/Dialogs/Tables/AddTable'
import EditTable from '../../Components/Dialogs/Tables/EditTable'
import DeleteTable from '../../Components/Dialogs/Tables/DeleteTable'
import ViewMoreTable from '../../Components/Dialogs/Tables/ViewMoreTable'
import ViewAllTables from '../../Components/Dialogs/Tables/ViewAllTables'
import AddTableProject from '../../Components/Dialogs/Tables/TableProjects/AddTableProject'
import EditTableProject from '../../Components/Dialogs/Tables/TableProjects/EditTableProject'
import DeleteTableProject from '../../Components/Dialogs/Tables/TableProjects/DeleteTableProject'
import DeleteTableRow from '../../Components/Dialogs/Tables/TableRows/DeleteTableRow'

export default function useTablesDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (table, refresh) => {
        const modal = showModal(DeleteTable, {
            table,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = (refresh) => {
        const modal = showModal(AddTable, {
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (table, refresh) => {
        const modal = showModal(EditTable, {
            table,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openViewMore = (table, parentRefresh) => {
        const modal = showModal(ViewMoreTable, {
            table,
            parentRefresh,
            close: () => modal.hide(),
        })
    }

    const openViewAll = (company, refresh) => {
        const modal = showModal(ViewAllTables, {
            company,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openAddTableProject = (row, refresh) => {
        const modal = showModal(AddTableProject, {
            row,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditTableProject = (row, refresh) => {
        const modal = showModal(EditTableProject, {
            row,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openDeleteTableProject = (data, refresh) => {
        const modal = showModal(DeleteTableProject, {
            data,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openDeleteTableRow = (row, refresh) => {
        const modal = showModal(DeleteTableRow, {
            row,
            refresh,
            close: () => modal.hide(),
        })
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog,
        openViewMore,
        openViewAll,
        openAddTableProject,
        openEditTableProject,
        openDeleteTableProject,
        openDeleteTableRow
    }
}