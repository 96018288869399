import React from 'react'
import DialogPrefab from '../DialogPrefab'
import { Avatar } from '@mui/material'
import TableCard from '../../Cards/Tables/TableCard'
import useTablesDialog from '../../../Hooks/Dialogs/useTablesDialog'

import './ViewAllTables.css'

function ViewAllTables(props) {
    const { openViewMore } = useTablesDialog()

    const onView = table => {
        openViewMore(table, () => props.refresh())
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='md'
            title='Tabelas'
        >
            <div className='view-all-tables-main-div'>
                <div className='view-more-table-company-logo'>
                    <Avatar variant='rounded' alt={props.company.name} src={props.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${props.company.image}` : null} sx={{ width: 'auto', height: '50px' }}>
                        {props.company.name.split('')[0]}
                    </Avatar>
                    <p>{props.company.name}</p>
                </div>
                <div className='view-all-tables-content'>
                    {props.company.tables.map((table, index) => (
                        <TableCard
                            index={index} //for animation delay
                            key={table.id}
                            table={table}
                            onView={() => onView(table)}
                        />
                    ))}
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ViewAllTables