import React, { useState, useEffect } from 'react'
import DialogPrefab from '../DialogPrefab'
import { Divider, Skeleton, Avatar, AvatarGroup } from '@mui/material'
import { PictureAsPdf, QrCode2, Refresh } from '@mui/icons-material'
import UsersList from '../../Misc/UsersList/UsersList'
import { getEntity, getFilesStats } from '../../../API/Requests'
import CustomTooltip from '../../Misc/CustomTooltip'
import useCompaniesDialog from '../../../Hooks/Dialogs/useCompaniesDialog'
import useMiscDialog from '../../../Hooks/Dialogs/useMiscDialog'
import moment from 'moment'
import { convertBytes, openInNewTab, skinString } from '../../../utils'
import Button from '../../Buttons/Button'
import { Menu, MenuItem, ListItemIcon } from '@mui/material'
import useShareProjectsDialog from '../../../Hooks/Dialogs/useShareProjectsDialog'

import noImage from '../../../Assets/placeholder/no_image.jpg'
import logoPlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './ViewMoreProjects.css'

function ViewMoreProject(props) {
    const [loading, setLoading] = useState(false)
    const [project, setProject] = useState()
    const [projectFilesInfo, setProjectFilesInfo] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [projectImage, setProjectImage] = useState(`${process.env.REACT_APP_SERVER_URL}/project/${props.project.folder}/socialThumbnail.jpg`)
    const { openViewMore } = useCompaniesDialog()
    const { openQRCode } = useMiscDialog()
    const { openShareProjectsProject } = useShareProjectsDialog()

    useEffect(() => {
        if (props.open) {
            getEntity('projects', props.project.id).then(res => {
                setProject(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props.open, props.project, refresh])

    const getProjectFilesStats = async () => {
        setLoading(true)
        getFilesStats(props.project.id).then(res => {
            setLoading(false)
            setProjectFilesInfo(res.data)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }

    const openProject = () => {
        openInNewTab(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${props.project.folder}`)
    }

    const openPdf = (file) => {
        const pdf = `${process.env.REACT_APP_SERVER_URL}/project/${props.project.folder}/files/${file}`
        openInNewTab(pdf)
    }

    const onShare = () => {
        openShareProjectsProject(project.id, project.sharedWith, () => setRefresh(!refresh))
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={props.project.name}
            maxWidth='md'
        >
            {project &&
                <div className='project-view-more-main-div'>
                    <div className='projects-view-more-users-list-wrapper'>
                        <div className='projects-view-more-users-list'>
                            <p className='projects-view-more-users-title'>{project.company.app_users.length} Utilizadores com acesso a este projeto</p>
                            <UsersList users={project.company.app_users} />
                        </div>
                        <Divider sx={{ width: '95%', marginTop: 'auto' }} />
                        <div className='projects-view-more-shared'>
                            <p style={{ fontSize: '12px' }}>{`Partilhado para ${project.sharedWith.length} utilizadores:`}</p>
                            <SharedWith users={project.sharedWith} onManage={onShare} />
                        </div>
                    </div>
                    <Divider sx={{ height: '99%' }} orientation='vertical' />
                    <div className='projects-view-more-info'>
                        <div className='project-view-more-image-div'>
                            <div
                                className='project-view-more-online-div'
                                style={{ backgroundColor: project.online ? 'green' : 'var(--dark-red)' }}
                            >
                                {project.online ? 'Online' : 'Offline'}
                            </div>
                            <img
                                alt={project.name}
                                src={projectImage}
                                className='projects-view-more-image'
                                onClick={openProject}
                                onError={() => setProjectImage(noImage)}
                            />
                        </div>
                        <div className='projects-view-more-actions'>
                            {project.pdfFiles &&
                                <PDFMenu pdfFiles={project.pdfFiles} openPdf={openPdf} />
                            }
                            <CustomTooltip title='QR Code'>
                                <QrCode2 onClick={() => openQRCode(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${props.project.folder}`)} />
                            </CustomTooltip>
                        </div>
                        <p className='project-view-more-title'>{project.name}</p>
                        <div className='project-view-more-company-div'>
                            <img
                                className='view-more-user-company-img'
                                alt={project.company.name}
                                src={project.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${project.company.image}` : logoPlaceholder}
                                onClick={() => props.companyLink && openViewMore(project.company)}
                            />
                            <p>{project.company.name}</p>
                        </div>
                        <Divider sx={{ width: '95%', marginTop: 'auto' }} />
                        <div className='view-more-info-project-files'>
                            {projectFilesInfo ?
                                <div className='project-info-refresh-files-info'>
                                    <p>Total de espaço em disco: {convertBytes(projectFilesInfo.size)}</p>
                                    <p>Total de ficheiros: {projectFilesInfo.files}</p>
                                    <p>Total de pastas: {projectFilesInfo.folders}</p>
                                    {loading ?
                                        <Skeleton variant='text' />
                                        :
                                        <CustomTooltip title='Atualizar'>
                                            <Refresh sx={{ cursor: 'pointer' }} onClick={() => getProjectFilesStats()} />
                                        </CustomTooltip>
                                    }
                                </div>
                                :
                                <Button loading={loading} size='small' onClick={() => getProjectFilesStats()}>Ver info ficheiros</Button>
                            }
                        </div>
                        <Divider sx={{ width: '95%' }} />
                        <div className='view-more-user-meta'>
                            <p style={{ fontWeight: '600' }}>ID: <span style={{ fontWeight: '300' }}>{project.id}</span></p>
                            <p style={{ fontWeight: '600' }}>Folder: <span style={{ fontWeight: '300' }}>{project.folder}</span></p>
                            <p style={{ fontWeight: '600' }}>Google Analytics Code: <span style={{ fontWeight: '300' }}>{project.analyticsCode ?? 'N/D'}</span></p>
                            <p style={{ fontWeight: '600' }}>Created At: <span style={{ fontWeight: '300' }}>{moment(project.createdAt).format('DD MMM YYYY HH:mm')}</span></p>
                            <p style={{ fontWeight: '600' }}>Updated At: <span style={{ fontWeight: '300' }}>{moment(project.updatedAt).format('DD MMM YYYY HH:mm')}</span></p>
                        </div>
                    </div>
                </div>
            }
        </DialogPrefab>
    )
}

export default ViewMoreProject

const SharedWith = ({ users, onManage }) => {

    return (
        <div className='shared-with-users-avatar'>
            <AvatarGroup>
                {users.map(user => (
                    <CustomTooltip key={user.id} title={user.name}>
                        <Avatar alt={user.name} src={user.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${user.portrait}` : null} />
                    </CustomTooltip>
                ))}
            </AvatarGroup>
            <Button size='small' onClick={onManage}>Gerir</Button>
        </div>
    )
}

const PDFMenu = ({ pdfFiles, openPdf }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const pdfArray = pdfFiles.split('/')

    return (
        pdfArray.length === 1 ?
            <CustomTooltip title={pdfArray[0]}>
                <PictureAsPdf onClick={() => openPdf(pdfArray[0])} />
            </CustomTooltip>
            :
            <>
                <CustomTooltip title="Ver PDF's">
                    <PictureAsPdf onClick={(e) => setAnchorEl(e.currentTarget)} />
                </CustomTooltip>
                <Menu
                    id='pdf-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {pdfArray.map(pdf => (
                        <MenuItem key={pdf} onClick={() => { setAnchorEl(null); openPdf(pdf) }}>
                            <ListItemIcon >
                                <PictureAsPdf />
                            </ListItemIcon>
                            {pdf}
                        </MenuItem>
                    ))}
                </Menu>
            </>

    )
}