/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { FileDownload } from "@mui/icons-material";
import CustomTooltip from "../Misc/CustomTooltip";
import { ExportToCsv } from "export-to-csv";
import Actions from "./TableComponents/Actions";
import { getUserSettings, setUserSettings } from "../../UserSettings/UserSettings";

import { MRT_Localization_PT } from "material-react-table/locales/pt";
import {
	MRT_ToggleDensePaddingButton,
	MRT_FullScreenToggleButton,
	MRT_ToggleFiltersButton,
	MRT_ToggleGlobalFilterButton,
	MRT_ShowHideColumnsButton,
} from "material-react-table";

function TablePrefab(props) {
	const [state, setState] = useState(getUserSettings(props.settingsId));

	const csvOptions = {
		fieldSeparator: ",",
		quoteStrings: '"',
		decimalSeparator: ".",
		showLabels: true,
		useBom: true,
		title: props.title,
		filename: `${props.title}_Faunavr_backoffice`,
		useKeysAsHeaders: false,
		headers: props.columns.map(c => c.header),
	};

	useEffect(() => {
		setUserSettings(props.settingsId, state);
	}, [state]);

	const csvExporter = new ExportToCsv(csvOptions);

	const headerInternalButtons = ({ table }) => {
		return (
			<div style={{ display: "flex", alignItems: "center", height: "40px", padding: "0 10px" }}>
				<MRT_ToggleGlobalFilterButton table={table} />
				{props.export && (
					<CustomTooltip title="Exportar">
						<FileDownload
							sx={{ cursor: "pointer", color: "var(--table-buttons)" }}
							onClick={() => handleExportData()}
						/>
					</CustomTooltip>
				)}
				<MRT_ToggleFiltersButton table={table} />
				<MRT_ToggleDensePaddingButton table={table} />
				<MRT_ShowHideColumnsButton table={table} />
				<MRT_FullScreenToggleButton table={table} />
			</div>
		);
	};

	const handleExportData = () => {
		csvExporter.generateCsv(props.exportData);
	};

	const onStateChange = (e, entity) => {
		setState(prevState => ({ ...prevState, [entity]: e instanceof Function ? e(prevState[entity]) : e }));
	};

	return (
		<MaterialReactTable
			onPaginationChange={e => onStateChange(e, "pagination")}
			onColumnVisibilityChange={e => onStateChange(e, "columnVisibility")}
			onDensityChange={e => onStateChange(e, "density")}
			onExpandedChange={e => onStateChange(e, "expanded")}
			onSortingChange={e => onStateChange(e, "sorting")}
			onShowFiltersChange={e => onStateChange(e, "showColumnFilters")}
			state={{ ...state, ...props.adicionalState }}
			columns={props.columns}
			data={props.data}
			localization={MRT_Localization_PT}
			positionActionsColumn="last"
			renderToolbarInternalActions={headerInternalButtons}
			enableRowActions
			displayColumnDefOptions={{
				"mrt-row-actions": {
					size: 125, //make actions column wider
				},
			}}
			renderRowActions={(row, index) => (
				<Actions
					onEdit={props.onRowEdit ? () => props.onRowEdit(row) : null}
					onView={props.onRowView ? () => props.onRowView(row) : null}
					onDelete={props.onRowDelete ? () => props.onRowDelete(row) : null}
					onResend={props.onRowResend ? () => props.onRowResend(row) : null}
					onVisit={props.onRowVisit ? () => props.onRowVisit(row) : null}
					onShareUser={props.onRowShareUser ? () => props.onRowShareUser(row) : null}
					onShareProject={props.onRowShareProject ? () => props.onRowShareProject(row) : null}
					onViewAnalytics={props.onRowAnalytics ? () => props.onRowAnalytics(row) : null}
					onSendProjectEmail={props.onSendProjectEmail ? () => props.onSendProjectEmail(row) : null}
					roleId={props.roleId}
				/>
			)}
			{...props}
			muiTablePaperProps={{
				sx: {
					boxShadow: "2px 2px 6px 0 #171717",
					backgroundColor: "var(--dark-background)",
					borderRadius: "10px",
				},
			}}
			muiBottomToolbarProps={{
				//table bottom
				sx: {
					backgroundColor: "var(--background)",
					borderBottomLeftRadius: "10px",
					borderBottomRightRadius: "10px",
					boxShadow: "0px -4px 7px -5px #171717",
				},
			}}
			muiTopToolbarProps={{
				//Table top
				sx: {
					backgroundColor: "var(--background)",
					borderTopLeftRadius: "10px",
					borderTopRightRadius: "10px",
				},
			}}
			muiTableBodyRowProps={{
				//table body cells
				sx: {
					backgroundColor: "transparent",
				},
			}}
			muiTableHeadRowProps={{
				//table header
				sx: {
					backgroundColor: "var(--background)",
					boxShadow: "0px 0px 6px 0 #171717",
					"& > th > div": {
						justifyContent: "space-between",
					},
				},
			}}
			muiToolbarAlertBannerProps={{
				//top table info
				sx: {
					backgroundColor: "var(--background)",
				},
			}}
		/>
	);
}

export default TablePrefab;
