import React from 'react'
import { BarLoader } from 'react-spinners'

import './Button.css'

function Button({ loading, size, ...props }) {

    return (
        <button
            className={`loading-button-main-div ${loading ? 'loading' : ''} ${size ? size : 'medium'}`}
            disabled={loading || props.disabled}
            {...props}
        >
            {loading ? (
                <div className='button-loading-div'>
                    <p>Loading</p>
                    <BarLoader
                        color='var(--text)'
                    />
                </div>
            ) : (
                props.children
            )}
        </button>
    )
}

export default Button