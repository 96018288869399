import React from 'react'
import DialogPrefab from './DialogPrefab'
import Button from '../Buttons/Button'

import './ErrorDialog.css'

function ErrorDialog(props) {

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title='Erro'
            maxWidth='sm'
            error={true}
            fullScreen={window.innerWidth < 960}
            preventOutSideClose={true}
        >
            <div className='error-dialog-main-div'>
                <div className='error-dialog-info-div'>
                    <div className='error-dialog-top-info'>
                        <p>{props.info.status}</p>
                        <p>{props.info.statusText}</p>
                    </div>
                    <div className='error-dialog-description-div'>
                        {props.info.data ?
                            <p>{props.info.data}</p> :
                            <p>{JSON.stringify(props.info.message)}</p>
                        }
                    </div>
                </div>
                <div style={{ alignSelf: 'center' }}>
                    <Button onClick={props.ocb}>
                        Ok
                    </Button>
                </div>
            </div>
        </DialogPrefab >
    )
}

export default ErrorDialog