import React, { useState, useEffect } from 'react'
import UsersStatistics from '../Components/Statistics/UsersStatistics'
import ProjectsStatistics from '../Components/Statistics/ProjectsStatistics'
import CompaniesStatistics from '../Components/Statistics/CompaniesStatistics'
import { getEntity } from '../API/Requests'

import './css/Statistics.css'

function Statistics() {
    const [data, setData] = useState()

    useEffect(() => {
        getEntity('statistics').then(res => {
            setData(res.data)
        }, err => {
            console.error(err)
        })
    }, [])

    return (
        <div className='statistics-main-div'>
            {data &&
                <>
                    <div className='statistics-section'>
                        <p>Estatísticas Users</p>
                        <UsersStatistics data={data.usersMostVisits} />
                    </div>
                    <div className='statistics-section'>
                        <p>Estatísticas Projetos</p>
                        <ProjectsStatistics data={data.projectsMost} />
                    </div>
                    <div className='statistics-section'>
                        <p>Estatísticas Empresas</p>
                        <CompaniesStatistics data={data.companiesInfo} />
                    </div>
                    <div className="statistics-section">
                        <iframe
                            title="Users Locations and Devices Types"
                            style={{ border: 'none' }}
                            width="1600"
                            height="1600"
                            src="https://lookerstudio.google.com/embed/reporting/8e12738c-332d-4fc8-b0e7-4a59905c8384/page/8kziD"
                        />
                    </div>
                </>
                
            }
        </div>
    )
}

export default Statistics