import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import DialogPrefab from '../DialogPrefab'
import { useUserInfo } from '../../../Hooks/useUser'
import moment from 'moment'
import { Delete } from '@mui/icons-material'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Tabs, Tab, Divider } from '@mui/material'
import { getEntity, changePicture, removePicture } from '../../../API/Requests'
import EditProfile from './EditProfile'
import ChangePassword from './ChangePassword'

import PortraitPlaceholder from '../../../Assets/placeholder/portrait.jpg'

import './MyProfile.css'

function MyProfile(props) {
    const [user, setUser] = useState(null)
    const [tab, setTab] = useState(0)

    const { showSnackbar } = useGlobalSnackbar()
    const { userInfo, setUserInfo } = useUserInfo()

    useEffect(() => {
        setUser(userInfo)
    }, [])

    const refresh = () => {
        getEntity('backoffice_users', userInfo.id).then(res => {
            setUser(res.data)
            setUserInfo({ ...res.data, token: userInfo.token })
        }, err => {
            console.log(err)
        })
    }

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
            if (fileRejections[0].errors[0].code === 'file-too-large') {
                showSnackbar('Imagem demasiado grande, limite de 1mb', '', "error", { vertical: 'bottom', horizontal: 'center' }, false)
            }
            if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                showSnackbar('Tipo de ficheiro não aceite', '', "error", { vertical: 'bottom', horizontal: 'center' }, false)
            }
            return;
        }

        handleUploadImage(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        maxSize: 1000000,
        multiple: false,
        noDragEventsBubbling: true,
        accept: {
            'image/jpeg': [],
            'image/png': []
        }
    })

    const handleRemoveImage = () => {
        removePicture(userInfo.id).then(res => {
            setUserInfo({ ...userInfo, portrait: null })
            showSnackbar({ message: res.data })
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro no seu pedido', description: err.response ? err.response : 'Não foi possível comunicar com o servidor', variant: "error" })
        })
    }

    const handleUploadImage = (file) => {

        let formData = new FormData();
        formData.append('portrait', file)

        changePicture(userInfo.id, formData).then(res => {
            setUserInfo({ ...userInfo, portrait: `${res.data.portrait}?updatedAt=${new Date()}` }) //make sure image refreshes while having the same name
            showSnackbar({ message: res.data.message })
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro no seu pedido', description: err.response ? err.response : 'Não foi possível comunicar com o servidor', variant: "error" })
        })
    }


    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title='O meu perfil'
            maxWidth='md'
            margin='0 10px 20px 10px'
        >
            {user &&
                <div className='profile-main-div'>
                    <div className='profile-info-div'>
                        <div className={`profile-image-div${isDragActive ? ' dragging' : ''}`}>
                            <input {...getInputProps({})} />
                            <img
                                alt={user.name}
                                src={userInfo.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/backofficeUsers/${userInfo.portrait}` : PortraitPlaceholder}
                                className='profile-portrait-image'
                                {...getRootProps()}
                            />
                            <p className='image-upload-info' style={{color: 'white'}}>Clique ou arraste para alterar imagem</p>
                            {userInfo.portrait &&
                                <CustomTooltip title='Remover Imagem'>
                                    <Delete htmlColor='white' className='remove-picture-btn' onClick={handleRemoveImage} />
                                </CustomTooltip>
                            }
                        </div>
                        <div className='profile-user-role'>
                            {user.user_role.name}
                        </div>
                        <div className='profile-user-info'>
                            <p style={{ fontSize: '22px' }}>{user.name}</p>
                            <p>{user.email}</p>
                            <p style={{ marginTop: 'auto' }}>Ultimo Login: {moment(user.last_login).format('DD MMM YYYY HH:mm')}</p>
                            <p>Conta criada em: {moment(user.createdAt).format('DD MMM YYYY HH:mm')}</p>
                        </div>
                    </div>
                    <Divider orientation='vertical' />
                    <div className='profile-edit-div'>
                        <Tabs value={tab} onChange={(e, value) => setTab(value)} centered>
                            <Tab label='Editar Informação' />
                            <Tab label='Alterar Password' />
                        </Tabs>
                        <div style={{ flex: '1 1' }}>
                            <TabPanel value={tab} index={0}>
                                <EditProfile
                                    user={user}
                                    userRolesData={props.userRolesData}
                                    refresh={refresh}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <ChangePassword user={user} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            }
        </DialogPrefab>
    )
}

export default MyProfile

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ display: 'flex', justifyContent: 'center' }}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}