import React, { useState, useEffect } from 'react'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar'
import DialogPrefab from '../../DialogPrefab'
import { editEntity, getByCompany } from '../../../../API/Requests'
import Button from '../../../Buttons/Button'
import { TextField } from '../../../Inputs/TextField/TextField'
import { Controller, useForm } from 'react-hook-form'
import { GenericAutoComplete } from '../../../Inputs/AutoComplete/CustomAutocomplete'
import DatePicker from '../../../Inputs/DatePickers/DatePicker'

import '../../GenericCRUDDialog.css'
import moment from 'moment'

function EditTableProject(props) {
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])

    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (props.open) {
            getByCompany('projects', props.row.company.id).then(res => {
                setProjects(res.data)
            }, err => {
                console.error(err)
            })
        }
    }, [props.open])


    const submit = (data) => {
        const changedData = {};

        if (data.brand !== props.row.brand) changedData.brand = data.brand;
        if (data.orderBy !== props.row.orderBy) changedData.orderBy = data.orderBy;
        if (!data.orderDate.isSame(props.row.orderDate, 'day')) changedData.orderDate = data.orderDate.toDate();
        if (data.projectId) {
            if (data.projectId.id !== props.row.projectId) changedData.projectId = data.projectId.id;
        } else {
            if (props.row.projectId !== null) changedData.projectId = null
        }

        if (Object.keys(changedData).length === 0) {
            showSnackbar({ message: 'Não foram detetadas alterações', variant: 'info' })
            return;
        }

        setLoading(true)
        editEntity('table_projects', props.row.id, changedData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar editar o projeto da tabela', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Editar projeto na tabela`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para editar este projeto da tabela</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(submit)}>
                    <Controller
                        control={control}
                        name='projectId'
                        defaultValue={props.row.project}
                        render={({ field: { value, onChange } }) => (
                            <GenericAutoComplete
                                id='projectId'
                                label='Associar Projeto?'
                                name='projectId'
                                optionLabel='name'
                                data={projects}
                                value={value}
                                fullWidth
                                onChange={onChange}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='brand'
                        defaultValue={props.row.brand}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Brand*'
                                name='brand'
                                onChange={onChange}
                                value={value}
                                error={errors.brand}
                                helperText={errors.brand && errors.brand?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='orderBy'
                        defaultValue={props.row.orderBy}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Order By*'
                                name='orderBy'
                                onChange={onChange}
                                value={value}
                                error={errors.orderBy}
                                helperText={errors.orderBy && errors.orderBy?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='orderDate'
                        defaultValue={moment(props.row.orderDate)}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                value={value}
                                onChange={onChange}
                                name='orderDate'
                                label='Order Date*'
                                inputFormat='DD-MM-YYYY'
                                mask='__-__-____'
                                error={errors.orderDate ? true : false}
                                helperText={errors.orderDate && errors.orderDate?.message}
                            />
                        )}
                    />
                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Editar Projeto na tabela</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default EditTableProject