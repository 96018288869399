import React, { useEffect, useState, useContext } from 'react'
import { useUserInfo } from '../Hooks/useUser'
import useToken from '../Hooks/useToken'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import { useNavigate } from 'react-router-dom'
import { SocketContext } from '../Context/socket/socket'
import { loginUser } from '../API/Requests'
import Button from '../Components/Buttons/Button'
import { TextField, PasswordTextField } from '../Components/Inputs/TextField/TextField'
import ThemeToggle from '../Components/Misc/ThemeToggle'
import ForgotPassDialog from '../Components/Dialogs/ForgotPassword/ForgotPassDialog'

import Logo from '../Assets/Logos/fauna.svg'

import './css/LoginPage.css'

function Login() {
    const [loading, setLoading] = useState(false)
    const [openForgotPass, setOpenForgotPass] = useState(false)

    const { setUserInfo } = useUserInfo()
    const { setToken } = useToken()
    const { showSnackbar } = useGlobalSnackbar()
    const navigate = useNavigate()
    const socket = useContext(SocketContext)

    useEffect(() => {
        sessionStorage.removeItem('id')
        setToken(null)
        setUserInfo({})
        if (socket.connected) socket.disconnect()
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        const email = e.target[0].value;
        const pass = e.target[1].value;

        setLoading(true)

        loginUser(email, pass).then(res => {
            setToken({ token: res.headers.key })
            sessionStorage.setItem('id', res.headers.id)
            socket.auth = { uuid: res.headers.id, token: res.headers.key }
            socket.connect()
            setLoading(false)
            navigate('/', { replace: true })
        }, err => {
            console.log(err)
            showSnackbar({ message: err.response?.data ? err.response.data : 'Não foi possível comunicar com o servidor', variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <div className='login-page-main-div'>
            <div className='login-page-logo-div'>
                <img alt='logo' src={Logo} className='login-page-logo' />
                <div className='logo-line top'></div>
                <div className='logo-line bottom'></div>
            </div>
            <form className='login-page-form' onSubmit={handleLogin}>
                <div className='login-textfield'>
                    <TextField autoFocus={true} disabled={loading} name='email' required type='email' placeholder='example@email.com' label='e-mail' />
                </div>
                <div className='login-textfield-pass'>
                    <PasswordTextField disabled={loading} required />
                </div>
                <Button type={loading ? 'button' : 'submit'} size='large' loading={loading}>Entrar</Button>
            </form>
            <div className='login-bottom-div'>
                <p className='login-forgot-pass-link' tabIndex={0} onClick={() => setOpenForgotPass(true)}>Esqueci-me da minha palavra passe</p>
                <ThemeToggle />
            </div>
            <ForgotPassDialog open={openForgotPass} close={() => setOpenForgotPass(false)} />
        </div>
    )
}

export default Login