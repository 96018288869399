import React, { useEffect, useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { shareProject, removeShareProject, getEntity } from '../../../API/Requests'
import Button from '../../Buttons/Button'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import { Tabs, Tab } from '@mui/material'

import './ShareProjects.css'

function ShareProjectsProject(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [rawUsers, setRawUsers] = useState([])
    const [users, setUsers] = useState([])
    const [sharedWith, setSharedWith] = useState([])
    const [selectedAddUsers, setSelectedAddUsers] = useState([])
    const [selectedRemoveUsers, setSelectedRemoveUsers] = useState([])
    const [tab, setTab] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.open) {
            setLoading(true)

            if (props.sharedWith) {
                setSharedWith(props.sharedWith);
            } else {
                getEntity('projects', props.project).then(res => {
                    setSharedWith(res.data.sharedWith)
                }, err => {
                    console.error(err)
                })
            }

            getEntity('app_users').then(res => {
                setRawUsers(res.data)
                setLoading(false)
            }, err => {
                setLoading(false)
                console.error(err)
            })
        }
    }, [props.open])

    useEffect(() => {
        if (rawUsers.length > 0) {
            if (sharedWith.length === 0) {
                setUsers(rawUsers)
                return;
            }
            const filteredUsers = rawUsers.filter((user) => sharedWith.every((el) => el[Object.keys(el)[0]] !== user[Object.keys(el)[0]]));
            setUsers(filteredUsers)
        }
    }, [sharedWith, rawUsers])

    const onShareWithUsers = () => {
        const selectedUsers = selectedAddUsers.map(el => el.id)

        shareProject({ usersIds: selectedUsers, projectsIds: props.project }).then(res => {
            showSnackbar({ message: res.data })
            props.refresh && props.refresh()
            props.close()
        }, err => {
            console.error(err)
            showSnackbar({ message: 'Ocorreu um erro ao partilhar projetos', description: err.response, variant: 'error' })
        })
    }
    
    const onRemoveFromUsers = () => {
        const selectedUsers = selectedRemoveUsers.map(el => el.id)
        
        removeShareProject({ usersIds: selectedUsers, projectsIds: props.project }).then(res => {
            showSnackbar({ message: res.data })
            props.refresh && props.refresh()
            props.close()
        }, err => {
            console.error(err)
            showSnackbar({ message: 'Ocorreu um erro ao eliminar partilha de projetos', description: err.response, variant: 'error' })
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title='Partilhar Projetos'
        >
            <div className='share-projects-main-div'>
                <Tabs value={tab} onChange={(e, value) => setTab(value)} centered variant='fullWidth'>
                    <Tab label='Partilhar projeto' />
                    {sharedWith.length > 0 &&
                        <Tab label='Remover Partilha' />
                    }
                </Tabs>
                <div style={{ flex: '1 1', marginTop: '10px', width: '100%' }}>
                    <TabPanel value={tab} index={0}>
                        <div className='share-projects-tab-content'>
                            <p>Escolha os utilizadores com quem pretende partilhar este projeto</p>
                            <GenericAutoComplete
                                disableCloseOnSelect={true}
                                multiple={true}
                                id='users'
                                label='Escolha os Utilizadores'
                                name='users'
                                optionLabel='name'
                                tagText='User(s)'
                                data={users}
                                value={selectedAddUsers}
                                fullWidth
                                onChange={(val) => setSelectedAddUsers(val)}
                                helperText={loading ? 'Loading users...' : ''}
                            />
                            <Button disabled={selectedAddUsers.length === 0} onClick={onShareWithUsers}>Partilhar projeto</Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <div className='share-projects-tab-content'>
                            <p>Escolha os utilizadores que pretende retirar da partilha deste projeto</p>
                            <GenericAutoComplete
                                disableCloseOnSelect={true}
                                multiple={true}
                                id='users'
                                label='Escolha os Utilizadores'
                                name='users'
                                optionLabel='name'
                                tagText='User(s)'
                                data={sharedWith}
                                value={selectedRemoveUsers}
                                fullWidth
                                onChange={(val) => setSelectedRemoveUsers(val)}
                                helperText={loading ? 'Loading users...' : ''}
                            />
                            <Button disabled={selectedRemoveUsers.length === 0} onClick={onRemoveFromUsers}>Remover partilha</Button>
                        </div>
                    </TabPanel>
                </div>

            </div>
        </DialogPrefab>
    )
}

export default ShareProjectsProject

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='tab-panel'
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}