import React from 'react'

import './StatisticsTable.css'

function StatisticsTable({ data, headers, values, title, openViewMore }) {

    return (
        <div className='users-visits-div'>
            <p className='users-visits-title'>{title}</p>
            <div className='users-visits-table-wrapper'>
                <table className='users-visits-table'>
                    <thead className='users-visits-table-head'>
                        <tr>
                            {headers.split(',').map(th => (
                                <th key={th}>{th}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='users-visits-table-body'>
                        {data.map((el) => {
                            return (
                                <tr className='users-table-row' key={el.id} onClick={() => openViewMore && openViewMore(el, true)}>
                                    {values.split(',').map(td => (
                                        <td key={`${el.id}-${td}`}>{el[td]}</td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StatisticsTable