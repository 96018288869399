import React from "react";
import { Avatar } from "@mui/material";
import useAppUsersDialog from "../../../Hooks/Dialogs/useAppUsersDialog";

import "./AppUsersSmallCard.css";

function AppUsersSmallCard({ user }) {
	const { openViewMore } = useAppUsersDialog();

	return (
		<div className="app-user-small-card" onClick={() => openViewMore(user, false)}>
			<Avatar
				variant="rounded"
				alt={user.name}
				src={user.image ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${user.image}` : null}
				sx={{ width: "50px", height: "50px" }}
			/>
			<div>
				<p style={{ fontSize: "16px", fontWeight: "700" }}>{user.name}</p>
				<p style={{ fontSize: "12px", wordBreak: "break-all" }}>{user.email}</p>
			</div>
		</div>
	);
}

export default AppUsersSmallCard;
