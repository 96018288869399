import React from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

const StyledTextField = styled(TextField)({
    width: '100%',
    '& .MuiFormHelperText-root': {
        color: 'var(--textfield-error) !important',
        fontSize: '12px',
        marginLeft: '7px',
        fontFamily: '"JetBrains Mono", monospace'
    },
    '& .MuiInputBase-root': {
        width: '100%',
        height: '35px',
        borderRadius: '5px',
        letterSpacing: '1px',
        fontWeight: '300',
        border: 'none',
        outline: 'none',
        transition: 'box-shadow 0.2s ease-in-out',
        padding: '0 10px;',
        color: 'var(--text)',
        boxShadow: 'var(--textfield-shadow)',
        backgroundColor: 'field',
        '&:hover': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '&:focus-visible': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '&.Mui-focused': {
            boxShadow: 'var(--textfield-shadow-hover)'
        },
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            padding: '0 !important',
            fontFamily: '"JetBrains Mono", monospace'
        },
        '&.Mui-error': {
            border: '1px solid var(--textfield-shadow-border)',
            boxShadow: 'var(--textfield-shadow-error)'
        },
        '& button': {
            color: 'var(--table-buttons)',
            scale: '0.9'
        }
    },
    '& fieldset': {
        borderColor: 'transparent !important',
    }


})

function DatePicker({ value, onChange, label, error, helperText, ...props }) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MuiDatePicker
                value={value}
                onChange={onChange}
                renderInput={(params) => (
                    <div style={{ marginBottom: '20px' }}>
                        <p style={{ color: 'var(--text)' }}>{label}</p>
                        <StyledTextField {...params} error={error} helperText={helperText} />
                    </div>
                )}
                {...props}
            />
        </LocalizationProvider>
    )
}

export default DatePicker