import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { editEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import { GenericAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import Button from '../../Buttons/Button'

import '../GenericCRUDDialog.css'

function EditTable(props) {
    const [loading, setLoading] = useState(false)

    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, formState: { errors } } = useForm();

    const tableTypeData = [
        { id: 1, name: 'New Business', value: 'new_business' },
        { id: 2, name: 'Final Design', value: 'final_design' }
    ]

    const submitToServer = (data) => {
        const changedData = {}

        if (data.name !== props.table.name) changedData.name = data.name;
        if (data.year !== props.table.year) changedData.year = data.year;
        if (data.tableType.value !== props.table.type) changedData.type = data.tableType.value

        if (Object.keys(changedData).length === 0) {
            showSnackbar({ message: 'Não foram detetadas alterações', variant: 'info' })
            return;
        }

        setLoading(true)

        editEntity('tables', props.table.id, changedData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar editar a tabela', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title='Editar tabela'
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para editar a tabela</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(submitToServer)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue={props.table.name}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome'
                                name='name'
                                onChange={onChange}
                                value={value}
                                helperText='Campo não obrigatório'
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='year'
                        defaultValue={props.table.year}
                        rules={{
                            required: 'Indique um ano',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Ano*'
                                name='year'
                                type='number'
                                onChange={onChange}
                                value={value}
                                error={errors.year ? true : false}
                                helperText={errors.year && errors.year?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='tableType'
                        defaultValue={props.table.type === 'new_business' ? tableTypeData[0] : tableTypeData[1]}
                        rules={{
                            required: 'Escolha pelo menos um tipo de tabela',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <GenericAutoComplete
                                id='tableType'
                                label='Tipo de tabela'
                                name='tableType'
                                optionLabel='name'
                                data={tableTypeData}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                error={errors.tableType ? true : false}
                                helperText={errors.tableType && errors.tableType?.message}
                            />
                        )}
                    />
                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Editar Tabela</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default EditTable