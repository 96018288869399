import React from 'react'
import StatisticsTable from './Components/StatisticsTable'
import DoughnutChart from '../Charts/Statistics/DoughnutChart'
import useCompaniesDialog from '../../Hooks/Dialogs/useCompaniesDialog'
import sortArray from 'sort-array'

import './StatisticsSections.css'

function CompaniesStatistics(props) {
    const { openViewMore } = useCompaniesDialog()

    return (
        <div className='section-main-div'>
            <div className='section-content small'>
                <StatisticsTable
                    data={sortArray(props.data, {
                        by: 'total',
                        order: 'desc',
                        computed: {
                            total: company => company.projectsCount + company.usersCount
                        }
                    })}
                    headers='Nome,Projetos,Users'
                    values='name,projectsCount,usersCount'
                    title='Empresas'
                    openViewMore={openViewMore}
                />
            </div>
            <div className='section-content small'>
                <p style={{ position: 'absolute' }}>Projetos</p>
                <DoughnutChart data={props.data} field='projectsCount' />
            </div>
            <div className='section-content small'>
                <p style={{ position: 'absolute' }}>Users</p>
                <DoughnutChart data={props.data} field='usersCount' />
            </div>
        </div>
    )
}

export default CompaniesStatistics