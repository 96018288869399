import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { MainListItems, SecondaryListItems, BottomListItems } from './ListItems'

import './SidePanel.css'

function SidePanel() {
    const location = useLocation()

    const [activeTab, setActiveTab] = useState(location.pathname)

    useEffect(() => {
        setActiveTab(location.pathname)
    }, [location.pathname])

    return (
        <div>
            <div className='side-bar-div'>
                <MainListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                <Divider sx={{ backgroundColor: 'var(--text)', width: '80%' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%' }}>
                    <SecondaryListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                    <BottomListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                </div>
            </div>
        </div>
    )
}

export default SidePanel