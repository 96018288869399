import React, { useState, useEffect, useRef } from 'react'
import { getEntity, createEntity } from '../../../API/Requests'
import DialogPrefab from '../DialogPrefab'
import { Avatar, Divider, Menu, MenuItem, ListItemIcon } from '@mui/material'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Visibility, Link, PictureAsPdf, QrCode2, Edit, Delete, Add } from '@mui/icons-material'
import moment from 'moment'
import { skinString, openInNewTab } from '../../../utils'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import useTablesDialog from '../../../Hooks/Dialogs/useTablesDialog'
import useProjectsDialog from '../../../Hooks/Dialogs/useProjectsDialog'
import useMiscDialog from '../../../Hooks/Dialogs/useMiscDialog'
import { useUserInfo } from '../../../Hooks/useUser'

import './ViewMoreTable.css'
import sortArray from 'sort-array'
import Button from '../../Buttons/Button'
let timer;
function ViewMoreTable({ table, ...props }) {
    const [tableInfo, setTableInfo] = useState(null)
    const [tableData, setTableData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { userInfo } = useUserInfo()
    const scrollRef = useRef(null)
    const {
        openAddTableProject,
        openEditTableProject,
        openDeleteTableProject,
        openEditDialog,
        openDeleteDialog,
        openDeleteTableRow
    } = useTablesDialog()

    useEffect(() => {
        if (props.open) {
            getEntity('tables', table.id).then(res => {
                setTableInfo(res.data)
                setTableData(res.data.tables_rows)
            }, err => {
                console.error(err)
            })
        }
    }, [table, props.open, refresh])

    const onAddProject = data => {
        openAddTableProject({ company: tableInfo.company, ...data }, () => setRefresh(!refresh))
    }

    const onEditProject = data => {
        openEditTableProject({ company: tableInfo.company, ...data }, () => setRefresh(!refresh))
    }

    const onDeleteProject = data => {
        openDeleteTableProject(data, () => setRefresh(!refresh))
    }

    const onDeleteTable = () => {
        openDeleteDialog(tableInfo, () => props.parentRefresh())
    }

    const onEditTable = () => {
        openEditDialog(tableInfo, () => setRefresh(!refresh))
    }

    const onDeleteRow = row => {
        openDeleteTableRow(row, () => setRefresh(!refresh))
    }

    const onAddRow = () => {
        createEntity('table_rows', { tableId: tableInfo.id }).then(res => {
            setRefresh(!refresh)
            showSnackbar({ message: res.data })
            if (scrollRef.current) {
                timer = setTimeout(() => {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                    clearTimeout(timer)
                }, 500)
            }
        }, err => {
            console.error(err)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='lg'
            title={`${tableInfo?.name ? `${tableInfo?.name} - ` : ''} ${tableInfo?.type === 'new_business' ? 'New Business' : 'Final Design'} - ${tableInfo?.year}`}
        >
            <div className='view-more-table-main-div'>
                {tableInfo &&
                    <>
                        <div className='view-more-table-company-logo'>
                            <Avatar variant='rounded' alt={tableInfo.company.name} src={tableInfo.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${tableInfo.company.image}` : null} sx={{ width: 'auto', height: '50px' }}>
                                {tableInfo.company.name.split('')[0]}
                            </Avatar>
                            <p>{tableInfo.company.name}</p>
                        </div>
                        <div className='view-more-table-actions' style={{ right: '30px' }}>
                            {userInfo.user_role.canEditTables &&
                                <Button size='small' onClick={onEditTable}>Editar tabela</Button>
                            }
                            {userInfo.user_role.canRemoveTables &&
                                <Button size='small' onClick={onDeleteTable}>Eliminar tabela</Button>
                            }
                        </div>
                        <Table
                            rows={tableData}
                            addProject={onAddProject}
                            editProject={onEditProject}
                            deleteProject={onDeleteProject}
                            deleteRow={onDeleteRow}
                            scrollRef={scrollRef}
                        />
                        {userInfo.user_role.canEditTables &&
                            <div className='view-more-table-actions' style={{ left: '30px' }}>
                                <Button size='small' onClick={onAddRow}>Adicionar Linha</Button>
                            </div>
                        }
                    </>
                }
            </div>
        </DialogPrefab>
    )
}

export default ViewMoreTable

const Table = ({ rows, addProject, editProject, deleteProject, deleteRow, scrollRef }) => {
    const { userInfo } = useUserInfo()
    
    return (
        <div className='table-div'>
            <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
            <div className='table-headers'>
                <p style={{ width: '50px', textAlign: 'center' }}>#</p>
                <p className='table-p'>Brand</p>
                <p className='table-p'>Order By</p>
                <p className='table-p'>Order Date</p>
                <p style={{ width: '225px', margin: '0' }} className='table-p'>Ações</p>
            </div>
            <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
            <div className='table-rows-wrapper' ref={scrollRef}>
                {sortArray(rows, { by: 'id', order: 'asc'}).map((row, index) => (
                    <div key={row.id}>
                        <div className='table-row'>
                            <p className='index-col'>{index + 1}</p>
                            <div className='table-sub-row-div'>
                                {userInfo.user_role.canEditTables &&
                                    <div className='table-sub-row-div-actions'>
                                        <CustomTooltip title='Adicionar projeto à tabela'>
                                            <Add htmlColor='var(--text)' onClick={() => addProject(row)} />
                                        </CustomTooltip>
                                        <CustomTooltip title='Eliminar linha completa'>
                                            <Delete htmlColor='var(--text)' onClick={() => deleteRow(row)} />
                                        </CustomTooltip>
                                    </div>
                                }
                                {row.table_projects.length > 0 ?
                                    sortArray(row.table_projects, { by: 'createdAt', order: 'asc' }).map((el, i) => (
                                        <div key={el.id} className='table-sub-row filled'>
                                            <p className='table-p'>{el.brand}</p>
                                            <p className='table-p'>{el.orderBy}</p>
                                            <p className='table-p'>{moment(el.orderDate).format('DD MMMM YYYY')}</p>
                                            <TableActions el={el} editProject={editProject} deleteProject={deleteProject} userInfo={userInfo} />
                                        </div>
                                    ))
                                    :
                                    <div className='table-sub-row'></div>
                                }
                            </div>
                        </div>
                        <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const TableActions = ({ el, editProject, deleteProject, userInfo }) => {
    const { openViewMore } = useProjectsDialog()
    const { openQRCode } = useMiscDialog()

    const visitProject = project => {
        openInNewTab(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${project.folder}`)
    }

    const getQRCode = project => {
        openQRCode(`${process.env.REACT_APP_CLIENT_PROJECTS}?name=${project.folder}`)
    }

    const openPdf = (file, project) => {
        const pdf = `${process.env.REACT_APP_SERVER_URL}/project/${project.folder}/files/${file}`
        openInNewTab(pdf)
    }

    return (
        <div className='table-sub-row-actions'>
            {el.projectId &&
                <>
                    <CustomTooltip title='Visitar Projeto'>
                        <Link className='table-actions-icon' onClick={() => visitProject(el.project)} />
                    </CustomTooltip>
                    {el.project.pdfFiles && <PDFMenu project={el.project} openPdf={openPdf} />}
                    <CustomTooltip title='QR Code'>
                        <QrCode2 className='table-actions-icon' onClick={() => getQRCode(el.project)} />
                    </CustomTooltip>
                    <CustomTooltip title='Ver info projeto'>
                        <Visibility className='table-actions-icon' onClick={() => openViewMore(el.project)} />
                    </CustomTooltip>
                    <Divider orientation='vertical' sx={{ backgroundColor: 'var(--text)' }} />
                </>
            }
            {userInfo.user_role.canEditTables &&
                <CustomTooltip title='Editar'>
                    <Edit className='table-actions-icon' onClick={() => editProject(el)} />
                </CustomTooltip>
            }
            {userInfo.user_role.canRemoveTables &&
                <CustomTooltip title='Remover'>
                    <Delete className='table-actions-icon' onClick={() => deleteProject(el)} />
                </CustomTooltip>
            }
        </div>
    )
}

const PDFMenu = ({ project, openPdf }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const pdfArray = project.pdfFiles.split('/')

    return (
        pdfArray.length === 1 ?
            <CustomTooltip title={pdfArray[0]}>
                <PictureAsPdf className='table-actions-icon' onClick={() => openPdf(pdfArray[0], project)} />
            </CustomTooltip>
            :
            <>
                <CustomTooltip title="Ver PDF's">
                    <PictureAsPdf className='table-actions-icon' onClick={(e) => setAnchorEl(e.currentTarget)} />
                </CustomTooltip>
                <Menu
                    id='pdf-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {pdfArray.map(pdf => (
                        <MenuItem key={pdf} onClick={() => { setAnchorEl(null); openPdf(pdf, project) }}>
                            <ListItemIcon >
                                <PictureAsPdf />
                            </ListItemIcon>
                            {pdf}
                        </MenuItem>
                    ))}
                </Menu>
            </>

    )
}