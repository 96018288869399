import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { getEntity } from '../../../API/Requests'
import moment from 'moment'
import sortArray from 'sort-array'


import './TableCard.css'

function TableCard({ table, ...props }) {
    const [tableData, setTableData] = useState(null)

    useEffect(() => {
        getEntity('tables', table.id).then(res => {
            setTableData(res.data)
        }, err => {
            console.error(err)
        })
    }, [table])

    return (
        <div className='table-card-div' style={{ animationDelay: `${props.index * 0.3}s` }} onClick={props.onView}>
            <p className='table-card-info'>
                {`${table?.name ? `${table?.name} - ` : ''} ${table?.type === 'new_business' ? 'New Business' : 'Final Design'} - ${table?.year}`}
            </p>
            <TablePreview table={tableData} />
        </div>
    )
}

export default TableCard

const TablePreview = ({ table }) => {

    if (!table) return ''

    return (
        <div className='table-preview'>
            <div className='table-div-preview'>
                <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
                <div className='table-headers-preview'>
                    <p style={{ width: '15px', textAlign: 'center' }}>#</p>
                    <p className='table-p-preview'>Brand</p>
                    <p className='table-p-preview'>Order By</p>
                    <p className='table-p-preview'>Order Date</p>
                </div>
                <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
                <div className='table-rows-wrapper-preview'>
                    {table.tables_rows.map((row, index) => (
                        <div key={row.id}>
                            <div className='table-row-preview'>
                                <p className='index-col-preview'>{index + 1}</p>
                                <div className='table-sub-row-div-preview'>
                                    {row.table_projects.length > 0 ?
                                        sortArray(row.table_projects, { by: 'createdAt', order: 'asc' }).map((el, i) => (
                                            <div key={el.id} className='table-sub-row-preview'>
                                                <p className='table-p-preview'>{el.brand}</p>
                                                <p className='table-p-preview'>{el.orderBy}</p>
                                                <p className='table-p-preview'>{moment(el.orderDate).format('DD MMMM YYYY')}</p>
                                            </div>
                                        ))
                                        :
                                        <div className='table-sub-row-preview'></div>
                                    }
                                </div>
                            </div>
                            <Divider sx={{ width: '100%', backgroundColor: 'var(--text)' }} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}