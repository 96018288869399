import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getColors } from '../../../utils';

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ data, field }) {

    const chartData = {
        labels: data.map(el => el.name),
        datasets: [
            {
                data: data.map(el => el[field]),
                backgroundColor: getColors(data.length),
                borderWidth: 0
            },
        ],
    }

    return (
        <Doughnut
            data={chartData}
            options={{
                color: 'white',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                },
            }}
        />
    )
}

export default DoughnutChart