import React from 'react'
import { DotLoader } from 'react-spinners'

import Logo from '../../Assets/Logos/fauna.svg'

import './LoadingDisplay.css'

function LoadingDisplay() {
    return (
        <div className='loading-page-main-div'>
            <div className='login-page-logo-div'>
                <img alt='logo' src={Logo} className='login-page-logo' />
                <div className='logo-line top'></div>
                <div className='logo-line bottom'></div>
            </div>
            <div className='loading-page-loader-div'>
                <DotLoader color='var(--text)' />
                <p>Loading, please wait</p>
            </div>
        </div>
    )
}

export default LoadingDisplay