import React, { useState, useEffect } from 'react'
import DialogPrefab from '../DialogPrefab'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Divider } from '@mui/material'
import moment from 'moment'
import { Tabs, Tab } from '@mui/material'
import ProjectsList from '../../Misc/ProjectsList/ProjectsList'
import useCompaniesDialog from '../../../Hooks/Dialogs/useCompaniesDialog'
import Skeletons from '../../Misc/Skeletons'

import imagePlaceholder from '../../../Assets/placeholder/portrait.jpg'
import logoPlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './ViewMoreAppUser.css'
import { getProjectsFromUser } from '../../../API/Requests'

function ViewMoreAppUserDialog(props) {
    const [tab, setTab] = useState(0)
    const [projects, setProjects] = useState([])
    const [sharedProjects, setSharedProjects] = useState([])
    const [user, setUser] = useState()
    const { openViewMore } = useCompaniesDialog()
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (props.open) { //TODO: ver se está a funcionar, foi alterado o endpoint
            getProjectsFromUser(props.user.id).then(res => {
                setProjects(res.data.projects)
                setSharedProjects(res.data.user.sharedProjects)
                setUser(res.data.user)
            }, err => {
                console.log(err)
            })
        }
    }, [props.open, props.user, refresh])

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='lg'
            title={user?.name}
        >
            <div className='view-more-app-user-main-div'>
                <div className='view-more-app-user-left-div'>
                    <Tabs value={tab} onChange={(e, value) => setTab(value)} centered>
                        <Tab label={`Projetos com acesso (${projects.length})`} />
                        <Tab label={`Projetos partilhados (${sharedProjects.length ? sharedProjects.length : 0})`} />
                    </Tabs>
                    <div style={{ flex: '1 1', marginTop: '10px', width: '100%' }}>
                        <TabPanel value={tab} index={0}>
                            <ProjectsList projects={projects} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <ProjectsList
                                userId={props.user.id}
                                type='shared'
                                projects={sharedProjects}
                                refresh={() => setRefresh(!refresh)}
                            />
                        </TabPanel>
                    </div>
                </div>
                <Divider sx={{ height: '99%' }} orientation='vertical' />
                {user ?
                    <div className='view-more-app-user-right-div'>
                        <img className='view-more-user-card-image' alt={user.name} src={user.portrait ? `${process.env.REACT_APP_SERVER_URL}/images/appUsers/${user.portrait}` : imagePlaceholder} />
                        {user.company ?
                            <CustomTooltip title={user.company.name}>
                                <img
                                    className='view-more-user-company-img'
                                    alt={user.company.name}
                                    src={user.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${user.company.image}` : logoPlaceholder}
                                    onClick={() => props.companyLink && openViewMore(user.company)}
                                />
                            </CustomTooltip>
                            :
                            <p>Sem empresa</p>
                        }
                        <div className='view-more-user-info'>
                            <p style={{ fontSize: '24px' }}>{user.name}</p>
                            <p style={{ fontSize: '14px' }}>{user.email}</p>
                            <p style={{ fontSize: '14px', marginTop: '10px' }}>Ultimo Login: {user.last_login ? moment(user.last_login).format('DD MMM YYYY HH:mm') : 'Nunca'}</p>
                        </div>
                        <Divider sx={{ width: '95%' }} />
                        <div className='view-more-user-meta'>
                            <p style={{fontWeight:'600'}}>ID: <span style={{fontWeight:'300'}}>{user.id}</span></p>
                            <p style={{fontWeight:'600'}}>Image ID: <span style={{fontWeight:'300'}}>{user.image_id}</span></p>
                            <p style={{fontWeight:'600'}}>Created At: <span style={{fontWeight:'300'}}>{moment(user.createdAt).format('DD MMM YYYY HH:mm')}</span></p>
                            <p style={{fontWeight:'600'}}>Updated At: <span style={{fontWeight:'300'}}>{moment(user.updatedAt).format('DD MMM YYYY HH:mm')}</span></p>
                        </div>
                    </div>
                    :
                    <div className='view-more-app-user-right-div'>
                        <Skeletons />
                    </div>
                }
            </div>
        </DialogPrefab>
    )
}

export default ViewMoreAppUserDialog

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='tab-panel'
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}