import React, { useState, useMemo, useEffect } from 'react'
import PageTop from '../Panels/PageTop/PageTop'
import TablePrefab from '../Components/Tables/TablePrefab'
import { getEntity } from '../API/Requests'
import { CompanyAndPicture, LinkComponent } from '../Components/Tables/TableComponents/RowsComponents'
import useCompaniesDialog from '../Hooks/Dialogs/useCompaniesDialog'
import moment from 'moment'

import './css/Companies.css'

let timer;

function Companies() {
    const [companies, setCompanies] = useState([])
    const [plainData, setPlainData] = useState([])
    const [exportData, setExportData] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { openDeleteDialog, openAddDialog, openEditDialog, openViewMore } = useCompaniesDialog()

    const topPageInfo = [
        { label: 'Número de empresas', value: plainData.length },
    ]

    useEffect(() => {
        setLoading(true)
        getEntity('companies').then(res => {
            setCompanies(res.data.data)
            setExportData(mapExportData(res.data.plainData))
            setPlainData(res.data.plainData)
            setLoading(false)
        }, err => {
            console.log(err)
        })

    }, [refresh])

    const mapExportData = data => {
        return data.map(el => (
            {
                id: el.id,
                name: el.name,
                website: el.website,
                createdAt: moment(el.createdAt).format('DD MMM YYYY HH:mm'),
                updatedAt: moment(el.updatedAt).format('DD MMM YYYY HH:mm'),
            }
        ))
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 50,
                columnFilterModeOptions: ['equals', 'between', 'betweenInclusive', 'greaterThan', 'lessThan'],
                filterFn: 'equals',
                enableGrouping: false,
            },
            {
                accessorKey: "name",
                header: "Nome",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                Cell: ({ cell, row }) => <CompanyAndPicture name={cell.getValue()} image={row.original.image} />,
            },
            {
                accessorKey: "website",
                header: "Website",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                Cell: ({ cell }) => <LinkComponent url={cell.getValue()} />,
            },
        ],
        []
    );

    const onEdit = (data) => {
        openEditDialog(data.row.original, plainData, () => setRefresh(!refresh))
    }

    const onView = (data) => {
        openViewMore(data.row.original)
    }

    const onDelete = (data) => {
        openDeleteDialog(data.row.original, plainData, () => setRefresh(!refresh))
    }

    const onAdd = () => {
        openAddDialog(plainData, () => setRefresh(!refresh))
    }

    return (
        <div className='companies-page-main-div'>
            <PageTop
                title='Empresas'
                info={topPageInfo}
                view={'table'}
                addEntity={onAdd}
                addBtn='Empresa'
            />
            <div className='companies-table-div'>
                <TablePrefab
                    settingsId='companiesTable'
                    roleId='Companies'
                    columns={columns}
                    data={companies}
                    export
                    exportData={exportData}
                    title='Empresas'
                    enableExpanding
                    filterFromLeafRows
                    enableGrouping
                    enablePinning
                    enableColumnDragging={false}
                    adicionalState={{ isLoading: loading }}
                    enableColumnFilterModes
                    onRowView={onView}
                    onRowEdit={onEdit}
                    onRowDelete={onDelete}
                />
            </div>
        </div>
    )
}

export default Companies