import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { createEntity } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import useDialog from '../../../Hooks/useDialog'
import { CompaniesAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import CustomSwitch from '../../Inputs/CustomSwitch'

import '../GenericCRUDDialog.css'

function AddAppUserDialog(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false)
    const { openInfoDialog } = useDialog()

    const onSubmit = data => {
        openInfoDialog({ title: 'Adicionar novo utilizador', message: 'Ao adicionar este utilizador irá ser enviado um email com as credenciais para o email introduzido.\n\nPretende continuar?' }, 'y/n', () => submitToServer(data))
    }

    const submitToServer = (data) => {
        const userData = { name: data.name, email: data.email, companyId: data.company.id, superUser: data.superUser }
        setLoading(true)

        createEntity('app_users', userData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar criar o utilizador', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Adicionar novo utilizador`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para adicionar um novo utilizador</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue=''
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='email'
                        defaultValue=''
                        rules={{
                            required: 'Campo Obrigatório',
                            pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Email inválido' }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Email*'
                                name='email'
                                onChange={onChange}
                                value={value}
                                error={errors.email}
                                helperText={errors.email && errors.email?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='company'
                        defaultValue={null}
                        rules={{
                            required: 'Escolha uma empresa',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <CompaniesAutoComplete
                                id='company'
                                label='Empresa'
                                name='company'
                                data={props.companies}
                                value={value}
                                onChange={(val) => { onChange(val) }}
                                error={errors.company ? true : false}
                                helperText={errors.company && errors.company?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='superUser'
                        defaultValue={false}
                        render={({ field: { value, onChange } }) => (
                            <CustomSwitch
                                disabled={loading}
                                label='Super User?'
                                name='superUser'
                                onChange={onChange}
                                checked={value}
                                tooltip='Um super user significa que este user terá acesso a todos os projetos.'
                            />
                        )}
                    />
                    <Button loading={loading} style={{ marginTop: '20px' }} type='submit'>Criar utilizador</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default AddAppUserDialog