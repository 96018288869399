import React from 'react'
import DialogPrefab from './DialogPrefab'

function PDFViewer(props) {
    console.log(props)
    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={props.title}
            maxWidth='lg'
        >
            <div style={{ height: 'calc(100vh - 200px' }}>
                <iframe
                    src={props.pdf}
                    referrerPolicy='no-referrer'
                    allowFullScreen={true}
                    title={props.title}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
        </DialogPrefab>
    )
}

export default PDFViewer