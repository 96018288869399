import React, { useState, useEffect } from 'react'
import Button from '../../Buttons/Button'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField/TextField'
import { editEntityWithFile } from '../../../API/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { Controller, useForm } from 'react-hook-form'
import { CompaniesAutoComplete } from '../../Inputs/AutoComplete/CustomAutocomplete'
import ImageDropzone from '../../Inputs/Dropzone/ImageDropzone'
import { Help } from '@mui/icons-material'
import CustomTooltip from '../../Misc/CustomTooltip'

import '../GenericCRUDDialog.css'

function EditCompany(props) {
    const [loading, setLoading] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const [imageFile, setImageFile] = useState()
    const [companiesData, setCompaniesData] = useState([])

    useEffect(() => {
        setCompaniesData(props.companiesData.filter(company => company.id !== props.company.id))

        const companyHierarchy = props.company.hierarchy ? props.company.hierarchy.split('/') : null
        if (companyHierarchy) {
            const parentCompany = props.companiesData.find(el => el.id.toString() === companyHierarchy[companyHierarchy.length - 1])
            setValue('parent', parentCompany)
        }

    }, [props.company, props.companiesData])

    const submitToServer = (data) => {
        setLoading(true)
        const formData = new FormData()
        const { name, website, parent } = data
        const companyHierarchy = props.company.hierarchy ? props.company.hierarchy.split('/') : null

        if (name !== props.company.name) formData.set('name', name)
        if (website !== props.company.website) formData.set('website', website)
        if (imageFile !== undefined) formData.append('image', imageFile === null ? '' : imageFile)

        //se tinha parent e agora também, verifica se é o mesmo, set for nada acontece, se não atualiza o parent para esse novo,
        //se tinha parent e agora não, então faz-se set ao parent para null
        //se não tinha parent e agora tem, então faz-se set do novo parent
        if (companyHierarchy) {
            if (parent) {
                if (companyHierarchy[companyHierarchy.length - 1] !== parent.id.toString()) {
                    formData.set('hierarchy', parent.hierarchy ? `${parent.hierarchy}/${parent.id}` : parent.id)
                }
            } else {
                formData.set('hierarchy', '')
            }
        } else {
            if (parent) {
                formData.set('hierarchy', parent.hierarchy ? `${parent.hierarchy}/${parent.id}` : parent.id)
            }
        }

        if (Array.from(formData.keys()).length === 0) {
            showSnackbar({ message: 'Não foram detetadas alterações', variant: 'info' })
            return;
        }

        editEntityWithFile('companies', props.company.id, formData).then(res => {
            props.close()
            props.refresh()
            showSnackbar({ message: res.data })
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar({ message: 'Ocorreu um erro ao tentar criar a empresa', description: err.response, variant: 'error' })
            setLoading(false)
        })
    }

    const dropzoneOptions = {
        options: {
            maxFiles: 1,
            maxSize: 500000,
            multiple: false,
            accept: {
                'image/jpeg': [],
                'image/png': [],
            }
        },
        text: {
            maxSize: '500kb',
            title: 'Arraste ou clique aqui para carregar um logo.',
        }
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Editar empresa ${props.company.name}`}
        >
            <div className='add-dialog-div'>
                <p className='add-dialog-title'>Utilize o formulário a baixo para editar a empresa</p>
                <form className='add-dialog-user-fields' onSubmit={handleSubmit(submitToServer)}>
                    <Controller
                        control={control}
                        name='name'
                        defaultValue={props.company.name}
                        rules={{
                            required: 'Campo Obrigatório',
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Nome*'
                                name='name'
                                onChange={onChange}
                                value={value}
                                error={errors.name}
                                helperText={errors.name && errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name='website'
                        defaultValue={props.company.website}
                        rules={{
                            pattern: {
                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                                message: 'Urls têm de começar por http:// ou https://. Ex: https://google.com'
                            }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                disabled={loading}
                                label='Website'
                                name='website'
                                type='url'
                                placeholder='https://'
                                onChange={onChange}
                                value={value}
                                error={errors.website}
                                helperText={errors.website && errors.website?.message}
                            />
                        )}
                    />
                    {companiesData.length > 0 &&
                        <Controller
                            control={control}
                            name='parent'
                            defaultValue={null}
                            render={({ field: { value, onChange } }) => (
                                <CompaniesAutoComplete
                                    id='parent'
                                    label='Empresa Mãe'
                                    name='parent'
                                    noOptionsText='Sem empresas'
                                    data={companiesData}
                                    value={value}
                                    onChange={(val) => { onChange(val) }}
                                    error={errors.parent ? true : false}
                                    helperText={errors.parent && errors.parent?.message}
                                />
                            )}
                        />
                    }
                    <CustomTooltip title='Poderá fazer upload do logo da empresa, embora não seja obrigatório. Caso a empresa que pretende editar tenha uma empresa "mãe" e se não fizer upload de um logo, esta irá herdar o logo da empresa "mãe".' >
                        <Help />
                    </CustomTooltip>
                    <ImageDropzone
                        preview={props.company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${props.company.image}` : null}
                        setFile={(file) => setImageFile(file)}
                        dropzoneOptions={dropzoneOptions}
                    />
                    <Button style={{ marginTop: '20px' }} loading={loading} type='submit'>Editar empresa</Button>
                </form>
            </div>
        </DialogPrefab>
    )
}

export default EditCompany