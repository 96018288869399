import React from 'react'
import useCompaniesDialog from '../../../Hooks/Dialogs/useCompaniesDialog'

import logoPlaceholder from '../../../Assets/placeholder/logo_placeholder.png'

import './DashboardCompanyCard.css'

function DashboardCompanyCard({ company }) {
    const { openViewMore } = useCompaniesDialog()

    return (
        <div className='company-card-div' onClick={() => openViewMore(company)}>
            <img className='company-view-more-company-img' alt={company.name} src={company.image ? `${process.env.REACT_APP_SERVER_URL}/images/companies/${company.image}` : logoPlaceholder} />
            <p style={{ fontWeight: '700', margin: '20px 0' }}>{company.name}</p>
        </div>
    )
}

export default DashboardCompanyCard