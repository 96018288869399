import React, { useState, useEffect } from 'react'
import AppUsersSmallCard from '../../Cards/AppUsers/AppUsersSmallCard'
import { TextField } from '../../Inputs/TextField/TextField'
import { useDebounce } from '../../../utils'
import { Clear } from '@mui/icons-material'

import './UsersList.css'

function UsersList({ users }) {
    const [searchString, setSearchString] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(users)
    const debounceSearch = useDebounce(searchString, 500)

    useEffect(() => {
        setFilteredUsers(users)
    }, [users])

    useEffect(() => {
        if (searchString.length === 0) {
            setFilteredUsers(users)
            return;
        }

        const filter = users.filter(user => user.name.toLowerCase().includes(searchString.toLowerCase()))
        setFilteredUsers(filter)

    }, [debounceSearch])

    return (
        <>
            <div className='users-search-div'>
                <Clear
                    sx={searchString.length > 0 ? { display: 'flex' } : { display: 'none' }}
                    className='users-clear-search-icon'
                    onClick={() => setSearchString('')}
                />
                <TextField
                    name='search'
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    placeholder='Procurar por utilizador'
                />
            </div>
            <div className='users-list-wrapper'>
                <div className='users-list'>
                    {filteredUsers.length > 0 ?
                        filteredUsers.map(user => (
                            <AppUsersSmallCard key={user.id} user={user} />
                        ))
                        :
                        <p style={{textAlign: 'center'}}>Sem utilizadores para apresentar</p>
                    }
                </div>
            </div>
        </>
    )
}

export default UsersList